import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../navigations/Types";
import "./login.scss";
import { LocalStorage } from "../../../common/service";
import { Constants } from "../../../assets/constants";
// import { login } from "../../../common/api/login";
import {
  Button,
  ButtonText,
  CaptionText,
  H3,
  SubHeading,
  TextField,
} from "../../../common/components/design-system";
import appIcon from "../../../assets/icons/appIcon.svg";
import { ReactComponent as PersonIcon } from "../../../assets/icons/person.svg";
import { ReactComponent as HidePasswordIcon } from "../../../assets/icons/eye-password-hide.svg";
import { ReactComponent as ShowPasswordIcon } from "../../../assets/icons/eye-password-show.svg";
// @ts-ignore
import AppleSignin from "react-apple-signin-auth";
// @ts-ignore
import { GoogleLogin } from "@react-oauth/google";
import { googleSSO, appleSSO, login } from "common/redux/services/login.service";
import AppleIcon from "@mui/icons-material/Apple";

// istanbul ignore next
const MyAppleSigninButton = () => (
  <AppleSignin
    style={{ width: "100%", height: "100%" }}
    authOptions={{
      clientId: "com.apadaccess.applesignin.serviceid",
      scope: "email name",
      redirectURI: "https://applicationweb.staging.apadaccess.com",
      state: "state",
      nonce: "nonce",
      usePopup: true,
    }}
    uiType="dark"
    className="apple-auth-btn"
    noDefaultStyle={false}
    buttonExtraChildren="Continue with Apple"
    onSuccess={async (response: any) => {
      const appleResponse = await appleSSO(
        response.authorization.id_token,
        response.authorization.code
      );
      LocalStorage.setItem(Constants.storageKey.userSession, {
        accessToken: appleResponse?.token,
      });
    }}
    onError={(error: any) => console.error(error)} // default = undefined
    iconProp={{ style: { marginTop: "10px" } }} // default = undefined
    render={(props: any) => (
      <Button
        style={{
          borderRadius: 4,
          width: "100%",
          height: 32,
          backgroundColor: "black",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: 4,
        }}
        onClick={props.onClick}
      >
        <AppleIcon
          sx={{ color: "white" }}
          fontSize="small"
        />
        <ButtonText
          style={{
            color: "white",
            marginLeft: 10,
            fontSize: 14,
          }}
        >
          Sign in with Apple
        </ButtonText>
      </Button>
    )}
  />
);

const LoginSchema = Yup.object().shape(
  {
    email: Yup.string()
      .required("Email is required")
      .test("email", "Email is invalid", function (value) {
        const { path, createError } = this;
        if (value !== undefined && Number.isNaN(+value)) {
          return /^((?!\.)[\w-_+.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(
            value
          );
        }
        return (
          value?.length === 10 ||
          createError({
            path,
            message: "Email is invalid",
          })
        );
      }),
    password: Yup.string().required("Password is required"),
  },
  []
);

function Login() {
  const theme = useTheme();
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    values,
    dirty,
    errors,
    isValid,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (params, helpers) => {
      try {
        const session = await login(params.email, params.password);
        LocalStorage.setItem(Constants.storageKey.userSession, {
          accessToken: session?.token,
        });
        toast.success("User Login SuccessFully");
      } catch (e) {
        toast.error("Entered credentials don’t match");
      }
      return false;
    },
  });

  const responseMessage = async (response: any) => {
    const googleResponse = await googleSSO(response.credential);

    LocalStorage.setItem(Constants.storageKey.userSession, {
      accessToken: googleResponse?.token,
    });
  };

  const errorMessage = (error: any = "Uknown error") => {
    // console.log(error);
  };

  const handleGoogleError = () => {
    errorMessage();
  };

  return (
    <div className="login-wrapper">
      <div className="row d-flex justify-content-center">
        <div className="login-box">
          <div className="login-container">
            <div className="welcome-title">
              <SubHeading color={theme.color.brand}>Welcome to </SubHeading>
            </div>
            <div className="logo-wrapper">
              <img
                className="logo-image"
                src={appIcon}
                alt="Logo"
              />
            </div>
            <div className="login-title">
              <H3>Please sign in </H3>
            </div>
            <form
              id="loginform"
              onSubmit={handleSubmit}
            >
              <div className="login-form-wrapper">
                <TextField
                  data-testid="Email"
                  label="Email"
                  name="email"
                  id="email"
                  suffixIcon={() => (
                    <PersonIcon
                      height={18}
                      width={18}
                    />
                  )}
                  suffixStyle={{
                    fill: "none",
                    stroke: "black",
                  }}
                  value={values.email}
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                  error={touched?.email && !!errors?.email}
                  errorMessage={errors?.email}
                />

                <TextField
                  data-testid="Password"
                  label="Password"
                  type={!isPasswordVisible ? "password" : "text"}
                  name="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange("password")}
                  onBlur={handleBlur("password")}
                  error={touched?.password && !!errors?.password}
                  errorMessage={errors?.password}
                  suffixIcon={() =>
                    !isPasswordVisible ? (
                      <ShowPasswordIcon
                        data-testid="show-password"
                        width={18}
                        height={18}
                        onClick={() => setIsPasswordVisible(true)}
                      />
                    ) : (
                      <HidePasswordIcon
                        data-testid="hide-password"
                        width={18}
                        height={18}
                        onClick={() => setIsPasswordVisible(false)}
                      />
                    )
                  }
                  suffixStyle={{
                    fill: "none",
                    stroke: "black",
                    height: "14px",
                    width: "14px",
                  }}
                />
              </div>
              <div className="d-flex justify-content-center login-button-wrapper">
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || !dirty}
                  size="lg"
                  variant="primary"
                >
                  <ButtonText>Sign In</ButtonText>
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <div style={{ flex: 1 }}>
                  <GoogleLogin
                    theme="filled_blue"
                    onSuccess={responseMessage}
                    onError={handleGoogleError}
                    size={"medium"}
                  />
                </div>
                <div style={{ marginRight: 10, marginLeft: 10 }} />
                <div style={{ flex: 1 }}>
                  <MyAppleSigninButton />
                </div>
              </div>

              <div className="login-note-wrapper">
                <div className="login-note">
                  <CaptionText>
                    By signing in, you agree to our{" "}
                    <a
                      href="https://www.apadaccess.com/terms-of-service"
                      target="_blank"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://apadaccess.com/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </CaptionText>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const route: AppRoute = {
  name: "Login",
  screen: Login,
  path: "/login",
  role: [UserRole.General],
};
export { Login, route };

import { useEffect, useState } from "react";
import { InputFieldIcon } from "common/components/design-system/text-field/input-field-icon/input-field-icon";

import {
  Button,
  ButtonText,
  SelectField,
} from "../../../../common/components/design-system";
import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person-outline-icon.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail-outline-icon.svg";

import * as S from "./styles";
import { Api } from "common/redux/services/base/api";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Tooltip } from "react-tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function FillUserInfo({
  addResidentData,
  setAddResidentData,
  onNext,
  goBack,
}: any) {
  const [residentData, setResidentData] = useState({
    name: addResidentData?.name ?? "",
    email: addResidentData?.email ?? "",
    type: "resident",
  });
  const [allowedToNext, setAllowedToNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setAddResidentData({ ...addResidentData, ...residentData });
  }, [residentData]);

  const nextButton = () => {
    if (residentData.name === "") {
      setErrorMessage("*Name cannot be empty");
    } else {
      onNext();
    }
  };

  const [emailFilled, setEmailFilled] = useState(false);
  const [infoEditable, setInfoEditable] = useState(true);

  async function searchUser(email: string) {
    const result = await Api.post(
      // @ts-ignore
      "/api/v1/users/search",
      {
        email: email,
      }
    );

    if (result != null) {
      if (result.data.length > 0) {
        let newName = "";
        let inputEditable = false;
        if (result.data[0].firstName && result.data[0].lastName) {
          newName = result.data[0].firstName + " " + result.data[0].lastName;
        } else if (result.data[0].fullName) {
          newName = result.data[0].fullName;
        } else {
          inputEditable = true;
        }

        setAddResidentData({
          ...addResidentData,
          name: newName,
        });
        setResidentData({
          ...residentData,
          name: newName,
        });
        setInfoEditable(inputEditable);
      } else {
        setInfoEditable(true);
      }

      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  useEffect(() => {
    if (
      residentData?.email.match(/^[\w.+%-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/)
    ) {
      setEmailFilled(true);
      searchUser(residentData.email);
    } else {
      setEmailFilled(false);
      setInfoEditable(true);
    }
  }, [residentData.email]);

  return (
    <div data-testid="fill-user-info">
      <S.ContentContainer
        style={{
          flexDirection: "column",
        }}
      >
        <S.InputContainer>
          <S.InputLabel>Email</S.InputLabel>
          <InputFieldIcon
            data-testid="resident-email-input"
            // @ts-ignore
            onChange={(event: InputText) => {
              setErrorMessage("");
              setResidentData({ ...residentData, email: event.target.value });
              setAddResidentData({
                ...addResidentData,
                email: event.target.value,
              });
            }}
            value={residentData.email}
            // @ts-ignore
            suffixIcon={MailIcon}
          />
        </S.InputContainer>

        {emailFilled && (
          <S.InputContainer>
            <S.InputLabel>Name</S.InputLabel>
            <InputFieldIcon
              data-testid="resident-name-input"
              // @ts-ignore
              onChange={(event: InputText) => {
                setErrorMessage("");
                setResidentData({ ...residentData, name: event.target.value });
                setAddResidentData({
                  ...addResidentData,
                  name: event.target.value,
                });
              }}
              value={residentData.name}
              disabled={!infoEditable}
              // @ts-ignore
              suffixIcon={PersonIcon}
            />
            <div>
              <h2 style={{ fontSize: 12, color: "red", marginTop: 10 }}>
                {errorMessage}
              </h2>
            </div>

            <div>
              <FormControlLabel
                label="This user is a Resident"
                control={
                  <Checkbox
                    // disableRipple={true}
                    title="This user is a Resident"
                    style={{}}
                    onChange={() => {
                      setResidentData({
                        ...residentData,
                        type:
                          residentData.type === "resident"
                            ? "guest"
                            : "resident",
                      });
                      setAddResidentData({
                        ...addResidentData,
                        type:
                          residentData.type === "resident"
                            ? "guest"
                            : "resident",
                      });
                    }}
                    checked={residentData.type === "resident"}
                    data-testid="is-resident-checkbox"
                  />
                }
              />
              <a data-tooltip-id="is-resident-tooltip">
                <InfoOutlinedIcon
                  style={{
                    color: "#303030",
                    fontSize: 20,
                  }}
                />
              </a>
              <Tooltip id="is-resident-tooltip">
                <div>
                  <p>Residents can invite others to access their doors</p>
                </div>
              </Tooltip>
            </div>
          </S.InputContainer>
        )}
      </S.ContentContainer>

      <div className="bottom-buttons">
        <Button
          size="lg"
          variant="basic"
          onClick={goBack}
        >
          <ButtonText>Previous</ButtonText>
        </Button>
        <div style={{ width: 20 }} />
        <Button
          data-testid="next-button"
          size="lg"
          variant="primary"
          onClick={nextButton}
          disabled={!emailFilled || addResidentData.name === ""}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      </div>
    </div>
  );
}

export { FillUserInfo };

import React, {
  ComponentProps,
  ComponentType,
  useCallback,
  useEffect,
  useState,
} from "react";

export function useWindowDimension() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // istanbul ignore next
  const onResize = useCallback(
    () =>
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    [setSize]
  );
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  useEffect(() => {
    window.onload = () => {
      const newDimension = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      // istanbul ignore next
      setSize((prev) => {
        if (
          newDimension.width !== prev.width ||
          newDimension.height !== prev.height
        ) {
          return newDimension;
        }
        return prev;
      });
    };
  }, []);

  return size;
}

export function getElementDimension(element: Element) {
  const rect = element.getBoundingClientRect();
  return {
    width: rect.right - rect.left,
    height: rect.bottom - rect.top,
  };
}

export function useViewDimension(element?: Element | null) {
  const dimension = useWindowDimension();
  const [size, setSize] = useState<{ width?: number; height?: number }>({});
  const getViewDimension = useCallback(
    () => (element ? getElementDimension(element) : {}),
    [dimension, element]
  );
  useEffect(() => {
    setSize(getViewDimension());
  }, [getViewDimension]);
  return size;
}

export const genericMemo: <T extends ComponentType<any>>(
  component: T,
  propsAreEqual?: (
    prevProps: Readonly<ComponentProps<T>>,
    nextProps: Readonly<ComponentProps<T>>
  ) => boolean
) => T = React.memo;

export function groupBy<I>(
  array: I[],
  predicate: (item: I) => string
): Record<string, I[]> {
  return array.reduce((p, c) => {
    const key = predicate(c);
    p[key] = [...(p?.[key] ?? []), c];
    return p;
  }, {} as Record<string, I[]>);
}

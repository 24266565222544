import styled from "styled-components";

export const ButtonsContainer = styled.div`
  flex-direction: row;
  margin: 16px 0px;
`;

export const ContentContainer = styled.div`
  flex: 1;
`;

export const OrContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 24px 0px 24px 0px;
`;

export const OrText = styled.span`
  color: grey;
  margin: 0px 16px;
`;

export const OrLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: grey;
  flex: 1;
`;

export const WarningText = styled.span`
  color: grey;
`;

import { useCallback, useState } from "react";
import styled, { useTheme } from "styled-components";

import { genericMemo } from "../../../../utils";
import { Select, SelectProps } from "../select/select";

export interface SelectDropDownProps<I, R> extends SelectProps {
  name: string;
  data: I[];
  selectedValue?: R | undefined;
  onSelection: ((item?: R) => void) | undefined;

  labelExtractor?: (item: I) => string;
  valueExtractor?: (item: I) => R;
  keyExtractor: (item: I) => string;
}

const SelectWrapper = styled.div`
  position: relative;
`;

function SelectDropdownDelicate<I = string, R = string>({
  name,
  data,
  selectedValue,
  labelExtractor,
  keyExtractor,
  valueExtractor,
  onSelection,
  ...rest
}: SelectDropDownProps<I, R>) {
  // const Icon = rest.icon;
  //
  // const StyledIcon = Icon && styled(Icon)`
  //   position: absolute;
  //   top: 23px;
  //   right: 7px;
  // `;
  const [selected, setSelected] = useState<R | undefined>(selectedValue);

  const theme = useTheme();
  const onSelectionChange = useCallback((item: I) => {
    const value = valueExtractor?.(item);
    setSelected(value);
    onSelection?.(value);
  }, [onSelection]);

  return (
    <SelectWrapper>
      <Select
        {...rest}
        id={name}
        name={name}
        value={selected ? data.findIndex((it) => selected === valueExtractor?.(it)) : undefined}
        onChange={(e) => onSelectionChange(data?.[+e.target.value])}
      >
        {
          data.map((item, index) => (
            <option key={keyExtractor(item)} value={index}>
              {labelExtractor?.(item)}
            </option>
          ))
        }
      </Select>
      {
        rest.icon && (
          <rest.icon style={{
            position: "absolute",
            top: "24px",
            right: "7px",
            fill: rest.disabled ? theme.color.text.disabled : theme.color.text.default,
          }}
          />
        )
      }
    </SelectWrapper>
  );
}

SelectDropdownDelicate.defaultProps = {
  selectedValue: undefined,
  labelExtractor: (item: object) => item?.toString(),
  valueExtractor: (item: object) => item?.toString(),
};

const SelectDropDown = genericMemo<typeof SelectDropdownDelicate>(
  SelectDropdownDelicate,
  (prev, next) => prev.data === next.data
    && prev.selectedValue === next.selectedValue
    && prev.onSelection === next.onSelection,
);

export { SelectDropDown };

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "styled-components";
import { toast } from "react-toastify";

import { GuestDetail } from "../../../../../../common/redux/services/model";
import {
  Button,
  ButtonText,
  CaptionTextSmall,
  H3,
  LabelText,
  TextField,
} from "../../../../../../common/components/design-system";
import { useUpdateGuestMutation } from "../../../../../../common/redux/query";

import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { ReactComponent as DownArrowIcon } from "assets/icons/angle-down.svg";
import "./edit-guest-info-form-component.scss";
import { redirect, useNavigate, useNavigation } from "react-router-dom";

const guestSchema = Yup.object().shape(
  {
    name: Yup.string().trim().required("Full Name is required"),
    email: Yup.string()
      .trim()
      // .required("Email is required") // TODO: enable email requirement for common guests
      .email("Email is invalid"),
  },
  []
);

export interface EditGuestInfoFormComponentProps {
  guestInfo?: GuestDetail;
  onCancel: (() => void) | undefined;
}

function EditGuestInfoFormComponent({
  guestInfo,
  onCancel,
}: EditGuestInfoFormComponentProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isDoorListShown, setIsDoorListShown] = useState(false);
  const [
    updateGuest,
    { isLoading, isSuccess, data: updateGuestResponse, ...updateGuestMutation },
  ] = useUpdateGuestMutation();

  // istanbul ignore next
  const {
    values,
    dirty,
    errors,
    isValid,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      name:
        // @ts-ignore
        guestInfo?.allowedNickname ||
        // @ts-ignore
        guestInfo?.doorAccesses[0].allowedNickname,
      // @ts-ignore
      email: guestInfo?.allowedEmail || "",
    },
    validationSchema: guestSchema,
    onSubmit: async (params, helpers) => {
      // @ts-ignore
      if (guestInfo?.allowedUserUuid) {
        await updateGuest({
          // @ts-ignore
          id: guestInfo?.allowedUserUuid,
          // @ts-ignore
          actualNickname: guestInfo.allowedNickname,
          newNickname: params?.name?.trim(),
        });
        // @ts-ignore
        const newUrl = `../guests/${guestInfo?.allowedUserUuid}/${params?.name}/true`;
        navigate(newUrl);
      } else {
        await updateGuest({
          // @ts-ignore
          pinId: guestInfo?.doorAccessPinUuid,
          newName: params?.name?.trim(),
        });
        // @ts-ignore
        const newUrl = `../guests/${guestInfo?.doorAccessPinUuid}/${params?.name}/false`;
        navigate(newUrl);
      }
    },
  });

  useEffect(() => {
    const response = updateGuestResponse;
    if (isSuccess && response) {
      toast.success("Guest updated successfully");
      onCancel?.();
      // close the add door modal
    }
  }, [isSuccess, updateGuestResponse]);

  useEffect(() => {
    if (updateGuestMutation.isError) {
      toast.error((updateGuestMutation.error as Error).message);
      updateGuestMutation.reset();
    }
  }, [
    updateGuestMutation.error,
    updateGuestMutation.isError,
    updateGuestMutation.reset,
  ]);

  return (
    <form
      id="edit_guest_form"
      onSubmit={() => {
        event?.preventDefault();
        handleSubmit();
      }}
    >
      <H3 style={{ marginBottom: "19px", marginTop: "20px" }}>Profile</H3>
      <div className="form-input-wrapper">
        <TextField
          label="Full Name"
          name="fullName"
          id="fullName"
          data-testid="full-name-input"
          value={values.name}
          onChange={handleChange("name")}
          onBlur={handleBlur("name")}
          error={touched?.name && !!errors?.name}
          // @ts-ignore
          errorMessage={errors?.name}
          suffixIcon={() => (
            <PersonIcon
              height={18}
              width={18}
            />
          )}
          suffixStyle={{
            fill: "none",
            stroke: "black",
          }}
        />

        <TextField
          disabled
          label="Email"
          name="email"
          id="email"
          data-testid="email-input"
          value={values.email}
          onChange={handleChange("email")}
          onBlur={handleBlur("email")}
          error={touched?.email && !!errors?.email}
          // @ts-ignore
          errorMessage={errors?.email}
          suffixIcon={() => (
            <MailIcon
              height={18}
              width={18}
              stroke={theme.color.text.disabled}
            />
          )}
        />
      </div>

      <div className="edit-guest-note-wrapper">
        <div
          className="note-title-wrapper"
          onClick={() => setIsDoorListShown(!isDoorListShown)}
        >
          <LabelText color={theme.color.interactive.default}>
            Why can’t I edit email?
          </LabelText>
          <div
            className="building-drop-icon"
            style={{ transform: isDoorListShown ? "rotate(180deg)" : "" }}
          >
            <DownArrowIcon fill={theme.color.interactive.default} />
          </div>
        </div>

        {isDoorListShown && (
          <div
            className="note-wrapper"
            style={{ transition: "10s" }}
          >
            <CaptionTextSmall color={theme.color.text.subdued}>
              The email is tied to the guest account. If you want to change
              email, you must delete and create a new, updated guest profile, or
              add a new guest.
            </CaptionTextSmall>
          </div>
        )}
      </div>
      <div className="form-button-wrapper">
        <Button
          type="submit"
          size="lg"
          variant="primary"
          disabled={!dirty}
          isLoading={isLoading}
        >
          <ButtonText>Save</ButtonText>
        </Button>
      </div>
    </form>
  );
}

EditGuestInfoFormComponent.defaultProps = {
  guestInfo: undefined,
};

export { EditGuestInfoFormComponent };

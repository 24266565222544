import { Constants } from "assets/constants";
import { Api } from "./base/api";

export async function getDoors() {
  try {
    const result = await Api.get(Constants.apiEndpoint.doors);
    const doors = result?.data.map((door: any) => {
      return { ...door, id: door?.doorUuid };
    });

    return Promise.resolve(doors);
  } catch (error) {
    return Promise.resolve([]);
  }
}

export async function getBuildingManagementDoors() {
  const result = await Api.post(Constants.apiEndpoint.doorsBuildingManager, {
    buildingIds: "*",
  });
  const doors = result.data.map((items: any) => {
    return items.doors.map((door: any) => ({
      ...door,
      pin: door.padPin,
      doorUuid: door.uuid,
      doorName: door.name,
      active: door.active,
      buildingUuid: items.building.buildingUuid,
      buildingName: items.building.buildingName,
      offsetShortName: items.building.offsetShortName,
      buildingAddress: door.address1,
      isEntrance: door.doorType === "entrance",
      ownershipType: door.ownershipType ?? "resident",
      type: door.type,
    }));
  });

  return Promise.resolve(doors.flat());
}

import { useCallback, useState } from "react";

import { DoorListSelectionWrapperComponent } from "../door-list-selection-wrapper/door-list-selection-wrapper";
import { DoorResponse } from "../../../../../../common/redux/services/model";
import {
  Button,
  ButtonText,
  H3,
} from "../../../../../../common/components/design-system";
import "./guest-select-doors-form.scss";
type DoorSelectVariant = "add_door" | "add_guest" | "add_resident";
export interface SelectDoorsFormComponentProps {
  doorSelectVariant?: DoorSelectVariant;
  selectedDoorList: DoorResponse[];
  onNext: ((selectedDoor?: DoorResponse[]) => void) | undefined;
  goBack: ((selectedDoor?: DoorResponse[]) => void) | undefined;
  currentModule?: string;
  testId?: string;
  offsetShortName?: string;
}

function GuestSelectDoorsFormComponent({
  onNext,
  selectedDoorList,
  goBack,
  doorSelectVariant,
  currentModule,
  testId,
  offsetShortName,
}: SelectDoorsFormComponentProps) {
  const [selectedDoors, setSelectedDoors] =
    useState<DoorResponse[]>(selectedDoorList);
  const onFormSubmit = useCallback(() => {
    onNext?.(selectedDoors);
  }, [selectedDoors]);

  const onGoBack = useCallback(() => {
    goBack?.(selectedDoors);
  }, [selectedDoors]);
  return (
    <div
      className="select-doors-container"
      data-testid={testId ?? "select-doors-container"}
    >
      <H3 style={{ marginBottom: "19px" }}>Building & Doors</H3>
      <DoorListSelectionWrapperComponent
        onSelect={setSelectedDoors}
        selectedDoorList={selectedDoorList}
        currentModule={currentModule}
      />
      <div className="bottom-buttons">
        {doorSelectVariant !== "add_door" && (
          <Button size="lg" variant="basic" onClick={onGoBack}>
            <ButtonText>Previous</ButtonText>
          </Button>
        )}
        <div style={{ width: 20 }} />
        <Button
          data-testid={"next-button"}
          size="lg"
          variant="primary"
          onClick={() => {
            if (selectedDoors === undefined || selectedDoors.length === 0) {
              alert("You must select at least one door");
              return;
            }
            onFormSubmit();
          }}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      </div>
    </div>
  );
}
GuestSelectDoorsFormComponent.defaultProps = {
  doorSelectVariant: "add_guest",
};
export { GuestSelectDoorsFormComponent };

import styled from "styled-components";

import { ReactComponent as DoorIcon } from "assets/icons/door.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/angle-right.svg";

import "./door.scss";
import { H4 } from "common/components/design-system";
import { Door } from "common/redux/services/model";

interface DoorParams {
  door: Door;
  buildingName: string;
  onClick: (door: Door) => void;
}
export const DoorImgWrapper = styled.div.attrs((props) => props)`
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.color.background.darkDefault};
`;
function DoorComponent({ door, buildingName, onClick }: DoorParams) {
  return (
    <div
      data-testid="door-component"
      className="door-wrapper"
      onClick={() => {
        // @ts-ignore
        onClick({ ...door, buildingName });
      }}
      style={{ cursor: "pointer" }}
    >
      <div className="door-title-img-wrapper">
        <div
          style={{
            position: "relative",

            marginTop: -30,
            marginBottom: -5,
          }}
        >
          <div
            style={{
              height: 25,
              width: 1,
              backgroundColor: "black",
              marginLeft: 32, //half of the icon width
            }}
            data-testid="door-item-divider"
          />
          <div
            style={{
              borderRadius: 32,
              width: 64,
              height: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid",
            }}
          >
            <DoorIcon />
          </div>
        </div>
        <div>
          <div className="door-title">
            <H4 style={{ fontSize: 20, color: "#202020" }}>{door?.name}</H4>
          </div>
          <div className="door-title">
            <H4
              style={{
                color: "#707070",
                fontSize: 16,
                fontWeight: "400",
                marginTop: -8,
              }}
            >
              {/* @ts-ignore */}
              {door.floor} floor • {door.lockStatusName}
            </H4>
          </div>
        </div>
      </div>
      <div className="door-pin">
        <RightArrowIcon />
      </div>
    </div>
  );
}

export { DoorComponent };

// istanbul ignore file
import { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";

import { HeaderOption } from "../../../common/components/top-nav/type";
import { groupBy } from "../../../common/utils";
import { AppRoute, UserRole } from "../../navigations/Types";

import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/loadingIcon.svg";
import { getFilterableDoors } from "common/redux/services/activities.service";
import { Api, HttpError } from "common/redux/services/base/api";
import { User } from "common/redux/services/model";
import moment from "moment";
import "./activities.scss";
import ActivityItem from "./components/ActivityItem";
import { SelectField } from "common/components/design-system/select-field-doors/select-field";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

const dashboardOptions: HeaderOption = {
  url: "/activities",
  title: "Activities",
  showLogo: true,
};

interface Activity {
  activityUuid: string;
  doorName: string;
  createdByUuid: string;
  createdByName: string;
  buildingName: string;
  activityType: string;
  activityStatus: string;
  createdAt: string;
}

export function groupActivities(
  data: any,
  doorFilter: string,
  statusFilter: string,
  activitiesOrder: string,
  currentOptions: any,
  setLoading: any,
  setGroupedActivities: any,
  statusSelected: any,
  setFlattenedActivities: any,
  flattenGroupedActivities: any,
  selectedBuilding: any
) {
  const doors = Array.from(
    new Set(
      data.map((object: any) => {
        return object.doorName;
      })
    )
  );

  let filteredData = data;

  // filter by building
  if (selectedBuilding !== null) {
    // @ts-ignore
    filteredData = filteredData.filter((object: any) => {
      // @ts-ignore
      return object.buildingUuid === selectedBuilding.buildingId;
    });
  }

  let newGroupedActivities = Object.entries(
    groupBy(
      filteredData,
      (it: any) => it.buildingName
      //@ts-ignore
    )
  ).map(([buildingName, activities]: any) => {
    return {
      building: {
        id: buildingName,
        address: "",
      },
      data: Object.entries(
        groupBy(activities, (it: any) => {
          return moment(it.createdAt).format("YYYY-MM-DD");
        })
      ).map(([date, data]) => ({ date, data })),
    };
  });

  if (activitiesOrder === "Latest") {
    newGroupedActivities.forEach((building) => {
      building.data.sort((a, b) => {
        return b.date.localeCompare(a.date);
      });
    });
  } else {
    newGroupedActivities.forEach((building) => {
      building.data.sort((a, b) => {
        return a.date.localeCompare(b.date);
      });
    });
  }

  // @ts-ignore
  setGroupedActivities(newGroupedActivities);
  if (newGroupedActivities.length > 0) {
    setFlattenedActivities(
      flattenGroupedActivities(newGroupedActivities[0].data)
    );
  } else {
    setFlattenedActivities([]);
  }
  setLoading(false);
}

function Activities() {
  const theme = useTheme();
  const [activeOption, setActiveOption] = useState<HeaderOption | undefined>(
    dashboardOptions
  );
  const [userData, setUserData] = useState<User>();
  const [activities, setActivities] = useState<Activity[]>();
  const [loadingNextPage, setLoadingNextPage] = useState(true);
  const [data, setData] = useState<any>([]);
  const [lastIndex, setLastIndex] = useState(0);

  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [buildingsFilterOptions, setBuildingsFilterOptions] = useState<
    string[]
  >([]);

  const [filterableDoors, setFilterableDoors] = useState([]);

  const options: any = [
    {
      title: "doors",
      values: [{ id: "*", name: "All Doors" }],
      selectedValue: [{ id: "*", name: "All Doors" }],
    },

    {
      title: "status",
      values: ["All Activities", "Locked", "Unlocked"],
      selectedValue: "All Activities",
    },

    {
      title: "order",
      values: ["Latest", "Oldest"],
      selectedValue: "Latest",
    },
  ];

  const statusData = ["Locked", "Unlocked"];

  const [doorList, setDoorList] = useState([]);
  const [flattenedActivities, setFlattenedActivities] = useState<
    FlattenedItem[]
  >([]);
  const [stickyHeaderIndices, setStickyHeaderIndices] = useState<number[]>([]);
  const [currentOptions, setCurrentOptions] = useState(options);
  const [doorFilter, setDoorFilter] = useState("*");
  const [statusSelected, setStatusSelected] = useState<string[]>(["Unlocked"]);
  const [statusFilter, setStatusFilter] = useState("All Status");
  const [activitiesOrder, setActivitiesOrder] = useState("Latest");
  const [loading, setLoading] = useState(true);
  const [groupedActivities, setGroupedActivities] = useState<any>([[]]);
  const [updatingFilters, setUpdatingFilters] = useState(false);

  const abortControllerRef = useRef(null);

  async function fetchDoors() {
    let result = [];
    result = await getFilterableDoors();
    setFilterableDoors(result);

    let newBuildings = [];
    let uniqueBuildings = new Map();

    result.forEach((door: any) => {
      // @ts-ignore
      const buildingId = door?.buildingId;
      // @ts-ignore
      const buildingName = door?.buildingName;

      const uniqueKey = `${buildingId}-${buildingName}`;

      if (!uniqueBuildings.has(uniqueKey)) {
        uniqueBuildings.set(uniqueKey, {
          buildingId,
          buildingName,
        });
      }
    });

    // @ts-ignore
    newBuildings = Array.from(uniqueBuildings.values());

    setBuildingsFilterOptions(newBuildings);
    // @ts-ignore
    setSelectedBuilding(newBuildings[0]);
  }

  useEffect(() => {
    fetchDoors();
  }, []);

  useEffect(() => {
    if (selectedBuilding !== null) {
      const newDoorsFilterOptions = filterableDoors.filter(
        // @ts-ignore
        (door) => door.buildingId === selectedBuilding.buildingId
      );

      const newOptions = currentOptions;
      //@ts-ignore
      newOptions[0].values = [
        { id: "*", name: "All Doors" },
        ...newDoorsFilterOptions,
      ];
      setDoorFilter("*");
      setCurrentOptions(newOptions);
    }
  }, [selectedBuilding]);

  function getActivityKey(activity: any): string {
    const createdAtWithoutMillisecondsAndMinutes = activity.createdAt.slice(
      0,
      -13
    );

    // Checks if activity already had the milliseconds and minutes removed
    if (createdAtWithoutMillisecondsAndMinutes.includes(":")) {
      activity.createdAt = createdAtWithoutMillisecondsAndMinutes;
    }

    return `${activity.doorUuid}-${activity.doorName}-${activity.doorType}-${activity.createdAt}-${activity.activityStatus}-${activity.activityType}`;
  }

  function removeDuplicates(activities: Activity[]): Activity[] {
    // Create a Map to store unique activities based on custom keys
    const uniqueMap = new Map<string, Activity>();
    const uniqueActivities: Activity[] = [];

    for (const activity of activities) {
      const activityKey = getActivityKey(activity);

      if (!uniqueMap.has(activityKey)) {
        uniqueMap.set(activityKey, activity);
        uniqueActivities.push(activity);
      }
    }

    return uniqueActivities;
  }

  const pageSize = 50;
  // @ts-ignore
  async function getActivity(skipIndex = 0): Promise<any> {
    try {
      // If there's an existing controller, abort its request
      if (abortControllerRef.current) {
        // @ts-ignore
        abortControllerRef.current.abort();
      }

      // @ts-ignore
      abortControllerRef.current = new AbortController();
      // @ts-ignore
      const { signal } = abortControllerRef.current;

      // @ts-ignore
      const statusFilterInfo = statusSelected.map((status: any) => {
        if (status == "Locked") {
          return "lock";
        }
        if (status == "Unlocked") {
          return "unlock";
        }
      });

      if (statusFilterInfo.length == 0) {
        setData([]);
        setLoadingNextPage(false);
        setLoading(false);
        return Promise.resolve([]);
      }

      const activityOrderInfo = activitiesOrder == "Latest" ? "DESC" : "ASC";
      const result = await Api.post(
        `/api/v1/activities/search?pageSize=${pageSize}&orderBy=createdAt&orderDir=${activityOrderInfo}&skipIndex=${skipIndex}`,
        {
          doorUuid: [doorFilter],
          lockActivity: statusFilterInfo,
        },
        { signal }
      );

      if (result != null) {
        const activities: Activity[] = result.data.rows;
        setLastIndex(result.data.lastIndex);

        const uniqueActivities = removeDuplicates(
          skipIndex == 0 ? [...activities] : [...data, ...activities]
        );
        setData(uniqueActivities);

        return Promise.resolve(result?.data);
      } else {
        return Promise.reject();
      }
      // istanbul ignore next
    } catch (axiosError) {
      // istanbul ignore next
      const err = axiosError as HttpError;
      // istanbul ignore next
      return {
        error: {
          status: err.code,
          message: err.message,
          data: err.response?.data,
          fieldErrors: err.fieldErrors,
        },
      };
    } finally {
      setLoadingNextPage(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    setLoadingNextPage(true);
    setLastIndex(0);
    getActivity();
  }, [doorFilter, statusSelected, activitiesOrder]);

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 2;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 250,
      },
    },
  };

  useEffect(() => {
    groupActivities(
      data,
      doorFilter,
      statusFilter,
      activitiesOrder,
      currentOptions,
      setLoading,
      setGroupedActivities,
      statusSelected,
      setFlattenedActivities,
      flattenGroupedActivities,
      selectedBuilding
    );
  }, [data, selectedBuilding]);

  // TO DO: test scroll and lazy loading later
  // istanbul ignore next
  const handleScroll = (e: any) => {
    const PROPORTION = 1.02;
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight * PROPORTION;
    if (bottom && !loadingNextPage) {
      setLoadingNextPage(true);
      getActivity(lastIndex);
    }
  };

  const handleChange = (event: SelectChangeEvent<typeof statusData>) => {
    const {
      target: { value },
    } = event;
    setStatusSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // TO DO: test this function
  // istanbul ignore next
  function renderGroupedActivities() {
    return (
      <div style={{ padding: 24, display: "flex", flexDirection: "column" }}>
        {flattenedActivities.map((item: any, index: any) => {
          if ("date" in item) {
            const date = item.date;
            let formattedDate = moment(date).format("MMMM DD, YYYY");

            if (
              moment(date).format("YYYYMMDD") == moment().format("YYYYMMDD")
            ) {
              formattedDate = "Today";
            } else if (moment(date).format("YYYY") == moment().format("YYYY")) {
              formattedDate = moment(date).format("MMMM DD");
            }
            return (
              <div
                key={index}
                style={{
                  backgroundColor: "white",
                  fontSize: 18,
                  marginBottom: 16,
                  position: "sticky",
                  top: 0,
                  zIndex: 100,
                }}
              >
                {formattedDate}
              </div>
            );
          } else {
            return (
              <div
                key={item.createdAt + index}
                className="activity-item"
              >
                <ActivityItem activity={item} />
              </div>
            );
          }
        })}
        {(flattenedActivities.length == 0 || statusSelected.length == 0) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h6>
              No activities found for the selected door and status. Please try
              again.
            </h6>
          </div>
        )}
      </div>
    );
  }

  type GroupedActivity = {
    date: string;
    data: Activity[];
  };

  type FlattenedItem = Activity | { date: string };

  useEffect(() => {
    let newStickyHeaderIndices: number[] = [];
    flattenedActivities.forEach((item, index) => {
      if ("date" in item) {
        newStickyHeaderIndices.push(index);
      }
    });
    setStickyHeaderIndices(newStickyHeaderIndices);
  }, [flattenedActivities]);

  function flattenGroupedActivities(
    groupedActivities: GroupedActivity[]
  ): FlattenedItem[] {
    const flattenedList: FlattenedItem[] = [];

    for (const activityGroup of groupedActivities) {
      flattenedList.push({ date: activityGroup.date });

      if (activitiesOrder === "Latest") {
        activityGroup.data.sort((a, b) => {
          return moment(b.createdAt).diff(moment(a.createdAt));
        });
      } else {
        activityGroup.data.sort((a, b) => {
          return moment(a.createdAt).diff(moment(b.createdAt));
        });
      }

      flattenedList.push(...activityGroup.data);
    }

    return flattenedList;
  }

  return (
    <>
      {/* @ts-ignore */}
      <div
        data-testid="activities-container"
        onScroll={handleScroll}
        style={{
          overflowY: "hidden",
          height: "100%",
        }}
      >
        {
          <>
            <div style={{ padding: 24 }}>
              <div
                style={{
                  display: "flex",
                  gap: 16,
                  maxWidth: 900,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SelectField<any, any>
                  data-testid="buildings-filter"
                  icon={DownIcon}
                  name="building"
                  label="Buildings"
                  data={buildingsFilterOptions}
                  selectedValue={selectedBuilding}
                  onSelection={(selected) => {
                    setLoading(true);
                    setUpdatingFilters(true);
                    // @ts-ignore
                    setSelectedBuilding(selected);
                    setDoorFilter("*");
                    setTimeout(() => {
                      setLoading(false);
                      setUpdatingFilters(false);
                    }, 0);
                  }}
                  valueExtractor={(item) => item}
                  labelExtractor={(item) => item?.buildingName}
                  keyExtractor={(item) => item?.buildingId}
                />

                <Select
                  style={{
                    backgroundColor: "#202020",
                    borderColor: "transparent",
                    height: 50,
                    color: "white",
                    borderRadius: 24,
                    textAlign: "center",
                    width: "100%",
                  }}
                  sx={{}}
                  IconComponent={() => {
                    return (
                      <ArrowDropDownOutlinedIcon
                        style={{
                          color: "white",
                          paddingRight: 4,
                        }}
                      />
                    );
                  }}
                  labelId="demo-multiple-checkbox-label"
                  multiple
                  value={statusSelected}
                  onChange={handleChange}
                  input={<OutlinedInput label="All Activities" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  data-testid="status-filter"
                >
                  {statusData.map((statusData) => (
                    <MenuItem
                      key={statusData}
                      value={statusData}
                      style={{ color: "#F1F2F1" }}
                    >
                      <Checkbox
                        checked={statusSelected.indexOf(statusData) > -1}
                        style={{ color: "black" }}
                      />
                      <ListItemText
                        primary={statusData}
                        style={{ color: "black" }}
                      />
                    </MenuItem>
                  ))}
                </Select>

                <SelectField<any, any>
                  data-testid="doors-filter"
                  icon={DownIcon}
                  name="door"
                  label="Doors"
                  data={currentOptions[0].values}
                  selectedValue={currentOptions[0].selectedValue}
                  onSelection={(selected) => {
                    setDoorFilter(selected);
                  }}
                  valueExtractor={(item) => item.id}
                  labelExtractor={(item) => item.name}
                  keyExtractor={(item) => item.id}
                />

                <SelectField<any, any>
                  data-testid="order-dropdown"
                  icon={DownIcon}
                  name="order"
                  label="Order"
                  data={currentOptions[2].values}
                  selectedValue={currentOptions[2].selectedValue}
                  onSelection={(selected) => {
                    setActivitiesOrder(selected);
                    // close the dropdown
                  }}
                  valueExtractor={(item) => item}
                  labelExtractor={(item) => item}
                  keyExtractor={(item) => item.toString()}
                />
              </div>
            </div>

            <div
              onScroll={handleScroll}
              style={{
                overflowY: "scroll",
                height: "100vh",
              }}
            >
              {!loading && renderGroupedActivities()}
              {(loading || loadingNextPage) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 24,
                    flex: 1,
                  }}
                  data-testid="loading-indicator"
                >
                  <LoadingIcon className="rotate" />
                </div>
              )}
            </div>
          </>
        }
      </div>
    </>
  );
}

const route: AppRoute = {
  name: "activities",
  screen: Activities,
  path: "/activities",
  role: [UserRole.Authenticated],
};

export { Activities, route };

// TO DO: test this
// istanbul ignore file
import React from "react";

import { Sidebar } from "../sidebar";
import { useSession } from "../../../service";

function SidebarWrapper({ children }: React.PropsWithChildren<{}>) {
  const session = useSession();
  return (
    <div>
      {session.state === "authenticated" && (
        <div>
          <Sidebar />
        </div>
      )}
      <div
        className="site-container"
        style={session.state === "authenticated" ? { marginLeft: "214px" } : {}}
      >
        {children}
      </div>
    </div>
  );
}

export { SidebarWrapper };

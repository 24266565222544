import * as S from "./styles";
import backgroundImage from "../../../../../assets/png/residents_background.png";

interface User {
  userUuid: string;
  name: string;
  doorAccess: [];
  status: string;
}

interface StaffData {
  statusCount: { pending: number; active: number; others: number };
  users: User[];
}

function StaffCard({
  staffData,
  variant,
  selected = true,
}: {
  staffData: StaffData;
  variant?: String;
  selected?: boolean;
}) {
  const variant_data = [
    {
      variant: "guests",
      title: "Guests",
      background: require("../../../../../assets/png/guests_background.png"),
    },
    {
      variant: "residents",
      title: "Residents",
      background: require("../../../../../assets/png/residents_background.png"),
    },
    {
      variant: "staff",
      title: "Staff",
      background: require("../../../../../assets/png/staff_background.png"),
    },
    {
      variant: "building-manager",
      title: "Managers",
      background: require("../../../../../assets/png/manager_background.png"),
    },
    {
      variant: "organization",
      title: "Org. Managers",
      background: require("../../../../../assets/png/organization_background.png"),
    },
  ];

  return (
    <>
      <S.Container selected={selected}>
        <div
          style={{
            width: "100%",
            height: 184,
            // borderRadius: 8,
            backgroundImage: `url(${
              variant_data.find((item) => item.variant == variant)?.background
            })`,
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 184,
              padding: 24,
              justifyContent: "space-between",
              background: selected
                ? "linear-gradient(90deg, rgba(0,0,0,0.8), rgba(27, 27, 27, 0.2) 90.00%)"
                : "linear-gradient(90deg, rgba(0,0,0,0.8), rgba(27, 27, 27, 0.8) 90.00%)",
              filter: selected ? "blur(0px)" : "blur(1px)",
            }}
          >
            <S.ResidentStatusText selected={selected}>
              {variant_data.find((item) => item.variant == variant)?.title} •{" "}
              {(staffData?.statusCount?.pending ?? "") +
                (staffData?.statusCount?.active ?? "")}
            </S.ResidentStatusText>

            <S.StatusContainer>
              <S.StatusText selected={selected}>
                Pending • {staffData?.statusCount?.pending ?? ""}
              </S.StatusText>
              <S.StatusText selected={selected}>
                Active • {staffData?.statusCount?.active ?? ""}
              </S.StatusText>
            </S.StatusContainer>
          </div>
        </div>
      </S.Container>
    </>
  );
}

export default StaffCard;

import { AppRoute, UserRole } from "../navigations/Types";
import { useSession } from "../../common/service";
import { Login } from "../authentication/login";
import { MyAccess } from "../authenticated/my-access";

function Home() {
  const session = useSession();

  if (session.state === "unknown") {
    return <div>Loading</div>;
  }

  if (session.state === "authenticated") {
    return <MyAccess data-testid={"my-access-component"} />;
  }
  return <Login data-testid={"login-component"} />;
}

const route: AppRoute = {
  name: "Dashboard",
  screen: Home,
  path: "/",
  role: [UserRole.Default],
};

export { route, Home };

import type { AxiosError } from "axios";

import { LocalStorage } from "../../../../service";
import { Constants } from "../../../../../assets/constants";

export const StatusCode = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  RESOURCE_NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
};

export const Messages = {
  NOT_ALLOWED: "You're not allowed to perform this task.",
  UNAUTHORIZED: "You're not authorized to perform this task.",
  UNEXPECTED: "Some error occurred, Please contact to the administrator.",
  BAD_REQUEST: "There's an error on your request, Please try again later.",
  CONFLICT: "There's been a conflict on your request, Please try again later.",
  GENERAL_NETWORK_ERROR:
    "An error occurred while connecting to the server, Please contact to the administrator.",
  SERVER_NOT_RESPONDING:
    "Could not establish connection with server, please try again",
  NO_INTERNET_CONNECTION:
    "Could not establish connection. Please make sure you are connected to internet.",
};

export function errorCodeMessage(code?: number): string {
  switch (code) {
    case StatusCode.UNAUTHORIZED:
      return Messages.UNAUTHORIZED;
    case StatusCode.RESOURCE_NOT_FOUND:
      return Messages.GENERAL_NETWORK_ERROR;
    case StatusCode.METHOD_NOT_ALLOWED:
      return Messages.NOT_ALLOWED;
    case StatusCode.FORBIDDEN:
    case StatusCode.CONFLICT:
      return Messages.CONFLICT;
    case StatusCode.BAD_REQUEST:
      return Messages.BAD_REQUEST;
    default:
      return Messages.UNEXPECTED;
  }
}

export interface ErrorResponse {
  error?: string;
  errors?: Record<string, string>;
  message?: string;
}

export interface HttpError<T = never> extends AxiosError<T> {
  message: string;
  hasFieldErrors: boolean;
  fieldErrors: Record<string, string | undefined>;
}

/**
 *
 * @param error @{AxiosError} to map to @{HttpError}
 * @throws HttpError
 */
export async function formatErrorAndThrow<T>(
  error: AxiosError<ErrorResponse>
): Promise<void> {
  const httpError = error as HttpError<T>;

  if (httpError.response?.status === StatusCode.UNAUTHORIZED) {
    LocalStorage.setItem(Constants.storageKey.userSession, undefined);
  }

  // const netInfo = await getNetInfo();
  // if (netInfo?.isConnected === false) {
  //   httpError.fieldErrors = {};
  //   httpError.hasFieldErrors = false;
  //   httpError.message = Messages.NO_INTERNET_CONNECTION;
  //   throw httpError;
  // }

  let message = errorCodeMessage(error.response?.status);
  if (typeof error.response?.data?.error === "string") {
    message = error.response.data.error;
  } else if (typeof error.response?.data.message === "string") {
    message = error.response?.data.message;
  }

  httpError.fieldErrors = error.response?.data?.errors || {};
  httpError.hasFieldErrors = Object.keys(httpError.fieldErrors).length === 0;
  httpError.message = message;
  throw httpError;
}

import { Constants } from "assets/constants";

import { Api } from "./base/api";

export async function getFilterableDoors() {
  try {
    const result = await Api.get(Constants.apiEndpoint.activityFilterableDoors);
    return Promise.resolve(result?.data);
  } catch (error) {
    return Promise.resolve([]);
  }
}

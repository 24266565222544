import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./door-list-selection-wrapper.scss";

import { DoorResponse } from "../../../../../../common/redux/services/model";
import { Building } from "../../../../../../common/redux/services/model/building";
import {
  H4,
  SelectField,
} from "../../../../../../common/components/design-system";
import { DoorListSelectItem } from "../door-list-select-item/door-list-select-item";
import { groupBy } from "../../../../../../common/utils";

import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";
import {
  getBuildingManagementDoors,
  getDoors,
} from "common/redux/services/doors.service";
import { off } from "process";

export interface DoorListSelectionFormProps {
  selectedDoorList: DoorResponse[];
  onSelect: (selectedDoors: DoorResponse[]) => void;
  currentModule?: string;
}

function DoorListSelectionWrapperComponent({
  onSelect,
  selectedDoorList,
  currentModule,
}: DoorListSelectionFormProps) {
  const [currentData, setCurrentData] = useState([]);
  //@ts-ignore
  
  async function getData() {
    let result = [];
    if (currentModule === "residents") {
      result = await getBuildingManagementDoors();
    } else if (currentModule === "manager") {
      // filter to get only type private
      result = await getBuildingManagementDoors();
      //@ts-ignore
      result = result.filter((item) => item.type === "private");
    } else {
      result = await getDoors();
    }
    setCurrentData(result);
  }

  useEffect(() => {
    getData();
  }, []);

  const [selectedBuilding, setSelectedBuilding] = useState<
    Building | undefined
  >();
  const [commonAreasSelected, setCommonAreasSelected] =
    useState<boolean>(false);

  const [selectedDoors, setSelectedDoors] = useState<DoorResponse[]>(
    selectedDoorList ?? []
  );

  const buildings = React.useMemo(
    () =>
      // @ts-ignore
      Object.values(groupBy(currentData, (item) => item.buildingUuid)).map(
        (item) => ({
          // @ts-ignore
          buildingUuid: item[0].buildingUuid,
          // @ts-ignore
          building_name: item[0].buildingName,
          doors: item,
          
        })
      ),
    [currentData]
  );

  const entranceDoors = useMemo(
    () =>
      selectedBuilding?.doors?.filter(
        (door) => door?.doorType === "entrance"
      ) ?? [],
    [selectedBuilding?.doors]
  );

  const otherDoorSelected = React.useMemo(
    () =>
      Boolean(selectedDoors.filter((it) => it.doorType !== "entrance").length),
    [selectedDoors]
  );

  const removeDoor = (door: DoorResponse) => {
    const newSelection = selectedDoors.filter(
      (it) => it.doorUuid !== door.doorUuid
    );
    setSelectedDoors(newSelection);
    onSelect(newSelection);
  };

  const onDoorSelected = useCallback(
    (door: DoorResponse, checked?: boolean) => {
      if (!checked) {
        removeDoor(door);
        return;
      }

      if (selectedDoors.findIndex((it) => it.doorUuid === door.doorUuid) >= 0) {
        return;
      }

      const newSelection = [...selectedDoors];

      if (door.doorType === "entrance" || commonAreasSelected) {
        newSelection.push(door);
      } else {
        newSelection.push(door);

        const previouslyAddedEntranceDoors = selectedDoors
          .filter((it) => it.doorType === "entrance")
          .map((it) => it.doorUuid);

        // add all other entrance door
        newSelection.push(
          ...entranceDoors.filter(
            (it) => !previouslyAddedEntranceDoors.includes(it.doorUuid)
          )
        );
        setCommonAreasSelected(true);
      }

      setSelectedDoors(newSelection);
      onSelect(newSelection);
    },
    [selectedDoors, entranceDoors, onSelect]
  );

  React.useEffect(() => {
    const building = buildings?.[0];
    setSelectedBuilding(building);
    setSelectedDoors(
      building
        ? selectedDoorList?.filter(
            (it) => it.buildingUuid === building.buildingUuid
          ) ?? []
        : []
    );
  }, [buildings, selectedDoorList]);

  return (
    <div
      className="door-list-main-container"
      data-testid="door-list-wrapper-container"
    >
      <div className="building-drop-down-wrapper">
        <SelectField<Building, Building>
          icon={DownIcon}
          name="buildingId"
          label="Building"
          data={buildings}
          selectedValue={selectedBuilding}
          onSelection={(selected) => setSelectedBuilding(selected)}
          valueExtractor={(item) => item}
          labelExtractor={(item) => item.building_name}
          keyExtractor={(item) => item.buildingUuid.toString()}
        />
      </div>

      <div className="door-list-selection-wrapper">
        <div className="door-list-title">
          <H4>All Doors</H4>
        </div>
        <div className="door-list-container">
          <div className="door-list-selected-container">
            {selectedBuilding?.doors?.map((item) => {
              const checked =
                selectedDoors.findIndex(
                  (it) => it.doorUuid === item.doorUuid
                ) >= 0;
              return (
                <DoorListSelectItem
                  door={item!}
                  checked={checked}
                  key={item.doorUuid}
                  onClick={() => {
                    onDoorSelected(item, !checked);
                  }}
                  otherDoorSelected={otherDoorSelected}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export { DoorListSelectionWrapperComponent };

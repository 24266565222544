export const accessTypesInfo = [
  {
    value: "guest",
    title: "Guest",
    description:
      "Guests are short-term visitors and are given temporary, limited access to a unit. They cannot invite other guests or view activity history for their access",
  },
  {
    value: "resident",
    title: "Resident",
    description:
      "Residents have full access to a unit in a building. They can invite guests to access their unit and view activity history for their unit",
  },
  {
    value: "organization-manager",
    title: "Org. Manager",
    description:
      "Org. Managers are admin level users for an entire organization. They can manage all other users and doors for any building in their organization",
  },
  {
    value: "building-manager",
    title: "Manager",
    description:
      "Managers are admin level users for one or more buildings. They can manage all staff, residents, and guests as well as all doors for their assigned buildings",
  },
  {
    value: "building-staff",
    title: "Staff",
    description:
      "Staff are functional users for one or more buildings. They are automatically given access to common doors.",
  },
];

import React, { useMemo } from "react";
import styled from "styled-components";
import { To, useLocation, useNavigate } from "react-router-dom";

import { NavLinkButtonWrapperProps, NavLinkWrapper } from "./nav-link-button/nav-link-button";

type NavLinkOnClickRequired = {
  to?: To;
  onPress?: () => void;
}

type NavLinkOnClickNotRequired = {
  to?: To
  onPress?: () => void;
}
type NavLinkActionProps = NavLinkOnClickRequired | NavLinkOnClickNotRequired;

export interface NavLinkButtonProps extends Partial<Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "ref">>, NavLinkButtonWrapperProps {
  prefixIcon?: React.ElementType;
  suffixIcon?: React.ElementType;
}

const NavLinkContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function NavLinkButtonDelicate(
  {
    to,
    onPress,
    prefixIcon,
    suffixIcon,
    children,
    ...rest
  }: NavLinkButtonProps & NavLinkActionProps,
) {
  const location = useLocation();
  const navigate = useNavigate();
  const PrefixIcon = prefixIcon;
  const SuffixIcon = suffixIcon;
  const isActive = useMemo(() => {
    const locationArray = location.pathname.split("/");
    const toArray = to?.toString()
      .split("/");

    return toArray ? locationArray[1] === toArray[1] : false;
  }, [location, to]);

  return (
    <NavLinkWrapper
      isLinkOpened={isActive}
      onClick={to ? (() => navigate(to)) : onPress}
      {...rest}
    >
      <NavLinkContentWrapper>
        {
          PrefixIcon && <PrefixIcon />
        }

        {children}

        {
          SuffixIcon && <SuffixIcon />
        }
      </NavLinkContentWrapper>

    </NavLinkWrapper>
  );
}

NavLinkButtonDelicate.defaultProps = {
  prefixIcon: undefined,
  suffixIcon: undefined,
};

const NavLink = React.memo(NavLinkButtonDelicate);
export { NavLink };

/* eslint-disable max-len, no-extend-native, func-names */
import { capitalize } from "../utils";

declare global {
  interface String {
    capitalize(): string;
  }
}

// TO DO: test this function
/* istanbul ignore next */
String.prototype.capitalize = function () {
  return capitalize(this) ?? "";
};

import { useTheme } from "styled-components";
import moment from "moment";

import { GuestDoorAccess } from "../../../../../../common/redux/services/model";

import { DoorImgWrapper } from "../../../../my-access/components/door/door";

import {
  BodyText,
  H4,
  Button,
} from "../../../../../../common/components/design-system";
import { insert } from "../../../../../../common/utils";
import DeleteIcon from "@mui/icons-material/Delete";

import { ReactComponent as DoorIcon } from "assets/icons/single-door.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

import "./access-item.scss";

interface AccessItemProps {
  accessVariant?: "add_guest" | "profile";
  doorAccess: GuestDoorAccess;
  onClick: (doorAccess: GuestDoorAccess) => void;
  onDelete: (doorAccess: GuestDoorAccess) => void;
  showPin?: boolean;
}

function AccessItem({
  doorAccess,
  onClick,
  accessVariant,
  onDelete,
  showPin,
}: AccessItemProps) {
  const theme = useTheme();

  let startedAt = doorAccess?.startedAt;
  let endedAt = doorAccess?.endedAt;
  if (!startedAt) {
    // @ts-ignore
    startedAt = doorAccess?.startedAt;
  }
  if (!endedAt) {
    // @ts-ignore
    endedAt = doorAccess?.endedAt;
  }

  const startTimezoneOffset = startedAt ? startedAt.substring(startedAt.lastIndexOf(":") - 3, startedAt.length) : "+00:00";
const endTimezoneOffset = endedAt ? endedAt.substring(endedAt.lastIndexOf(":") - 3, endedAt.length) : "+00:00";


  return (
    <div className="access-item-wrapper">
      <div className="access-item-container">
        <div
          className="access-item-img-detail"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              position: "relative",
              marginLeft: -15,
              marginTop: -7,
              marginBottom: -2,
            }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: "black",
                marginLeft: 32, //half of the icon width
              }}
              data-testid="door-item-divider"
            />
            <div
              style={{
                borderRadius: 32,
                width: 64,
                height: 64,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid",
              }}
            >
              <DoorIcon />
            </div>
          </div>
        </div>
        <div className="access-item-detail-wrapper">
          <div className="access-item-door">
            <H4
              style={{
                margin: 0,
                display: "flex",
                alignItems: "center", // Optional, to align items vertically
              }}
              color={
                // @ts-ignore
                doorAccess?.isExpired
                  ? theme.color.text.titleExpired
                  : theme.color.text.default
              }
            >
              {doorAccess.doorName}
              <span
                style={{
                  fontFamily: "arial",
                  fontStyle: "italic",
                  marginLeft: "8px",
                }}
              >
                {/* @ts-ignore */}
                {doorAccess?.isExpired ? "• Expired" : ""}
              </span>
            </H4>
            {showPin != false && accessVariant === "profile" && (
              <H4 color={theme.color.interactive.default} style={{ margin: 0 }}>
                {insert(doorAccess?.padPin, " ", 3) ?? "XXX XXX"}
              </H4>
            )}
          </div>
          <div className="access-date">
            <BodyText
              color={
                // @ts-ignore
                doorAccess?.isExpired
                  ? theme.color.text.textExpired
                  : theme.color.text.subdued
              }
            >
              {`Start: ${
                startedAt
                  ? moment(startedAt)
                      .utcOffset(startTimezoneOffset)
                      .format("MMM DD, YYYY [•] hh:mmA")
                  : "none"
              }`}
            </BodyText>
            <BodyText
              color={
                // @ts-ignore
                doorAccess?.isExpired
                  ? theme.color.text.textExpired
                  : theme.color.text.subdued
              }
            >
              {`End: ${
                endedAt
                  ? moment(endedAt)
                      .utcOffset(endTimezoneOffset)
                      .format("MMM DD, YYYY [•] hh:mmA")
                  : "none"
              }`}
            </BodyText>
          </div>
        </div>
      </div>

      {accessVariant === "profile" ? (
        <div
          data-testid="edit-button"
          className="access-edit-icon"
          onClick={() => onClick(doorAccess)}
        >
          <EditIcon
            fill={theme.color.text.default}
            stroke={theme.color.text.default}
          />
        </div>
      ) : (
        <div
          data-testid="delete-button"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            // isLoading={isLoading}
            type="submit"
            size="lg"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            onClick={() => {
              onDelete(doorAccess);
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      )}
    </div>
  );
}
AccessItem.defaultProps = {
  accessVariant: "profile",
};
export { AccessItem };

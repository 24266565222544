import { Constants } from "assets/constants";
import { Api } from "./base/api";

export async function addDoorAccess(data: any) {
  const result = await Api.post(Constants.apiEndpoint.addDoorAccess, data);
  return Promise.resolve(result);
}

export async function guestDetail(
  id: string,
  nickname: string,
  pinOnly: string
) {
  const result = await Api.get(
    pinOnly != "true"
      ? `${Constants.apiEndpoint.users}/${id}/door-access?nickname=${nickname}`
      : `api/v1/door-pins/${id}/door-access`
  );
  return Promise.resolve(result);
}

export async function updateGuest(
  id: string,
  actualNickname: string,
  newNickname: string
) {
  const result = await Api.put(`${Constants.apiEndpoint.users}/${id}/nickname`);
  return Promise.resolve(result);
}

export async function deleteGuest(id: string, name: string) {
  const result = await Api.post(
    `${Constants.apiEndpoint.users}/${id}/revoke-access?nickname=${name}`
  );
  return Promise.resolve(result);
}

export async function deleteGuestDoorAccess(id: string) {
  const result = await Api.delete(`${Constants.apiEndpoint.doorAccess}/${id}`);
  return Promise.resolve(result);
}

export async function updateGuestDoorAccess(doorAccessUuid: string, data: any) {
  const result = await Api.patch(`/api/v1/door-access/${doorAccessUuid}`, data);
  return Promise.resolve(result);
}

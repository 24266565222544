/* istanbul ignore file */
import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import "assets/fonts/stylesheet.scss";
import "./index.css";
import "common/extension/string";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// @ts-ignore
import { GoogleOAuthProvider } from "@react-oauth/google";

createRoot(document.getElementById("root")!).render(
  <GoogleOAuthProvider
    clientId={process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID ?? ""}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

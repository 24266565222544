import { useCallback, useState } from "react";
import { useTheme } from "styled-components";

import { Button, H3, H4, NavLink } from "../design-system";
import { LocalStorage } from "../../service";
import { Constants } from "../../../assets/constants";
import "./top-nav.scss";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { MenuItem } from "../sidebar/Type";
import { useWindowDimension } from "../../utils";

import { TopNavProps } from "./type";

import { ReactComponent as AppIcon } from "assets/icons/appIcon.svg";

const navMenuList: MenuItem[] = [
  {
    name: "My Access",
    path: "/",
  },
  {
    name: "Guests",
    path: "/guests",
  },
];

function TopNav({ navigationOption }: TopNavProps) {
  const theme = useTheme();
  const [showSideBar, setShowSideBar] = useState<boolean | undefined>(false);
  const windowDimension = useWindowDimension();
  const renderTitle = useCallback(
    () =>
      windowDimension.width <= 555 && navigationOption?.showLogo ? (
        <div
          data-testid={"app-icon"}
          style={{ marginTop: "6px" }}
        >
          <AppIcon />
        </div>
      ) : (
        <H3>{navigationOption?.title}</H3>
      ),
    [windowDimension, navigationOption]
  );
  return (
    <div className="top-nav-wrapper ">
      <div className="sidebar-wrapper-mb">
        <div className="menu-icon-mb">
          <MenuIcon
            data-testid={"menu-button-inactive"}
            stroke="black"
            onClick={() => setShowSideBar(!showSideBar)}
          />
        </div>
        <div className="sidebar-container-mb">
          <div className="title-icon">{renderTitle()}</div>
        </div>
        {navigationOption?.rightComponent?.()}

        {showSideBar ? (
          <div
            className="sidebar-overlay-mb-wrapper"
            data-testid={"sidebar"}
          >
            <div className="sidebar-overlay-left">
              <div className="sidebar-overlay-left-wrapper">
                <div className="sidebar-overlay-menu-wrapper">
                  <MenuIcon
                    data-testid={"menu-button-active"}
                    stroke={theme.color.icon.subdued}
                    onClick={() => setShowSideBar(false)}
                  />
                  <AppIcon data-testid={"app-icon"} />
                </div>
                <div className="sidebar-overlay-main-menu-wrapper">
                  {navMenuList.map((navMenu: MenuItem) => (
                    <NavLink
                      key={navMenu.path}
                      to={navMenu.path}
                      onPress={navMenu.onPress}
                    >
                      {navMenu.name}
                    </NavLink>
                  ))}
                </div>
                <div className="sidebar-logout-wrapper">
                  <Button
                    data-testid="logout-button"
                    variant="plain"
                    size="sm"
                    onClick={() => {
                      LocalStorage.removeItem(Constants.storageKey.userSession);
                    }}
                    style={{
                      width: "63px",
                    }}
                  >
                    <H4 color={theme.color.background.darkSelected}>Logout</H4>
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="sidebar-overlay-right"
              onClick={() => setShowSideBar(false)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export { TopNav };

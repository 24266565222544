import React from "react";
import styled, { css } from "styled-components";

export type Size = "sm" | "md" | "lg";
export type Variant =
  | "basic"
  | "primary"
  | "destructive"
  | "outline-monochrome"
  | "plain"
  | "plain-destructive";

export interface ButtonWrapperProps {
  variant?: string;
  size?: Size;
  isLoading?: boolean;
  width?: string;
}

// @ts-ignore
const ButtonWrapper = styled.button.attrs((props: ButtonWrapperProps) => props)`
  width: ${(props) => {
    // @ts-ignore
    return props.width ?? "100%";
  }};
  font-family: ${(props) => props.theme.fontFamily.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;

  height: ${(props) => {
    // @ts-ignore
    return props.theme.size.button[props.size || "sm"];
  }};

  border-radius: 4px;

  ${(props) =>
    // @ts-ignore
    props.variant === "basic" &&
    css`
      background: ${props.theme.color.action.secondaryDefault};
      border: 1px solid ${props.theme.color.border.subdued};
      color: ${props.theme.color.text.default};
      fill: ${props.theme.color.text.default};

      &:hover {
        background: ${props.theme.color.action.secondaryHover};
      }

      &:focus {
        outline: 2px solid ${props.theme.color.focused.default} !important;
        outline-offset: 1px;
        background: ${props.theme.color.action.secondaryHover};
      }

      &:active {
        background: ${props.theme.color.action.secondaryPressed};
      }

      &:disabled {
        background: ${props.theme.color.surface.disabled};
        border: 1px solid ${props.theme.color.border.disabled};
        color: ${props.theme.color.text.disabled};
        fill: ${props.theme.color.text.disabled};
      }
    `}

  ${(props) =>
    // @ts-ignore
    props.variant === "primary" &&
    css`
      background: ${props.theme.color.action.primaryDefault};
      border: none;
      color: ${props.theme.color.text.onPrimary};
      fill: ${props.theme.color.text.onPrimary};
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
        inset 0px -1px 0px rgba(0, 0, 0, 0.2);

      &:hover {
        background: ${props.theme.color.action.primaryHover};
      }

      &:focus {
        outline: 2px solid ${props.theme.color.focused.default} !important;
        outline-offset: 1px;
        background: ${props.theme.color.action.primaryDefault};
      }

      &:active {
        background: ${props.theme.color.action.primaryPressed};
      }

      &:disabled {
        background: ${props.theme.color.surface.disabled};
        border: 1px solid ${props.theme.color.border.disabled};
        color: ${props.theme.color.text.disabled};
        fill: ${props.theme.color.text.disabled};
        box-shadow: none;
      }
    `}

  ${(props) =>
    // @ts-ignore
    props.variant === "destructive" &&
    css`
      background: ${props.theme.color.action.criticalDefault};
      border: none;
      color: ${props.theme.color.text.onPrimary};
      fill: ${props.theme.color.text.onPrimary};
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
        inset 0px -1px 0px rgba(0, 0, 0, 0.2);

      &:hover {
        background: ${props.theme.color.action.criticalHover};
      }

      &:focus {
        outline: 2px solid ${props.theme.color.focused.default} !important;
        outline-offset: 1px;
        background: ${props.theme.color.action.criticalDefault};
      }

      &:active {
        background: ${props.theme.color.action.criticalPressed};
      }

      &:disabled {
        background: ${props.theme.color.surface.disabled};
        border: 1px solid ${props.theme.color.border.disabled};
        color: ${props.theme.color.text.disabled};
        fill: ${props.theme.color.text.disabled};
      }
    `}

  ${(props) =>
    // @ts-ignore
    props.variant === "outline-monochrome" &&
    css`
      background: ${props.theme.color.action.secondaryDefault};
      color: ${props.theme.color.text.critical};
      fill: ${props.theme.color.text.critical};
      border: 1px solid ${props.theme.color.interactive.critical};

      &:hover {
        background: ${props.theme.color.surface.criticalSubdued};
      }

      &:focus {
        outline: 2px solid ${props.theme.color.focused.default} !important;
        outline-offset: 1px;
      }

      &:active {
        background: ${props.theme.color.surface.criticalSubdued};
      }

      &:disabled {
        background: ${props.theme.color.surface.disabled};
        border: 1px solid ${props.theme.color.border.disabled};
        color: ${props.theme.color.text.disabled};
        fill: ${props.theme.color.text.disabled};
      }
    `}

  ${(props) =>
    // @ts-ignore
    props.variant === "plain" &&
    css`
      border: none;
      height: auto;
      width: auto;
      background: none;
      color: ${props.color ?? props.theme.color.interactive.default};
      font-weight: 400;
      fill: ${props.theme.color.interactive.default};

      &:hover {
        text-decoration: underline;
        text-decoration-color: ${props.color ??
        props.theme.color.interactive.default};
      }

      &:focus {
        outline: 2px solid ${props.theme.color.focused.default} !important;
        outline-offset: 1px;
        background: none;
      }

      &:active {
        color: ${props.theme.color.interactive.depressed};
        background: ${props.theme.color.action.secondaryHover};
      }

      &:disabled {
        background: ${props.theme.color.action.secondaryDisabled};
        color: ${props.theme.color.interactive.disabled};
        fill: ${props.theme.color.interactive.disabled};
        text-decoration: none;
      }
    `}
  ${(props) =>
    // @ts-ignore
    props.variant === "plain-destructive" &&
    css`
      border: none;
      height: auto;
      width: auto;
      background: none;
      color: ${props.theme.color.interactive.critical};
      font-weight: 400;
      fill: ${props.theme.color.interactive.critical};

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        outline: 2px solid ${props.theme.color.focused.default} !important;
        outline-offset: 1px;
        background: none;
      }

      &:active {
        color: ${props.theme.color.interactive.criticalDepressed};
        fill: ${props.theme.color.interactive.criticalDepressed};
        background: ${props.theme.color.action.secondaryHover};
      }

      &:disabled {
        background: ${props.theme.color.action.secondaryDisabled};
        color: ${props.theme.color.interactive.disabled};
        fill: ${props.theme.color.interactive.disabled};
        text-decoration: none;
      }
    `}
`;
ButtonWrapper.defaultProps = {
  // @ts-ignore
  variant: "basic",
};
export { ButtonWrapper };

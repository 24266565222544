import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { toast } from "react-toastify";

import { AppRoute, UserRole } from "../../../navigations/Types";
import { useDeleteGuestMutation } from "../../../../common/redux/query";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";
import {
  BuildingWithDoorAccess,
  GuestDetail,
  GuestDoorAccess,
} from "../../../../common/redux/services/model";
import { groupBy } from "../../../../common/utils";
import { RightSidebarLayout } from "../../../../common/components/right-sidebar-layout";
import { EditGuest } from "../edit-guest/edit-guest";
import { EditGuestAccess } from "../edit-guest-access";
import { H4, H3 } from "../../../../common/components/design-system";
import { AddDoor } from "../add-door/add-door";
import { Button } from "@mui/material";
import { GuestProfileCard } from "./components/guest-profile-card/guest-profile-card";
import "./guest-profile.scss";
import { AccessList } from "./components/access-list/access-list";
import { guestDetail } from "common/redux/services/guests.service";
import Checkbox from "@mui/material/Checkbox";

function GuestProfile() {
  const { id, name, isPinOnly, email } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();
  const [doorAccess, setDoorAccess] = useState<GuestDoorAccess | undefined>();
  const [showPin, setShowPin] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [isPinDetails, setIsPinDetails] = useState(true);

  const guestOption: HeaderOption = {
    url: "/guests",
    title: isPinDetails ? "PIN Details" : "Guest Details",
    showLogo: false,
    rightComponent: () => (
      <div className="top-nav-right-wrapper">
        <Button
          variant="text"
          onClick={() => setShowForm("add_door")}
        >
          Add door
        </Button>

        {/* <Button variant="text" onClick={() => setShowForm("edit_guest")}>
          Edit
        </Button> */}
      </div>
    ),
  };

  const [currentData, setCurrentData] = useState<GuestDetail>();

  async function refetchGuestDetail() {
    let result = await guestDetail(
      id ?? "",
      name ?? "",
      // email ?? "",
      // @ts-ignore
      isPinOnly
    );
    if (result) {
      if (result.data.allowedEmail) {
        setIsPinDetails(false);
      } else {
        setIsPinDetails(true);
      }
      // @ts-ignore
      setCurrentData(result.data);
    }
  }

  const [
    deleteGuest,
    {
      isLoading: deleteGuestLoading,
      isSuccess: deleteGuestSuccess,
      data: deleteGuestResponse,
      ...deleteGuestMutation
    },
  ] = useDeleteGuestMutation();

  useEffect(() => {
    const response = deleteGuestResponse;
    if (deleteGuestSuccess) {
      toast.success("Guest deleted successfully");
      navigate("../guests");
      // close the add door modal
    }
  }, [deleteGuestSuccess, deleteGuestResponse]);

  useEffect(() => {
    if (deleteGuestMutation.isError) {
      toast.error((deleteGuestMutation.error as Error).message);
      deleteGuestMutation.reset();
    }
  }, [
    deleteGuestMutation.error,
    deleteGuestMutation.isError,
    deleteGuestMutation.reset,
  ]);

  const buildingData = useMemo(
    () =>
      currentData &&
      Object.values(
        groupBy(currentData?.doorAccesses, (item) => item.buildingUuid)
      )
        .filter((it) => it.length > 0)
        .map(
          (items) =>
            // @ts-ignore
            ({
              id: items[0].buildingUuid,
              name: items[0].buildingName,
              doors: items.map((door) => ({
                ...door,
                doorUuid: door?.doorUuid,
                doorName: door?.doorName,
                buildingUui: door?.buildingUuid,
                buildingName: door?.buildingName,
                // @ts-ignore
                endedAt: door?.endedAt,
                // @ts-ignore
                startedAt: door?.startedAt,
                padPin: door?.padPin,
                doorAccessUuid: door?.doorAccessUuid,
              })),
            } as BuildingWithDoorAccess)
        ),
    [currentData?.doorAccesses]
  );

  const renderSubcomponent = useCallback(() => {
    switch (showForm) {
      // TODO: test this later
      // istanbul ignore next
      case "edit_guest":
        return (
          <EditGuest
            guest={currentData!}
            onClose={async () => {
              setShowForm(undefined);
              await refetchGuestDetail();
            }}
          />
        );
      // TODO: test this later
      // istanbul ignore next
      case "edit_access":
        return (
          <EditGuestAccess
            totalAccess={currentData?.doorAccesses?.length!}
            userId={currentData?.userUuid!}
            doorAccess={doorAccess!}
            onClose={() => {
              setShowForm(undefined);
            }}
          />
        );

      case "add_door":
        return (
          <AddDoor
            guestName={name}
            guestId={currentData?.userUuid!}
            guestInfo={currentData!}
            onClose={() => {
              setShowForm(undefined);
              refetchGuestDetail();
            }}
          />
        );

      default:
        return undefined;
    }
  }, [showForm, doorAccess, currentData]);

  useEffect(() => {
    refetchGuestDetail();
  }, [showForm]);

  const pinMessage = showPin ? (
    <h5
      style={{
        textAlign: "right",
        margin: 10,
        color: "#0F62FE",
        fontSize: 16,
        textTransform: "none",
      }}
    >
      Hide PINs
    </h5>
  ) : (
    <h5
      style={{
        textAlign: "right",
        margin: 10,
        color: "#0F62FE",
        fontSize: 16,
        textTransform: "none",
      }}
    >
      Show PINs
    </h5>
  );

  let hasValidDoorAccess = false;
  buildingData?.forEach((building) => {
    // @ts-ignore
    if (building.doors.some((door) => !door.isExpired)) {
      hasValidDoorAccess = true;
    }
  });

  // istanbul ignore next
  function renderContent() {
    return (
      <div
        className="guest-profile-wrapper"
        data-testid={"guest-profile-wrapper"}
      >
        <div>
          <GuestProfileCard
            guest={currentData!}
            nickname={name}
            isPinOnly={isPinDetails}
          />
        </div>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Checkbox
                style={{ float: "right", borderWidth: 0 }}
                onClick={() => {
                  setShowExpired(!showExpired);
                }}
                checked={showExpired}
              />
              Show Expired
            </div>
            <Button
              variant="text"
              onClick={() => {
                setShowPin(!showPin);
              }}
              style={{ borderWidth: 0 }}
            >
              {pinMessage}
            </Button>
          </div>
          <div className="access-list-box">
            <AccessList
              accessList={buildingData!}
              showPin={showPin}
              onEdit={(doorAccess) => {
                setDoorAccess(doorAccess);
                setShowForm("edit_access");
              }}
              onDelete={(doorAccess) => {}}
              showExpired={showExpired}
              hasValidDoorAccess={hasValidDoorAccess}
            />
          </div>
        </div>
        <div className="delete-wrapper">
          <Button
            //variant="plain"
            //color={theme.color.text.critical}
            data-testid="delete-guest-button"
            onClick={async () => {
              if (
                window.confirm(
                  `Are you sure you want to delete the ${
                    isPinDetails ? "PIN" : "guest"
                  }?`
                )
              ) {
                await deleteGuest({
                  id: id ?? "",
                  name: name ?? "",
                  isPinOnly: isPinOnly ?? "",
                });
              }
            }}
          >
            <h5 style={{ color: "#FF2100" }}>
              {isPinDetails ? "Remove PIN" : "Remove Guest"}
            </h5>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div data-testId={"guest-profile-container"}>
      <RightSidebarLayout
        visible={!!showForm}
        onClose={() => setShowForm(undefined)}
      >
        {renderSubcomponent()}
      </RightSidebarLayout>
      <TopNav navigationOption={guestOption} />
      {renderContent()}
    </div>
  );
}
const route: AppRoute = {
  name: "Guest Profile",
  screen: GuestProfile,
  path: "/guests/:id/:name/:isPinOnly",
  role: [UserRole.Authenticated],
};
export { GuestProfile, route };

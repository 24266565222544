import { useEffect, useState } from "react";

import { Constants } from "../../assets/constants";
import { TokenUtils } from "../utils";

import { LocalStorage } from "./storage";

export interface Session {
  userId: string;
  email: string;
  displayName: string;
  expireIn: number;
  accessToken: string;
  refreshToken?: string;
}

export type SessionState = "unknown" | "authenticated" | "unauthenticated";

export interface SessionInfo {
  session: Session | null;
  state: SessionState;
}

function mapSessionInfo(session: Session | null): SessionInfo {
  return {
    state: TokenUtils.isTokenExpired(session?.accessToken)
      ? "unauthenticated"
      : "authenticated",
    session,
  };
}

export function useSession() {
  const [session, setSession] = useState<SessionInfo>({
    state: "unknown",
    session: null,
  });

  useEffect(() => {
    setSession(
      mapSessionInfo(
        LocalStorage.getItem<Session>(Constants.storageKey.userSession)
      )
    );
    const subscription = LocalStorage.registerListener<Session>(
      Constants.storageKey.userSession,
      (s) => {
        setSession(mapSessionInfo(s));
      }
    );
    return () => {
      subscription();
    };
  }, []);

  return session;
}

import styled, { useTheme } from "styled-components";

import { GuestDetail } from "../../../../../../common/redux/services/model";
import {
  H2,
  LabelText,
} from "../../../../../../common/components/design-system";
import "./guest-profile-card.scss";
import { DoorImgWrapper } from "../../../../my-access/components/door/door";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import SaveIcon from "@mui/icons-material/Save";
import { ReactComponent as MultiUserIcon } from "assets/icons/multi-user.svg";
import { ReactComponent as PinIcon } from "assets/svg/pinIcon.svg";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUpdateGuestMutation } from "common/redux/query";
import { useFormik } from "formik";
import * as Yup from "yup";

const guestSchema = Yup.object().shape(
  {
    name: Yup.string().trim().required("Full Name is required"),
  },
  []
);

export const GuestProfileCardWrapper = styled.div.attrs((props) => props)`
  background: ${(props) => props.theme.color.background.darkDefault};
  padding: 17px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 45px;
`;
interface GuestProfileCardProps {
  guest: GuestDetail;
  nickname?: string;
  isPinOnly?: boolean;
}
function GuestProfileCard({
  guest,
  nickname,
  isPinOnly,
}: GuestProfileCardProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [guestName, setGuestName] = useState(nickname);
  const [oldName, setOldName] = useState(nickname);
  const textInputRef = useRef<HTMLInputElement | null>(null);
  const borderColor = isEditing ? "white" : "none";
  const borderWidth = isEditing ? 1 : 0;

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [isEditing]);

  const [
    updateGuest,
    { isLoading, isSuccess, data: updateGuestResponse, ...updateGuestMutation },
  ] = useUpdateGuestMutation();

  const {
    values,
    dirty,
    errors,
    isValid,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      name:
        // @ts-ignore
        guest?.allowedNickname ||
        // @ts-ignore
        guest?.doorAccesses[0].allowedNickname,
      // @ts-ignore
      email: guest?.allowedEmail || "",
    },
    validationSchema: guestSchema,
    onSubmit: async (params, helpers) => {
      // @ts-ignore
      if (guest?.allowedUserUuid) {
        await updateGuest({
          // @ts-ignore
          id: guest?.allowedUserUuid,
          // @ts-ignore
          actualNickname: oldName,
          newNickname: params?.name?.trim(),
        });
        // @ts-ignore
        const newUrl = `../guests/${guest?.doorAccessPinUuid}/${params?.name}/true`;
        navigate(newUrl);
      } else {
        await updateGuest({
          // @ts-ignore
          pinId: guest?.doorAccessPinUuid,
          newName: params?.name?.trim(),
        });
        // @ts-ignore
        const newUrl = `../guests/${guest?.doorAccessPinUuid}/${params?.name}/false`;
        // navigate(newUrl);
      }
    },
  });

  useEffect(() => {
    const response = updateGuestResponse;
    if (isSuccess && response) {
      setOldName(guestName);
      toast.success(`${isPinOnly ? "PIN" : "Guest"} updated successfully`);
    }
  }, [isSuccess, updateGuestResponse]);

  useEffect(() => {
    if (updateGuestMutation.isError) {
      toast.error((updateGuestMutation.error as Error).message);
      updateGuestMutation.reset();
    }
  }, [
    updateGuestMutation.error,
    updateGuestMutation.isError,
    updateGuestMutation.reset,
  ]);

  const handleIconClick = () => {
    if (isEditing) {
      event?.preventDefault();
      handleSubmit();
    }
    setIsEditing((prev) => !prev);
  };

  return (
    <GuestProfileCardWrapper>
      <form
        id="edit_guest_form"
        onSubmit={() => {
          setIsEditing(false);
          event?.preventDefault();
          handleSubmit();
        }}
        className="guest-name"
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 24,
        }}
      >
        <input
          data-testid="name-input"
          style={{
            flex: 1,
            backgroundColor: "#1B1B1B",
            color: "white",
            borderWidth: borderWidth,
            borderColor: borderColor,
            outline: "none",
            fontSize: 30,
            fontWeight: 600,
            padding: 8,
            borderRadius: 8,
          }}
          maxLength={25}
          type="text"
          placeholder="Guest name"
          value={guestName}
          disabled={!isEditing}
          onChange={(text) => {
            setGuestName(text.target.value);
            setFieldValue("name", text.target.value);
          }}
          ref={textInputRef}
        />

        <div
          style={{
            display: "flex",
            cursor: "pointer",
          }}
          data-testid="edit-button"
          className="access-edit-icon"
          onClick={handleIconClick}
        >
          {isEditing ? (
            <SaveIcon
              style={{ fill: "white", stroke: "white" }}
              width={30}
              height={30}
            />
          ) : (
            <EditIcon
              fill={"white"}
              stroke={"white"}
              width={30}
              height={30}
            />
          )}
        </div>
      </form>
      <div className="guest-profile-detail-wrapper">
        <div className="guest-profile-email-phone-wrapper">
          <LabelText color={theme.color.text.onPrimary}>
            {/* @ts-ignore */}
            {guest?.allowedPhoneNumber ?? "--"}
          </LabelText>
          <LabelText color={theme.color.text.onPrimary}>
            {/* @ts-ignore */}
            {guest?.allowedEmail ?? "--"}
          </LabelText>
        </div>
        <div className="guest-profile-image-wrapper">
          <DoorImgWrapper
            style={{
              border: `1px solid ${theme.color.text.onPrimary}`,
            }}
          >
            {isPinOnly ? (
              <PinIcon stroke={theme.color.text.onPrimary} />
            ) : (
              <MultiUserIcon stroke={theme.color.text.onPrimary} />
            )}
          </DoorImgWrapper>
        </div>
      </div>
    </GuestProfileCardWrapper>
  );
}
export { GuestProfileCard };

import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { AppRoute, UserRole } from "../../../navigations/Types";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";
import { Button } from "../../../../common/components/design-system";
import "./guests.scss";
import { InputFieldIcon } from "../../../../common/components/design-system/text-field/input-field-icon/input-field-icon";
import { useGuestsQuery } from "../../../../common/redux/query";
import { RightSidebarLayout } from "../../../../common/components/right-sidebar-layout";
import { AddGuest } from "../add-guest";

import { GuestItems } from "./components/guest-items/guest-items";

import { Constants } from "assets/constants";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { Guest } from "../../../../common/redux/services/model/guest";
import { Checkbox } from "@mui/material";

function GuestsComponent() {
  const guestOption: HeaderOption = {
    url: "/guests",
    title: "Guests",
    showLogo: false,
  };
  const theme = useTheme();

  const [params] = useState(Constants.Config.paging);
  const [showForm, setShowForm] = useState<string | undefined>();
  const [showExpired, setShowExpired] = useState(false);

  const renderSubcomponent = useCallback(() => {
    switch (showForm) {
      case "guest_form":
        return (
          <AddGuest
            onClose={() => {
              setShowForm(undefined);
              refetch();
            }}
          />
        );

      default:
        return undefined;
    }
  }, [showForm]);

  const {
    currentData: guestList,
    error: guestLoadError,
    isFetching: guestFetchingError,
    refetch,
  } = useGuestsQuery(params);

  const [searchText, setSearchText] = useState("");
  const [filteredGuests, setFilteredGuests] = useState<Guest[] | undefined>([]);
  useEffect(() => {
    // filter out guests with no door access if showExpired is false
    let filtered = guestList?.data.filter((guest) => {
      const hasNoDoorAccess = guest.doorList?.length === 0;
      if (hasNoDoorAccess && !showExpired) return false;
      return true;
    });

    if (searchText) {
      filtered = filtered?.filter((guest) => {
        return (
          guest?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
          // @ts-ignore
          guest?.allowedNickname
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
        );
      });
      setFilteredGuests(filtered);
    } else {
      setFilteredGuests(filtered);
    }
  }, [searchText, guestList, showExpired]);

  interface InputText {
    nativeEvent: {
      inputType: string;
      data: string;
    };
  }

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <RightSidebarLayout
        visible={!!showForm}
        onClose={() => setShowForm(undefined)}
      >
        {renderSubcomponent()}
      </RightSidebarLayout>
      <div style={{ marginTop: 20 }}>
        <TopNav navigationOption={guestOption} />
      </div>
      <div className="guests-list-wrapper">
        <div className="guests-list-container">
          <div className="guests-search-add-wrapper">
            <InputFieldIcon
              style={{ width: "286px", marginTop: 0 }}
              prefixStyle={{ top: "11px" }}
              prefixIcon={() => <SearchIcon />}
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <Button
              variant="basic"
              size="lg"
              style={{
                width: "54px",
                fontSize: "24px",
                color: theme.color.icon.hovered,
              }}
              onClick={() => setShowForm("guest_form")}
            >
              +
            </Button>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Checkbox
                style={{ float: "right", borderWidth: 0 }}
                onClick={() => {
                  setShowExpired(!showExpired);
                }}
                checked={showExpired}
              />
              Show Expired
            </div>
          </div>

          {filteredGuests?.map((guest) => {
            return (
              <GuestItems
                data-testid="guest-item"
                key={guest?.userUuid + guest?.name}
                guestItem={guest}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "Guests",
  screen: GuestsComponent,
  path: "/guests",
  role: [UserRole.Authenticated],
};

export { GuestsComponent, route };

import React from "react";
import styled, { css, useTheme } from "styled-components";

import { CaptionText, LabelText } from "../typography/typography";
import { Button } from "../button/button";
import { InputProps } from "../text-field/input-field/input-field";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/warning.svg";

const TextArea = styled.textarea.attrs((props: InputProps) => props)`
  margin-top: 4px;
  padding: 8px 12px;
  width: 100%;
  height: 172px;
  background-color: ${(props) =>
    // @ts-ignore
    props.backgroundColor || props.theme.color.surface.default};
  border-radius: 4px;
  border: none;
  outline: 2px solid transparent;

  ${(props) =>
    // @ts-ignore
    props.prefixIcon &&
    css`
      padding: 13px 13px 13px 40px;
      line-height: 16px;
    `}
  ${(props) =>
    // @ts-ignore
    props.suffixIcon &&
    css`
      padding: 13px 40px 13px 13px;
      line-height: 16px;
    `}
  ${(props) =>
    props.readOnly &&
    `background-color: ${props.theme.color.surface.default}; `}
  ${(props) =>
    // @ts-ignore
    props.error &&
    `background-color: ${props.theme.color.surface.criticalSubdued}; border: 1px solid ${props.theme.color.border.criticalDefault}; `}
    /* Inside auto layout */
  &:focus {
    background: ${(props) => props.theme.color.surface.default};
    border: 1px solid ${(props) => props.theme.color.border.subdued};
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.color.focused.default};
    ${(props) =>
      // @ts-ignore
      props.error &&
      `background-color: ${props.theme.color.surface.criticalSubdued}; border: 1px solid ${props.theme.color.border.criticalDefault}; outline:none; `}
  }

  &:disabled {
    background: ${(props) => props.theme.color.surface.default};
    color: ${(props) => props.theme.color.text.disabled};
    fill: ${(props) => props.theme.color.text.disabled};
  }

  &::placeholder {
    font-family: ${(props) => props.theme.fontFamily.normal};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.color.text.default};
  }
`;

const ValidationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export interface TextAreaProps
  extends Omit<
      React.DetailedHTMLProps<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
      >,
      "ref"
    >,
    InputProps {
  label?: string;
  caption?: string;
  note?: string;
  error?: boolean;
  errorMessage?: string;
}

function TextAreaDelicate({
  label,
  errorMessage,
  error,
  caption,
  note,
  ...rest
}: TextAreaProps) {
  const theme = useTheme();
  return (
    <div
      data-testid={"text-area-wrapper"}
      className="form-group text-area-wrapper"
    >
      <div className="text-field-title-wrapper">
        <LabelText
          data-testid={"label"}
          color={theme.color.text.subdued}
        >
          {label}
        </LabelText>
        {caption && (
          <Button
            data-testid={"note"}
            hidden={!caption}
            type="button"
            variant="plain"
          >
            {caption}
          </Button>
        )}
      </div>

      <TextArea
        // @ts-ignore
        error={error}
        {...rest}
      />

      <div className="text-field-bottom-text-wrapper">
        {!error ? (
          <CaptionText
            data-testid={"note"}
            color={theme.color.text.subdued}
          >
            {note}
          </CaptionText>
        ) : (
          <ValidationWrapper>
            <WarningIcon fill={theme.color.icon.critical} />
            <CaptionText
              data-testid={"error-message"}
              color={theme.color.text.critical}
            >
              {errorMessage}
            </CaptionText>
          </ValidationWrapper>
        )}
      </div>
    </div>
  );
}

TextAreaDelicate.defaultProps = {
  label: undefined,
  error: false,
  errorMessage: undefined,
  caption: undefined,
  note: undefined,
};
const TextAreaField = React.memo(TextAreaDelicate);
export { TextAreaField };

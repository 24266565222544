/* eslint-disable react/no-direct-mutation-state */
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../navigations/Types";
import { TopNav } from "../../../common/components/top-nav";
import { HeaderOption } from "../../../common/components/top-nav/type";
import { Button } from "../../../common/components/design-system";
import { Api } from "../../../common/redux/services/base/api";

import { InputFieldIcon } from "../../../common/components/design-system/text-field/input-field-icon/input-field-icon";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";

import "./doors.scss";

import { SelectField } from "common/components/design-system/select-field-doors/select-field";
import { BuildingList } from "./building-list/building-list";
import { RightSidebarLayout } from "common/components/right-sidebar-layout";
import { AddNewDoor } from "./add-new-door";
import { Door } from "common/redux/services/model";
import { DoorDetails } from "./door-details";

function Doors() {
  const navigate = useNavigate();
  const theme = useTheme();

  const userOption: HeaderOption = {
    url: "/Doors",
    title: "Doors",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  interface DoorData {
    floor: string;
    lockStatusName: string | undefined;
    name: string | undefined;
    uuid: string | undefined;
  }

  const [showForm, setShowForm] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [buildings, setBuildings] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredBuildings, setFilteredBuildingData] = useState<DoorData[]>([]);

  const [doorData, setDoorsData] = useState([]);
  const [allDoorData, setAllDoorData] = useState<DoorData[]>([]);

  const [selectedBuilding, setSelectedBuilding] = useState("All Properties");
  const [buildingsNames, setBuildingsNames] = useState<string[]>([
    "All Properties",
  ]);
  const [selectedStatus, setSelectedStatus] = useState("All Status");
  const [statusNames, setStatusNames] = useState<string[]>(["All Status"]);
  const [selectedTypes, setSelectedTypes] = useState("All Types");
  const [typesNames, setTypesNames] = useState<string[]>(["All Types"]);
  const [permissionLevels, setPermissions] = useState<string[]>([]);
  const [selectedDoor, setSelectedDoor] = useState({} as Door);

  useEffect(() => {
    let filtered: any = [];
    if (searchText) {
      doorData.forEach((building: any) => {
        const filteredDoors = building.data.filter((door: any) =>
          door.name?.toLowerCase().includes(searchText.toLowerCase())
        );
        if (filteredDoors.length > 0) {
          const newBuilding = {
            name: building.name,
            buildingUuid: building.buildingUuid,
            address1: building.address1,
            administrativeArea: building.administrativeArea,
            locality: building.locality,
            floors: building.floors,
            data: filteredDoors,
          };
          // @ts-ignore
          filtered.push(newBuilding);
        }
      });
    } else {
      filtered = doorData;
    }

    /* Dropdown Filters*/

    // Filter by building
    if (selectedBuilding !== "All Properties") {
      filtered = filtered.filter(
        (building: any) => building.name === selectedBuilding
      );
    }

    //Filter by status
    if (selectedStatus !== "All Status") {
      filtered = filtered.map((building: any) => {
        const newBuilding = {
          name: building.name,
          buildingUuid: building.buildingUuid,
          address1: building.address1,
          administrativeArea: building.administrativeArea,
          locality: building.locality,
          floors: building.floors,
          data: building.data.filter(
            (door: any) => door.lockStatusName === selectedStatus
          ),
        };
        return newBuilding;
      });
    }

    // Filter by type
    if (selectedTypes !== "All Types") {
      filtered = filtered.map((building: any) => {
        const newBuilding = {
          name: building.name,
          buildingUuid: building.buildingUuid,
          address1: building.address1,
          administrativeArea: building.administrativeArea,
          locality: building.locality,
          floors: building.floors,
          data: building.data.filter((door: any) => {
            return door.type === selectedTypes.toLowerCase();
          }),
        };
        return newBuilding;
      });
    }

    setFilteredBuildingData(filtered);
  }, [searchText, doorData, selectedBuilding, selectedStatus, selectedTypes]);

  async function getUser(): Promise<any> {
    const result = await Api.get("/api/v1/users/initial-data");
    if (result != null) {
      setPermissions(result.data.permissionLevels);

      return Promise.resolve(result.data.permissionLevels);
    } else {
      return Promise.reject();
    }
  }

  async function getDoors(): Promise<DoorData[]> {
    setLoading(true);
    const result = await Api.post("/api/v1/building-management/doors/search");
    if (result != null) {
      let groupedD: any = [];

      result?.data.forEach((element: any) => {
        const newBuilding = {
          name: element.building.buildingName,
          buildingUuid: element.building.buildingUuid,
          address1: element.building.address1,
          administrativeArea: element.building.administrativeArea,
          locality: element.building.locality,
          floors: element.building.floors,
          data: element.doors,
        };
        // @ts-ignore
        groupedD.push(newBuilding);
      });

      setBuildingsNames([
        "All Properties",
        ...groupedD.map((building: any) => building.name),
      ]);

      // set all status from the doors
      const allStatus = groupedD
        .map((building: any) => building.data)
        .flat()
        .map((door: any) => door.lockStatusName);

      // @ts-ignore
      setStatusNames(["All Status", ...new Set(allStatus)]);

      // set all types from the doors
      const allTypes = groupedD
        .map((building: any) => building.data)
        .flat()
        .map((door: any) => door.type);
      // @ts-ignore
      setTypesNames(["All Types", ...new Set(allTypes)]);

      setDoorsData(groupedD);
      setAllDoorData(groupedD);
      return Promise.resolve(result.data.rows);
    } else {
      return Promise.reject();
    }
  }

  useEffect(() => {
    getDoors();
    getUser();
    setLoading(false);
  }, [showForm]);

  const renderSubcomponent = useCallback(() => {
    switch (showForm) {
      case "guest_form":
        return (
          <>
            <AddNewDoor
              buildingData={allDoorData}
              onClose={() => {
                setShowForm(undefined);
              }}
            />
          </>
        );
      case "door_details":
        return (
          <>
            <DoorDetails
              door={selectedDoor}
              buildingData={allDoorData}
              permissions={permissionLevels}
              onClose={() => {
                setShowForm(undefined);
              }}
            />
          </>
        );

      default:
        return undefined;
    }
  }, [showForm]);

  return (
    <div
      style={{
        flex: 1,
        paddingLeft: 24,
        paddingRight: 24,
      }}
    >
      <RightSidebarLayout
        visible={!!showForm}
        onClose={() => setShowForm(undefined)}
      >
        {renderSubcomponent()}
      </RightSidebarLayout>

      <div style={{ marginTop: 20, marginBottom: 24, marginLeft: -24 }}>
        <TopNav navigationOption={userOption} />
      </div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <InputFieldIcon
          data-testid="search-input"
          style={{
            width: "286px",
          }}
          prefixIcon={() => <SearchIcon />}
          placeholder="Search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        {permissionLevels.includes("building-staff") ? null : (
          <Button
            data-testid="add-door-button"
            variant="basic"
            size="lg"
            style={{
              width: "54px",
              fontSize: "24px",
              color: theme.color.icon.hovered,
            }}
            onClick={() => {
              setShowForm("guest_form");
            }}
          >
            +
          </Button>
        )}
      </div>

      <div
        style={{
          display: "flex",
          maxWidth: 600,
          gap: 8,
          marginTop: 8,
          height: 56,
        }}
      >
        <SelectField<any, any>
          data-testid="building-select"
          icon={DownIcon}
          name="door"
          label="Doors"
          data={buildingsNames}
          selectedValue={selectedBuilding}
          onSelection={(selected) => {
            setSelectedBuilding(selected);
          }}
          valueExtractor={(item) => item}
          labelExtractor={(item) => item}
          keyExtractor={(item) => item.toString()}
        />
        <SelectField<any, any>
          data-testid="status-select"
          icon={DownIcon}
          name="door"
          label="Doors"
          data={statusNames}
          selectedValue={selectedStatus}
          onSelection={(selected) => {
            setSelectedStatus(selected);
          }}
          valueExtractor={(item) => item}
          labelExtractor={(item) => item}
          keyExtractor={(item) => item.toString()}
        />
        <SelectField<any, any>
          data-testid="type-select"
          icon={DownIcon}
          name="door"
          label="Doors"
          data={typesNames}
          selectedValue={selectedTypes}
          onSelection={(selected) => {
            setSelectedTypes(selected);
          }}
          valueExtractor={(item) => item}
          labelExtractor={(item) =>
            item.toString().replace(/^\w/, (c: any) => c.toUpperCase())
          }
          keyExtractor={(item) => item.toString()}
        />
      </div>

      <BuildingList
        //  @ts-ignore
        buildingList={filteredBuildings}
        onClick={(door: Door) => {
          setSelectedDoor(door);
          setShowForm("door_details");
        }}
      />
    </div>
  );
}
const route: AppRoute = {
  name: "Doors",
  screen: Doors,
  path: "/doors",
  role: [UserRole.Authenticated],
};
export { Doors, route };

import React from "react";

import { useCallback, useState } from "react";
import { useTheme } from "styled-components";
import { Button, H4 } from "../../../../common/components/design-system";
import { StepIndicator } from "../../../../common/components/step-indicator";
import { SelectBuilding } from "./select-building";
import { FillStaffInfo } from "./fill-staff-info";
import { StaffInfoConfirmation } from "./staff-info-confirmation";

interface AddStaffProps {
  buildingData: any;
  organizations?: any;
  onClose: () => void;
}
function AddNewStaff({
  buildingData,
  organizations = [],
  onClose,
}: AddStaffProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [addNewStaffData, setAddNewStaffData] = useState({
    currentModule: "AddNewStaff",
    buildings: [],
    name: "",
    email: "",
    phone: "",
    role: "",
    buildingAccess: [],
  });

  const [hasOrganizationPermissions, setHasOrganizationPermissions] =
    useState(false);

  React.useEffect(() => {
    setHasOrganizationPermissions(
      addNewStaffData?.role.includes("organization-manager")
    );
  }, [addNewStaffData]);

  // istanbul ignore next
  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <FillStaffInfo
            addNewStaffData={addNewStaffData}
            setAddNewStaffData={setAddNewStaffData}
            onNext={() => {
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              onClose();
            }}
          />
        );
      case 1:
        return (
          <SelectBuilding
            buildingData={buildingData}
            addNewStaffData={addNewStaffData}
            setAddNewStaffData={setAddNewStaffData}
            onNext={() => {
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
            organizations={organizations}
            hasOrganizationPermissions={hasOrganizationPermissions}
          />
        );
      case 2:
        return (
          <StaffInfoConfirmation
            addNewStaffData={addNewStaffData}
            setAddNewStaffData={setAddNewStaffData}
            onNext={() => {
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
            organizations={organizations}
            hasOrganizationPermissions={hasOrganizationPermissions}
          />
        );

      case 3:
        onClose();
        return <></>;
    }
  }, [activeStep, isLoading, addNewStaffData]);

  return (
    <div
      data-testid={"add-staff-container"}
      className="common-form-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>Add Staff</H4>
          </div>
          <div className="form-cancel">
            <Button
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <StepIndicator
          style={{ marginTop: "14px", marginBottom: "49px" }}
          totalSteps={3}
          activeStep={activeStep}
        />
        {renderSubComponent()}
      </div>
    </div>
  );
}

export { AddNewStaff };

/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import Modal from "react-modal";
import { AppRoute, UserRole } from "../../navigations/Types";
import { TopNav } from "../../../common/components/top-nav";
import { HeaderOption } from "../../../common/components/top-nav/type";
import { Button, ButtonText } from "../../../common/components/design-system";
import { Api } from "../../../common/redux/services/base/api";
import { Constants } from "../../../assets/constants";
import Axios from "axios";

import "./update-password.scss";
//   import { AccessList } from "./components/access-list/access-list";

interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
}

function UpdatePassword() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(true);

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "70%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const userOption: HeaderOption = {
    url: "/update-password",
    title: "Reset Password",
    showLogo: false,
    rightComponent: () => (
      <div className="top-nav-right-wrapper">
        <Button
          variant="plain"
          onClick={() => navigate("/Profile")}
        >
          Cancel
        </Button>
      </div>
    ),
  };

  // @ts-ignore
  async function getUser(): Promise {
    const result = await Api.get("/api/v1/users/profile");
    if (result != null) {
      const user: User = result.data;
      setUserData(result.data);
      setEmail(result?.data.email);
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }
  useEffect(() => {
    getUser();
  }, []);

  function closeModal() {
    setConfirmationModalVisible(false);
  }

  function confirmPasswordReset() {
    setConfirmationModalVisible(true);
  }

  async function handleForgetPassword() {
    try {
      const fusionAuthApi = Axios.create({
        baseURL: `https://apadaccess.fusionauth.io`,
        timeout: Constants.Config.time.MAX_CONNECTION_TIMEOUT,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      await fusionAuthApi.post("/api/user/forgot-password", {
        loginId: email,
      });

      setConfirmationModalVisible(false);
      navigate("/profile");
    } catch (error) {
      console.error("Error in handleForgetPassword:", error);
      setConfirmationModalVisible(false);
    }
  }

  return (
    <div>
      <TopNav navigationOption={userOption} />
      <div className="guest-profile-wrapper">
        <div style={{ margin: 20 }}>
          <h5 data-testid="reset-password-text">
            To set, update or reset your password, tap the button below. A
            verification link will be sent to your email with further
            instructions.
          </h5>
        </div>

        <div style={{ marginTop: 50 }}>
          <div style={{ height: 100, width: 100 }}>
            <Modal
              data-testid="confirmation-modal"
              isOpen={confirmationModalVisible}
              //onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={modalStyles}
              contentLabel="Confirmation Modal"
              shouldCloseOnOverlayClick={true}
            >
              <h4 style={{ textAlign: "center" }}>
                Check your email for a password reset link
              </h4>

              <div style={{ textAlign: "center" }}>
                A verification link has been sent to{" "}
                <span style={{ color: "#0F62FE" }}>{email}</span> with further
                instructions for reseting your password.
              </div>
              <Button
                data-testid="got-it-button"
                style={{
                  alignSelf: "center",
                  backgroundColor: "black",
                  color: "white",
                  marginTop: 20,
                }}
                // @ts-ignore
                onClick={handleForgetPassword}
              >
                Got it
              </Button>
            </Modal>
          </div>
          <Button
            //isLoading={isLoading}
            data-testid="reset-password-button"
            type="submit"
            size="lg"
            variant="primary"
            onClick={confirmPasswordReset}
          >
            <ButtonText>Reset Password</ButtonText>
          </Button>
        </div>
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "update-password",
  screen: UpdatePassword,
  path: "/update-password",
  role: [UserRole.Authenticated],
};
export { UpdatePassword, route };

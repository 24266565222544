import * as React from "react";
import { SelectBuilding } from "../add-new-staff/select-building";
import { EditBuildingsConfirmation } from "./edit-buildings-confirmation";

function EditBuildings({ user, staffData, onClose }: any) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedBuildings, setSelectedBuildings] = React.useState({
    buildingAccess: staffData,
    buildings: staffData,
  });

  const renderSubComponent = React.useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <SelectBuilding
            buildingData={[]}
            addNewStaffData={{ buildingAccess: staffData }}
            setAddNewStaffData={(item: any) => {
              setSelectedBuildings(item);
            }}
            onNext={() => {
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              onClose();
            }}
            organizations={[]}
            hasOrganizationPermissions={false}
            isFirstStep={true}
          />
        );
      case 1:
        return (
          <EditBuildingsConfirmation
            user={user}
            staffData={staffData}
            selectedBuildings={selectedBuildings.buildings}
            onClose={onClose}
            onGoBack={() => {
              setActiveStep(activeStep - 1);
            }}
          />
        );
    }
  }, [activeStep]);

  return (
    <div
      style={{ padding: 24, flex: 1, display: "flex", flexDirection: "column" }}
    >
      {renderSubComponent()}
    </div>
  );
}

export { EditBuildings };

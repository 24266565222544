import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  GuestAccessDate,
  GuestDoorAccess,
} from "../../../../common/redux/services/model";
import { Button, H4 } from "../../../../common/components/design-system";
import { DoorAccessWrapperComponent } from "../add-guest/components/door-access-wrapper/door-access-wrapper";
import "./edit-guest-access.scss";
import { useDeleteGuestDoorAccessMutation } from "../../../../common/redux/query";

import Modal from "react-modal";
import { updateGuestDoorAccess } from "common/redux/services/guests.service";
import { d } from "msw/lib/glossary-de6278a9";

interface EditGuestAccessProps {
  userId: string;
  doorAccess: GuestDoorAccess;
  onClose: () => void;
  totalAccess: number;
  offsetShortName?: string;
}

function EditGuestAccess({
  onClose,
  doorAccess,
  userId,
  totalAccess,
  offsetShortName,
}: EditGuestAccessProps) {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  const doorAccessDate = useMemo(
    () => ({
      // @ts-ignore
      startDate: moment(doorAccess?.startedAt).toDate() ?? undefined,
      // @ts-ignore
      startTime: moment(doorAccess?.startedAt).toDate() ?? undefined,
      // @ts-ignore
      endDate: doorAccess?.endedAt
        ? // @ts-ignore
          moment(doorAccess?.endedAt).toDate()
        : undefined,
      // @ts-ignore
      endTime: doorAccess?.endedAt
        ? // @ts-ignore
          moment(doorAccess?.endedAt).toDate()
        : undefined,
      // @ts-ignore
      offsetShortName: doorAccess?.offsetShortName,
    }),
    [doorAccess]
  );

  // TO DO: test this function
  /* istanbul ignore next */
  const updateDoorAccess = useCallback((doorAccessDate: GuestAccessDate) => {
    let startedAt = new Date(
      `${moment(doorAccessDate?.startDate).format("YYYY-MM-DD")}T${moment(
        doorAccessDate?.startTime
      ).format("HH:mm:ss")}`
    );

    let endedAt = doorAccessDate?.endDate
      ? new Date(
          `${moment(doorAccessDate?.endDate).format("YYYY-MM-DD")}T${moment(
            doorAccessDate?.endTime
          ).format("HH:mm:ss")}`
        )
      : undefined;

    const params = {
      startedAt: startedAt.toISOString(),
      endedAt: endedAt ? endedAt.toISOString() : undefined,
    };

    updateGuestDoorAccess(doorAccess.doorAccessUuid, { ...params }).then(() => {
      toast.success("Access updated successfully");
      onClose();
    });
  }, []);

  const [
    deleteGuestDoorAccess,
    {
      isLoading: deleteGuestDoorAccessLoading,
      isSuccess: deleteGuestDoorAccessSuccess,
      data: deleteGuestDoorAccessResponse,
      ...deleteGuestDoorAccessMutation
    },
  ] = useDeleteGuestDoorAccessMutation();

  useEffect(() => {
    if (deleteGuestDoorAccessSuccess) {
      toast.success("Access deleted successfully");
      // TO DO: Verify this logic
      // if (totalAccess < 2) {
      //   navigate("../guests");
      // }
      onClose();
      // close the add door modal
    }
  }, [deleteGuestDoorAccessSuccess, deleteGuestDoorAccessResponse]);

  useEffect(() => {
    if (deleteGuestDoorAccessMutation.isError) {
      toast.error((deleteGuestDoorAccessMutation.error as Error).message);
      deleteGuestDoorAccessMutation.reset();
    }
  }, [
    deleteGuestDoorAccessMutation.error,
    deleteGuestDoorAccessMutation.isError,
    deleteGuestDoorAccessMutation.reset,
  ]);
  // TO DO: test the navigation between steps separately
  /* istanbul ignore next */
  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <DoorAccessWrapperComponent
            isLoading={deleteGuestDoorAccessLoading}
            accessFormVariant="edit_access"
            title={doorAccess?.doorName}
            doorAccessDate={doorAccessDate!}
            goBack={(doorAccessDate) => {}}
            onDelete={() => {
              setActiveStep(1);
            }}
            onSubmit={(doorAccessDate) => {
              updateDoorAccess(doorAccessDate);
            }}
            offsetShortName={offsetShortName}
          />
        );
      case 1:
        return (
          // 2 lines of text and a yes and no button in the same line
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              <h4
                style={{
                  color: theme.color.text.subdued,
                  marginBottom: "20px",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to Remove Access?
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                style={{ height: "50px" }}
                variant="primary"
                onClick={() => {
                  setActiveStep(0);
                }}
              >
                No
              </Button>
              <div style={{ width: "10%" }}></div>
              <Button
                style={{ height: "50px" }}
                variant="primary"
                onClick={() => {
                  deleteGuestDoorAccess({
                    id: doorAccess?.doorAccessUuid,
                    userId,
                  });
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        );
    }
  }, [activeStep, deleteGuestDoorAccessLoading]);

  return (
    <div
      className="common-form-container"
      data-testid="edit-guest-access-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>Edit Access</H4>
          </div>
          <div className="form-cancel">
            <Button variant="plain" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
        {renderSubComponent()}
      </div>
    </div>
  );
}
export { EditGuestAccess };

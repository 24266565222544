import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoute, UserRole } from "../navigations/Types";
import { appRoute } from "../navigations/appRoutes";

import { useSession } from "common/service";

function NotFound() {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const session = useSession();
  //
  // useEffect(() => {
  //   const path = location.pathname;
  //   if (session.state === "authenticated") {
  //     const route = appRoute.find((route) => route.path === path);
  //     if (route?.role?.find((role) => role === UserRole.General)) {
  //       navigate("/");
  //     }
  //   } else if (path === "/") {
  //     const route = appRoute.find((route) => route.path === path);
  //     if (route?.role?.find((role) => role === UserRole.Authenticated)) {
  //       navigate("/login");
  //     }
  //   }
  // }, [location, session.state, navigate]);

  return (
    <div style={{
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    }}
    >
      <p>Not Found</p>
    </div>
  );
}

export const route : AppRoute = {
  name: "Not Found",
  screen: NotFound,
  path: "*",
  role: [UserRole.Default],
};

export { NotFound };

import { ConfirmationModal } from "common/components/ConfirmationModal/ConfirmationModal";
import { Api } from "common/redux/services/base/api";
import React from "react";

import * as S from "./styles";
import { ReactComponent as DoorIcon } from "assets/icons/door.svg";
import { BuildingIcon } from "../assets/building-icon";

export function PinModal({
  visible,
  onConfirm,
  onCancel,
  selectedPin,
}: {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  selectedPin: any;
}) {
  if (!selectedPin) {
    return null;
  }

  const { pin, id, doorAccessPinUuid } = selectedPin;
  const [loading, setLoading] = React.useState(false);

  // istanbul ignore next
  async function updatePinForAllDoorsSharingThisPin(): Promise<any> {
    try {
      setLoading(true);
      const result = await Api.post(
        `/api/v1/door-pins/${doorAccessPinUuid}/reset-pad-pin`
      );

      if (result != null) {
        onConfirm();
        return Promise.resolve(result.data as any);
      } else {
        onCancel();
        return Promise.reject();
      }
    } catch (error) {
      setLoading(false);
      onCancel();
      return Promise.reject(error);
    }
  }

  return (
    <div data-testid="pin-modal">
      <ConfirmationModal
        visible={visible}
        onConfirm={() => {
          updatePinForAllDoorsSharingThisPin();
        }}
        onCancel={() => {
          onCancel();
        }}
        title="Regenerate PIN"
        confirmText="Confirm"
        cancelText="Cancel"
        variant="confirm-and-cancel"
      >
        <S.PinText>
          {pin && pin.length > 0
            ? pin.substring(0, 3) + " " + pin.substring(3)
            : ""}
        </S.PinText>
        <S.DescriptionText>
          This PIN will be permanently changed for all doors using this PIN. The
          PIN shown above will no longer be usable.
        </S.DescriptionText>
      </ConfirmationModal>
    </div>
  );
}

import { useEffect, useMemo, useState } from "react";

import {
  Button,
  ButtonText,
} from "../../../../common/components/design-system";
import { InputFieldIcon } from "common/components/design-system/text-field/input-field-icon/input-field-icon";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";

import * as S from "./styles";
import { Radio } from "@mui/material";

function SelectBuilding({
  buildingData,
  addNewDoorData,
  setAddNewDoorData,
  onNext,
  goBack,
}: any) {
  const [searchText, setSearchText] = useState("");
  const [allowedToNext, setAllowedToNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredBuildings, setFilteredBuildings] = useState(buildingData);
  useEffect(() => {
    if (searchText === "") {
      setFilteredBuildings(buildingData);
    } else {
      const filtered = buildingData.filter((building: any) => {
        return building.name.toLowerCase().includes(searchText.toLowerCase());
      });
      setFilteredBuildings(filtered);
    }
  }, [searchText]);

  const groupedBuildings = useMemo(() => {
    // @ts-ignore
    const groupedData = filteredBuildings.reduce((acc, curr) => {
      const { administrativeArea } = curr;
      if (!acc[administrativeArea]) {
        acc[administrativeArea] = [];
      }
      acc[administrativeArea].push(curr);
      return acc;
    }, {});
    return groupedData;
  }, [filteredBuildings]);

  const [selected, setSelected] = useState({
    buildingUuid: "",
  });

  const nextButton = () => {
    if (allowedToNext) {
      onNext();
    } else {
      setErrorMessage("*Please select a building");
    }
  };

  return (
    <div data-testid="select-building-container">
      <InputFieldIcon
        data-testid="search-input"
        style={{
          width: "100%",
          marginBottom: 48,
        }}
        prefixIcon={() => <SearchIcon />}
        placeholder="Search"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <S.ContentContainer style={{ flexDirection: "column" }}>
        {/* {HEADER} */}
        {groupedBuildings &&
          Object.keys(groupedBuildings).map((administrativeArea) => {
            return (
              <S.BuildingsGroupContainer key={administrativeArea}>
                <S.GroupName>{administrativeArea}</S.GroupName>
                {groupedBuildings[administrativeArea].map(
                  // @ts-ignore
                  (building: any, index) => {
                    return (
                      <S.BuildingContainer key={building.buildingUuid}>
                        <S.IconContainer>
                          <BuildingIcon />
                        </S.IconContainer>
                        <S.BuildingName>{building.name}</S.BuildingName>
                        <Radio
                          data-testid={`building-radio-button-${building.buildingUuid}`}
                          checked={
                            // @ts-ignore
                            building.buildingUuid === selected?.buildingUuid
                          }
                          value={
                            building.buildingUuid === selected?.buildingUuid
                          }
                          onClick={() => {
                            setAddNewDoorData({
                              ...addNewDoorData,
                              building: building,
                              floor: building.floors[0],
                              doorName: "",
                              doorType: "Private",
                              lockId: "",
                            });
                            setAllowedToNext(true);
                            setErrorMessage("");
                            setSelected(building);
                          }}
                        />
                      </S.BuildingContainer>
                    );
                  }
                )}
              </S.BuildingsGroupContainer>
            );
          })}
      </S.ContentContainer>
      <div>
        <h2 style={{ fontSize: 12, color: "red" }}>{errorMessage}</h2>
      </div>
      <div className="bottom-buttons">
        <Button
          data-testid="cancel-button"
          size="lg"
          variant="basic"
          onClick={() => {
            goBack(goBack);
          }}
        >
          <ButtonText>Cancel</ButtonText>
        </Button>
        <div style={{ width: 20 }} />
        <Button
          data-testid="next-button"
          size="lg"
          variant="primary"
          onClick={nextButton}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      </div>
    </div>
  );
}

export { SelectBuilding };

import React from "react";
import styled from "styled-components";

import {
  ButtonWrapper,
  ButtonWrapperProps,
} from "./button-wrapper/button-wrapper";

// @ts-ignore
import { ReactComponent as LoadingIcon } from "assets/icons/loadingIcon.svg";

export interface ButtonProps
  extends Omit<
      React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      "ref"
    >,
    ButtonWrapperProps {
  prefixIcon?: React.ElementType;
  suffixIcon?: React.ElementType;
}

const ButtonContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  cursor: pointer;
`;

function ButtonDelicate({
  prefixIcon,
  suffixIcon,
  children,
  ...rest
}: ButtonProps) {
  const PrefixIcon = prefixIcon;
  const SuffixIcon = suffixIcon;
  return (
    <ButtonWrapper {...rest}>
      {!rest.isLoading ? (
        <ButtonContentWrapper>
          {PrefixIcon && <PrefixIcon />}

          {children}

          {SuffixIcon && <SuffixIcon />}
        </ButtonContentWrapper>
      ) : (
        <ButtonContentWrapper>
          <LoadingIcon className="rotate" />
        </ButtonContentWrapper>
      )}
    </ButtonWrapper>
  );
}

ButtonDelicate.defaultProps = {
  prefixIcon: undefined,
  suffixIcon: undefined,
};

const Button = React.memo(ButtonDelicate);
export { Button };

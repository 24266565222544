import React, { CSSProperties, useCallback, useState } from "react";
import styled from "styled-components";

import "./dropdown.scss";
import { genericMemo } from "../../../utils";

interface SelectWrapperProps
  extends Omit<
    React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >,
    "ref"
  > {
  background?: string;
}

export interface DropdownProps<I, R> extends SelectWrapperProps {
  name?: string;
  data: I[];
  selectedValue?: R | undefined;
  onSelection: ((item?: R) => void) | undefined;

  labelExtractor?: (item: I) => string;
  valueExtractor?: (item: I) => R;
  keyExtractor: (item: I) => string;
  style?: CSSProperties;
}

const SelectWrapper = styled.select.attrs((props: SelectWrapperProps) => props)`
  font-family: "Helvetica Now Display";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
  -webkit-appearance: none;
  height: 48px;
  color: ${(props) => props.theme.color.text.default};
  -moz-appearance: none;
  background: ${(props) =>
    // @ts-ignore
    props.background ?? props.theme.color.surface.default};
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 11px;
  padding-left: 9px;
  padding-right: 20px;
  border: none;

  &:focus-visible {
    outline: none;
  }

  option {
    font-family: "Helvetica Now Display";
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
  }
`;

function DropdownDelicate<I = string, R = string>({
  name,
  data,
  selectedValue,
  labelExtractor,
  keyExtractor,
  valueExtractor,
  onSelection,
  style,
  ...rest
}: DropdownProps<I, R>) {
  const [selected, setSelected] = useState<R | undefined>(selectedValue);
  const onSelectionChange = useCallback(
    (item: I) => {
      /* istanbul ignore next */
      const value = valueExtractor?.(item);
      /* istanbul ignore next */
      setSelected(value);
      /* istanbul ignore next */
      onSelection?.(value);
    },
    [onSelection]
  );

  return (
    <div
      className="select-wrapper"
      data-testid={"dropdown"}
    >
      <SelectWrapper
        data-testid={"dropdown-select"}
        // @ts-ignore
        background={rest.background}
        style={style}
        id={name}
        name={name}
        /* istanbul ignore next */
        value={
          /* istanbul ignore next */
          selected
            ? /* istanbul ignore next */
              data.findIndex((it) => selected === valueExtractor?.(it))
            : /* istanbul ignore next */
              undefined
          /* istanbul ignore next */
        }
        /* istanbul ignore next */
        onChange={(e) => onSelectionChange(data?.[+e.target.value])}
      >
        {name && (
          <option
            selected
            disabled
          >
            {name}
          </option>
        )}
        {data.map((item, index) => (
          <option
            key={keyExtractor(item)}
            value={index}
          >
            {labelExtractor?.(item)}
          </option>
        ))}
      </SelectWrapper>
    </div>
  );
}

SelectWrapper.defaultProps = {
  // @ts-ignore
  background: undefined,
};
DropdownDelicate.defaultProps = {
  name: undefined,
  selectedValue: undefined,
  style: undefined,
  labelExtractor: (item: any) => item?.toString(),
  valueExtractor: (item: any) => item?.toString(),
};

const Dropdown = genericMemo<typeof DropdownDelicate>(
  DropdownDelicate,
  (prev, next) =>
    /* istanbul ignore next */
    prev.data === next.data &&
    prev.selectedValue === next.selectedValue &&
    prev.onSelection === next.onSelection
);

export { Dropdown };

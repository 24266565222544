import styled from "styled-components";

import { CaptionText, LabelText } from "../typography/typography";

const Wrapper = styled.div`
  display: flex;
  gap: 9px;

  input {
    display: none;
  }

  .radiobutton-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    cursor: pointer;
    border-radius: 9px;
    background: #ffffff;
    border: ${(props) => `2px solid ${props.theme.color.border.default}`};

    .radiobutton-dot {
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
  }

  input:checked + .radiobutton-div {
    border: ${(props) => `2px solid ${props.theme.color.interactive.default}`};

    .radiobutton-dot {
      background: ${(props) => props.theme.color.interactive.default};
    }
  }

  input:checked + .radiobutton-div:hover {
    border: ${(props) => `2px solid ${props.theme.color.interactive.hovered}`};

    .radiobutton-dot {
      background: ${(props) => props.theme.color.interactive.hovered};
    }
  }

  input:disabled:checked + .radiobutton-div {
    border: ${(props) => `2px solid ${props.theme.color.border.disabled}`};

    .radiobutton-dot {
      background: ${(props) => props.theme.color.interactive.disabled};
    }
  }

  input:disabled:not(:checked) + .radiobutton-div {
    border: ${(props) => `2px solid ${props.theme.color.border.disabled}`};

    .radiobutton-dot {
      background: ${(props) => props.theme.color.surface.disabled};
    }
  }

  .radiobutton-labels {
    display: flex;
    flex-direction: column;
  }
`;

export interface RadioButtonProps {
  title: string;
  subtitle?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

function RadioButton({
  title,
  subtitle,
  checked,
  disabled,
  onChange,
}: RadioButtonProps) {
  return (
    <Wrapper onClick={disabled ? undefined : () => onChange?.(!checked)}>
      <input
        data-testid="radiobutton-input"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => {}}
      />
      <div className="radiobutton-div">
        <div className="radiobutton-dot" />
      </div>
      <div className="radiobutton-labels">
        <LabelText>{title}</LabelText>
        {subtitle && <CaptionText color="subdued">{subtitle}</CaptionText>}
      </div>
    </Wrapper>
  );
}

RadioButton.defaultProps = {
  checked: false,
  disabled: false,
  subtitle: undefined,
  onChange: undefined,
};

export { RadioButton };

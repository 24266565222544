import { useMemo } from "react";

import { useSession } from "../../common/service";

import { UserRole } from "./Types";
import { appRoute } from "./appRoutes";

import { route as notFoundRoute } from "screens/public/NotFound";

const allRoutes = [...appRoute, notFoundRoute];

function useAppRoutes() {
  const session = useSession();
  const currentRole = useMemo(
    () =>
      session.state === "authenticated"
        ? UserRole.Authenticated
        : UserRole.General,
    [session.state]
  );

  return useMemo(
    () =>
      allRoutes.filter((route) => {
        const a = route.role.find(
          (role) => role === UserRole.Default || role === currentRole
        );
        return a !== undefined;
      }),
    [currentRole]
  );
}

export { useAppRoutes };

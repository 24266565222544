import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ContentContainer = styled.div`
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  width: 80%;
  max-width: 512px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 16px 16px 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  padding: 16px 0px;
`;

export const DescriptionText = styled.p`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  padding: 0px 24px 24px 24px;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

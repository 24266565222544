import styled, { DefaultTheme } from "styled-components";

type ThemeColor = DefaultTheme["color"];
type TextColor = keyof ThemeColor["text"];

export type TextProps = {
  color: TextColor | string;
};

// @ts-ignore
const H1 = styled.h1.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  margin: 0px;
  line-height: 59px;
  color: ${(props) =>
    props.color
      ? props.theme.color.text[props.color as TextColor] ?? props.color
      : props.theme.color.text.default};
`;

// @ts-ignore
const H2 = styled.h2.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 50px;
  color: ${(props) => props.color || props.theme.color.text.default};
`;

// @ts-ignore
const H3 = styled.h2.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  color: ${(props) =>
    props.color
      ? props.theme.color.text[props.color as TextColor] ?? props.color
      : props.theme.color.text.default};
  margin: 0px;
`;

// @ts-ignore
const H4 = styled.h3.attrs((props: { color: string }) => props)`
  font-family: ${(props) => props.theme.fontFamily.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: ${(props) =>
    props.color
      ? props.theme.color.text[props.color as TextColor] ?? props.color
      : props.theme.color.text.default};
`;

// @ts-ignore
const SubHeading = styled.div.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) =>
    props.color
      ? props.theme.color.text[props.color as TextColor] ?? props.color
      : props.theme.color.text.default};
`;

// @ts-ignore
const ButtonText = styled.div.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
`;

// @ts-ignore
const BodyText = styled.div.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.normal};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) =>
    props.color
      ? props.theme.color.text[props.color as TextColor] ?? props.color
      : props.theme.color.text.default};
`;

// @ts-ignore
const LabelText = styled.p.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) =>
    props.color
      ? props.theme.color.text[props.color as TextColor] ?? props.color
      : props.theme.color.text.default};
  padding: 0px;
  margin: 0;
`;

// @ts-ignore
const CaptionText = styled.p.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.normal};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  color: ${(props) =>
    props.color
      ? props.theme.color.text[props.color as TextColor] ?? props.color
      : props.theme.color.text.default};
  padding: 0px;
`;

// @ts-ignore
const CaptionTextSmall = styled.p.attrs((props: TextProps) => props)`
  font-family: ${(props) => props.theme.fontFamily.normal};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  color: ${(props) =>
    props.color
      ? props.theme.color.text[props.color as TextColor] ?? props.color
      : props.theme.color.text.default};
  padding: 0px;
`;

export {
  H1,
  H2,
  H3,
  H4,
  BodyText,
  ButtonText,
  CaptionText,
  LabelText,
  SubHeading,
  CaptionTextSmall,
};

// istanbul ignore file
import { AxiosRequestConfig } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";

import { Api, HttpError } from "./api";

type ApiConfig = Pick<
  AxiosRequestConfig,
  "data" | "method" | "params" | "headers" | "url"
>;

const axiosBaseQuery =
  <Result = unknown>(): BaseQueryFn<ApiConfig, Result, unknown> =>
  async (args) => {
    const { url, method = "GET", params, data, headers } = args;
    try {
      const result = await Api.request<Result>({
        url,
        method,
        params,
        data,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as HttpError;
      return {
        error: {
          status: err.code,
          message: err.message,
          data: err.response?.data,
          fieldErrors: err.fieldErrors,
        },
      };
    }
  };

export default axiosBaseQuery();

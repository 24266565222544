/* istanbul ignore file */
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import { store } from "./common/redux";
import { useAppRoutes } from "./screens/navigations";
import { AppRoute } from "./screens/navigations/Types";
import { RouteWrapper } from "./screens/navigations/route";
import { SidebarWrapper } from "./common/components/sidebar";
import { defaultTheme } from "./common/components/design-system";

export function createRoute(route: AppRoute): React.ReactNode {
  const Page = route.screen;
  return (
    <Route
      key={route.path}
      path={route.path}
      element={
        <RouteWrapper
          element={Page}
          path={route.path}
        />
      }
    >
      {route.childRoutes?.map((childRoute) => createRoute(childRoute))}
    </Route>
  );
}

function App() {
  const routes = useAppRoutes();
  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <SidebarWrapper>
            <Routes>{routes.map((route) => createRoute(route))}</Routes>
          </SidebarWrapper>
        </BrowserRouter>
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
}

export default App;

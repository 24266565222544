import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useSession } from "../../common/service";

import { appRoute } from "./appRoutes";
import { UserRole } from "./Types";

function RouteWrapper({
  element: Element,
  path,
}: {
  element: React.ElementType;
  path: string;
}) {
  const session = useSession();
  const location = useLocation();

  if (session.state === "unknown") {
    return <div>Loading</div>;
  }

  const registeredRoute = appRoute.find(
    (route) => route.path === location.pathname
  );
  if (
    session.state === "unauthenticated" &&
    path !== "/" &&
    registeredRoute &&
    !registeredRoute.role.find((it) => it === UserRole.Default)
  ) {
    return (
      <Navigate
        to="/"
        replace
      />
    );
  }

  return <Element />;
}

export { RouteWrapper };

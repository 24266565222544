import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

import { Guest } from "../../../../../../common/redux/services/model";
import {
  BodyText,
  H4,
} from "../../../../../../common/components/design-system";
import { DoorImgWrapper } from "../../../../my-access/components/door/door";

import { ReactComponent as MultiUserIcon } from "assets/icons/multi-user.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/angle-right.svg";
import { ReactComponent as PinIcon } from "assets/svg/pinIcon.svg";

import "./guest-items.scss";

export interface GuestItemsParams {
  guestItem: Guest;
}
function GuestItems({ guestItem }: GuestItemsParams) {
  const theme = useTheme();
  const navigation = useNavigate();
  function formatDoorList() {
    if (!guestItem.doorList) return;

    let sortedDoors = [...guestItem.doorList].sort((a, b) => {
      return (
        (b.doorType === "private" ? 1 : 0) - (a.doorType === "private" ? 1 : 0)
      );
    });

    if (sortedDoors.length > 2) {
      return (
        <>
          {sortedDoors
            .slice(0, 2)
            .map((door) => door.doorName)
            .join(", ")}{" "}
          • <strong>{sortedDoors.length - 2} more</strong>
        </>
      );
    } else {
      return sortedDoors.map((door) => door.doorName).join(", ");
    }
  }

  const doorAccessList = formatDoorList();

  return (
    <div
      className="guest-item-wrapper"
      style={{alignItems: "center"}}
      onClick={() => {
        // @ts-ignore
        if (!guestItem?.doorAccessPinUuid) {
          navigation(`${guestItem?.userUuid}/${guestItem?.name}/false`);
        } else {
          navigation(
            // @ts-ignore
            `/guests/${guestItem?.doorAccessPinUuid}/${guestItem?.allowedNickname}/true`
          );
        }
      }}
      data-testid="guest-item"
    >
      <div className="guest-item-title-img-wrapper">
        <DoorImgWrapper
          style={{
            border: "none",
            background: theme.color.background.hovered,
          }}
        >
          {/* @ts-ignore */}
          {guestItem?.allowedUserUuid ? (
            <MultiUserIcon stroke={theme.color.icon.default} />
          ) : (
            <PinIcon />
          )}
        </DoorImgWrapper>
        <div className="guest-item-detail"
        style={{justifyContent: "center"}}
        >
          <H4
            style={{
              margin: 0,
              
            }}
          >
            {/* @ts-ignore */}
            {guestItem?.name || guestItem?.allowedNickname}
          </H4>
          <BodyText
            color={theme.color.text.subdued}
            style={{
              fontStyle: guestItem.doorList.length === 0 ? "italic" : "normal",
              fontFamily: "arial",
            }}
          >
            {doorAccessList || "No active access"}
          </BodyText>
        </div>
      </div>
      <div className="door-pin">
        <RightArrowIcon fill={theme.color.text.default} />
      </div>
    </div>
  );
}
export { GuestItems };

import * as S from "./styles";

import { ReactComponent as UserOutlineIcon } from "assets/icons/user-outline.svg";
import { ReactComponent as RightIcon } from "assets/icons/angle-right.svg";
import { useNavigate } from "react-router-dom";

interface User {
  userUuid: string;
  name: string;
  doorAccess: [];
  status: string;
  isResident?: boolean;
}

function UserItem({ user }: { user: User }) {
  const navigate = useNavigate();

  return (
    <>
      <S.Container
        data-testid="user-item"
        onClick={() => {
          navigate("/people/" + user.userUuid, {
            state: { isResident: true },
          });
        }}
      >
        <S.IconContainer>
          <UserOutlineIcon />
          {/* <AntDesign
            name="user"
            size={24}
            color="white"
          /> */}
        </S.IconContainer>

        <S.TextsContainer>
          <S.UserNameText>{user.name}</S.UserNameText>
          <div style={{ height: 8 }} />
          <S.DoorAccessText>
            {user.doorAccess.map((door: any) => door.doorName).join(" • ")}
          </S.DoorAccessText>
        </S.TextsContainer>

        <div style={{ justifyContent: "center" }}>
          <RightIcon />
        </div>
      </S.Container>
    </>
  );
}

export default UserItem;

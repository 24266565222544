/* eslint-disable max-len, no-extend-native, func-names */
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

import { Button, Checkbox, NavLink } from "../design-system";
import { Constants } from "../../../assets/constants";
import { LocalStorage } from "../../service";
import Copyright from "../../../assets/png/copyright.png";
//import { MenuItem } from "./Type";

import appIcon from "assets/icons/appIcon.svg";
import { useWindowDimension } from "common/utils";

import "./sidebar.scss";
import { Api } from "common/redux/services/base/api";

interface MenuItem {
  name: string;
  path: string;
  permissionLevel: string[];
  onPress?: () => void;
}

const navMenuList: MenuItem[] = [
  {
    name: "My Account",
    path: "/profile",
    permissionLevel: [
      "building-manager",
      "organization-manager",
      "building-staff",
      "organization-staff",
      "resident",
      "guest",
      "all",
      "",
    ],
  },
  {
    name: "My Access",
    path: "/",
    permissionLevel: [
      "building-manager",
      "organization-manager",
      "building-staff",
      "organization-staff",
      "resident",
      "guest",
    ],
  },
  {
    name: "Guests",
    path: "/guests",
    permissionLevel: ["resident"],
  },
  {
    name: "Doors",
    path: "/doors",
    permissionLevel: [
      "building-manager",
      "organization-manager",
      "building-staff",
    ],
  },
  {
    name: "Properties",
    path: "/properties",
    permissionLevel: [
      "building-manager",
      "organization-manager",
      "building-staff",
    ],
  },
  {
    name: "Activity",
    path: "/Activities",
    permissionLevel: [
      "building-manager", 
      "organization-manager", 
      "resident"
    ],
  },
  {
    name: "People",
    path: "/people",
    permissionLevel: [
      "building-manager", 
      "organization-manager"
    ],
  },
  {
    name: "Building Staff",
    path: "/building-staff",
    permissionLevel: [
      "building-manager", 
      "organization-manager"
    ],
  },
  {
    name: "Support",
    path: "/support",
    permissionLevel: [
      "building-manager",
      "organization-manager",
      "building-staff",
      "organization-staff",
      "resident",
      "guest",
    ],
  },
];

const logout = {
  name: "Logout",
  onPress: () =>
    LocalStorage.setItem(Constants.storageKey.userSession, undefined),
  permissionLevel: "resident",
};

function Sidebar() {
  const navigation = useNavigate();
  const windowDimension = useWindowDimension();
  const [showTos, setShowTos] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [permissions, setPermissions] = useState<any>([]);
  const [capitalizedPermission, setCapitalizedPermission] =
    useState<string>("");
  const [showPermission, setShowPermission] = useState("");
  const theme = useTheme();

  function formatPermission(permission: string): string {
    var words = permission.split("-");
    var capitalizedWords = words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  }

  async function getUser(): Promise<any> {
    try {
      const result = await Api.get("/api/v1/users/initial-data");
      if (result != null && result.data.permissionLevels.length > 0) {
        setPermissions(result.data.permissionLevels);
        setCapitalizedPermission(
          formatPermission(result.data.permissionLevels[0])
        );
        return Promise.resolve(result.data.permissionLevels);
      } else if (result.data.termOfServiceAcceptedAt === undefined) {
        setShowTos(true);
      } else {
        console.warn("No valid permission levels found.");
        return Promise.resolve([]); // Return an empty array as a default
      }
      // istanbul ignore next
    } catch (error) {
      // istanbul ignore next
      console.error("Error in getUser:", error);
      // istanbul ignore next
      return Promise.reject(error);
    }
  }

  async function AcceptTos(): Promise<boolean> {
    const data = { date: new Date() };
    const result = await Api.post(
      "/api/v1/users/term-of-service-accepted",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return Promise.resolve(result != null && result.status === 200);
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (capitalizedPermission === "Organization Manager") {
      setShowPermission("Org. Manager");
    } else if (capitalizedPermission === "Building Manager") {
      setShowPermission("Manager");
    } else if (capitalizedPermission === "Building Staff") {
      setShowPermission("Staff");
    } else {
      setShowPermission(capitalizedPermission);
    }
  }, [capitalizedPermission]);

  const height = windowDimension.height;
  const fixedSectionHeight = 100;

  return (
    <>
      {!showTos && (
        <div className="sidebar-wrapper" data-testid="sidebar-container">
          <div className="product-image">
            <img src={appIcon} alt="Logo" />
          </div>
          <div className="divider divider-margin" />

          <div
            className="nav-sidebar-wrapper"
            data-testid="nav-sidebar-wrapper"
            style={{ maxHeight: `calc(${height}px - 160px)`, overflowY: 'auto'}}
          >
            {navMenuList.map((navMenu: MenuItem) => {
              const itemLevel = navMenu.permissionLevel;
              const userLevel = permissions;

              for (let i = 0; i < itemLevel.length; i++) {
                const item = itemLevel[i];
                if (item === "" && userLevel?.length === 0) {
                  return (
                    <NavLink
                      key={`${navMenu.path}_${navMenu.name}`}
                      to={navMenu.path}
                      onPress={() =>
                        navMenu.name === "Logout"
                          ? () => {
                              navMenu.onPress?.();
                              navigation("../");
                            }
                          : undefined
                      }
                    >
                      {navMenu.name}
                    </NavLink>
                  );
                }
                else if (userLevel?.includes(item)) {
                  return (
                    <NavLink
                    
                      key={`${navMenu.path}_${navMenu.name}`}
                      to={navMenu.path}
                      onPress={() =>
                        navMenu.name === "Logout"
                          ? () => {
                              navMenu.onPress?.();
                              navigation("../");
                            }
                          : undefined
                      }
                    >
                      {navMenu.name}
                    </NavLink>
                  );
                }
              }
            })}

            <NavLink
              key={`${logout.name}`}
              onPress={() => {
                logout.onPress?.();
                navigation("../");
              }}
            >
              {logout.name}
            </NavLink>
            
            <div
              className="nav-sidebar-fixed" style={{
                
                position: "absolute",
                bottom: 0,
                width: "90%",
                backgroundColor: 'black',
                left: 0
              }}
            >
              <h6 style={{ color: "white", alignSelf: "left", marginLeft: 20}}>
                {showPermission}
              </h6>
              {/* <img
                  src={Copyright}
                  alt="Logo"
                /> */}
              <span style={{ color: "white", fontSize: 14, fontWeight: "400", marginBottom: 10, marginLeft: 20}}>
                © APAD Access, Inc.
              </span>
            </div>
          </div>
        </div>
      )}

      {showTos && (
        <div
          style={{
            width: "100vw",
            // maxWidth: "1600px",
            height: "100vh",
            position: "absolute",
            // top: "5vh",
            padding: "24px 48px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // alignSelf: "center",
            backgroundColor: "#FAFBFB",
            zIndex: 1000122112,
          }}
          data-testid="tos-container"
        >
          <h2 style={{}}>Terms of Service</h2>
          <iframe
            src="https://www.apadaccess.com/terms-of-service"
            width="100%"
            height="100%"
            title="Terms of Service Website"
            style={{ marginBottom: 8 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Checkbox
              title="I agree to the terms of service"
              style={{
                cursor: "pointer",
              }}
              onChange={() => setTosAccepted(!tosAccepted)}
              checked={tosAccepted}
              data-testid="tos-checkbox"
            />
            <Button
              style={{
                cursor: "pointer",
                width: "160px",
              }}
              variant="primary"
              disabled={!tosAccepted}
              onClick={() => {
                AcceptTos();
                setShowTos(!showTos);
              }}
              data-testid="tos-accept-button"
            >
              Accept
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export { Sidebar };

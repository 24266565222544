import styled from "styled-components";

export const ItemContainer = styled.button<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? "#303030" : "#F1F1F1")};
  padding: 12px 24px;
  border-radius: 32px;
  margin-right: 8px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Label = styled.span<{ isSelected: boolean }>`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => (props.isSelected ? "white" : "#202223")};
`;

const defaultTheme = {
  fontFamily: {
    normal: "Helvetica Now Display",
    medium: "Helvetica Now Display Medium",
    bold: "Helvetica Now Display Bold",
  },
  size: {
    button: {
      sm: "30px",
      md: "36px",
      lg: "44px",
    },
    badges: {
      sm: "32px",
      md: "38px",
      lg: "46px",
    },
    navLink: {
      sm: "32px",
      md: "32px",
      lg: "32px",
    },
  },
  color: {
    brand: "#00D97A",
    base: {
      surface: "#F4F4F4",
      onSurface: "#111213",
      primary: "#323232",
      secondary: "#CCCCCC",
      interactive: "#2E72D2",
      critical: "#D82C0D",
      warning: "#F1C21B",
      success: "#008060",
      highlight: "#3DDBD9",
      decorative: "#92E6B5",
    },
    background: {
      default: "#C9CCCF",
      hovered: "#F1F2F3",
      pressed: "#EDEEEF",
      selected: "#EDEEEF",
      darkDefault: "#1B1B1B",
      darkHovered: "#363636",
      darkPressed: "#666666",
      darkSelected: "#9E9E9E",
    },
    text: {
      default: "#202223",
      subdued: "#6D7175",
      disabled: "#8C9196",
      critical: "#FF2100",
      warning: "#916A00",
      success: "#149D52",
      highlight: "#347C84",
      onInteractive: "#FFFFFF",
      onPrimary: "#FFFFFF",
      onCritical: "#FFFFFF",
      textExpired: "#CCCCCC",
      titleExpired: "#707070",
    },
    icon: {
      critical: "#FF2100",
      warning: "#F1C21B",
      default: "#5C5F62",
      subdued: "#8C9196",
      highlight: "#00A0AC",
      success: "#149D52",
      onPrimary: "#FFFFFF",
      hovered: "#1A1C1D",
    },
    surface: {
      default: "#F4F4F4",
      criticalSubdued: "#FFF4F4",
      disabled: "#FAFBFB",
      subdued: "#FAFBFB",
      hovered: "#F6F6F7",
      selectedDefault: "#EDF5FF",
    },
    border: {
      default: "#8C9196",
      criticalDefault: "#FD5749",
      subdued: "#C9CCCF",
      disabled: "#D2D5D8",
    },
    interactive: {
      default: "#0F62FE",
      depressed: "#001D6C",
      disabled: "#BDC1CC",
      critical: "#FF2100",
      hovered: "#0043CE",
      criticalDepressed: "#670F03",
    },
    action: {
      primaryDefault: "#323232",
      primaryHover: "#5B5B5B",
      primaryPressed: "#232323",
      primaryDepressed: "#000000",
      primaryDisabled: "#F1F1F1",
      criticalDefault: "#FF2100",
      criticalHover: "#BC2200",
      criticalPressed: "#A21B00",
      criticalDepressed: "#6C0F00",
      criticalDisabled: "#F1F1F1",
      secondaryDefault: "#FFFFFF",
      secondaryHover: "#F6F6F7",
      secondaryPressed: "#F1F2F3",
      secondaryDepressed: "#6D7175",
      secondaryDisabled: "#FFFFFF",
    },
    focused: {
      default: "#0072C3",
    },
  },

};

export { defaultTheme };

import { useEffect, useState } from "react";
import * as React from "react";

import {
  Button,
  ButtonText,
} from "../../../../common/components/design-system";
import { Api } from "common/redux/services/base/api";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";

import * as S from "./styles";
import { AddGuestProfileCard } from "screens/authenticated/guests/add-guest/components/add-guest-profile-card/add-guest-profile-card";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert } from "react-bootstrap";
import { inviteStaff } from "common/redux/services/staff.service";

function StaffInfoConfirmation({
  addNewStaffData,
  setAddNewStaffData,
  onNext,
  goBack,
  organizations = [],
  hasOrganizationPermissions = false,
}: any) {
  const [loading, setLoading] = React.useState(false);
  const [showRole, setShowRole] = React.useState("");

  const [selectedBuilding, setSelectedBuilding] = useState({
    buildingUuid: "",
    buildingName: "",
  });

  function onDelete(buildingUuid: string) {
    const currentBuildings = addNewStaffData.buildings;

    const newBuildings = currentBuildings.filter(
      (building: any) => building.buildingUuid !== buildingUuid
    );

    setAddNewStaffData({
      ...addNewStaffData,
      buildings: newBuildings,
    });
  }

  function closeModal() {
    setSelectedBuilding({ buildingUuid: "", buildingName: "" });
  }

  useEffect(() => {
    if (addNewStaffData.role === "building-staff") {
      setShowRole("Staff");
    } else if (addNewStaffData.role === "building-manager") {
      setShowRole("Manager");
    } else if (addNewStaffData.role === "organization-manager") {
      setShowRole("Org. Manager");
    }
  }, [addNewStaffData.role]);

  return (
    <>
      {selectedBuilding.buildingUuid && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 100,
          }}
        >
          <div
            style={{
              width: "80%",
              padding: 24,
              backgroundColor: "white",
              border: "1px solid #E5E5E5",
              marginTop: "5vh",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
            }}
          >
            <h5 style={{ textAlign: "center" }}>Door removal</h5>

            <div style={{ textAlign: "center" }}>
              Are you sure you want to remove {selectedBuilding.buildingName}?
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "space-between",
                justifyContent: "space-between",
                alignContent: "space-between",
                marginTop: 16,
              }}
            >
              <Button
                style={{ alignSelf: "center", marginRight: 8 }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                data-testid="confirm-removal-button"
                variant="primary"
                style={{ alignSelf: "center", marginLeft: 8 }}
                onClick={() => {
                  const currentBuildings = addNewStaffData.buildings;

                  if (currentBuildings.length < 2) {
                    window.alert(
                      "You must have at least one building selected"
                    );
                    closeModal();
                  } else {
                    onDelete(selectedBuilding?.buildingUuid);
                    closeModal();
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}

      <div data-testid="data-info-confirmation">
        <S.ContentContainer style={{ flexDirection: "column" }}>
          <h3 style={{ fontSize: 26, fontWeight: "400" }}>Confirm Staff</h3>
          {/* Guest info */}
          <div style={{ marginTop: 24 }}>
            <AddGuestProfileCard
              guest={{
                name: addNewStaffData.name,
                invite_email: addNewStaffData.email,
                role: showRole,
                addStaff: true,
              }}
            />

            {addNewStaffData.buildings.map((building: any) => {
              return (
                <>
                  <S.BuildingContainer key={building.buildingUuid}>
                    <S.IconContainer>
                      <BuildingIcon />
                    </S.IconContainer>
                    <S.BuildingName>{building.buildingName}</S.BuildingName>
                    {!hasOrganizationPermissions && (
                      <S.ButtonContainer data-testid="delete-wrapper">
                        <Button
                          // isLoading={isLoading}
                          type="submit"
                          size="sm"
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                          onClick={() => {
                            setSelectedBuilding(building);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </S.ButtonContainer>
                    )}
                  </S.BuildingContainer>
                </>
              );
            })}
          </div>
          <div className="bottom-buttons">
            <Button size="lg" variant="basic" onClick={goBack}>
              <ButtonText>Previous</ButtonText>
            </Button>
            <div style={{ width: 20 }} />
            <Button
              data-testid="confirm-button"
              size="lg"
              variant="primary"
              onClick={async () => {
                setLoading(true);
                await inviteStaff(
                  addNewStaffData,
                  hasOrganizationPermissions,
                  organizations
                ).then(() => {
                  setLoading(false);
                  onNext();
                });
              }}
            >
              <ButtonText>Confirm</ButtonText>
            </Button>
          </div>
        </S.ContentContainer>
      </div>
    </>
  );
}

export { StaffInfoConfirmation };

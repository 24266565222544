import * as S from "./styles";

import { ReactComponent as UserOutlineIcon } from "assets/icons/user-outline.svg";
import { ReactComponent as RightIcon } from "assets/icons/angle-right.svg";
import { useNavigate } from "react-router-dom";

interface User {
  userUuid: string;
  name: string;
  doorAccess: [];
  buildingAccess: [];
  organizationAccess: [];
  status: string;
}

function UserItem({ user, variant }: { user: User; variant?: String }) {
  const navigate = useNavigate();
  const accesses =
    variant == "organization" ? user?.organizationAccess : user?.buildingAccess;
  return (
    <S.Container
      data-testid="user-item"
      onClick={() => {
        navigate("/staff-profile/" + variant + "/" + user.userUuid);
        // navigate("/residents/" + user.userUuid);
      }}
    >
      <S.IconContainer>
        <UserOutlineIcon />
      </S.IconContainer>

      <S.TextsContainer>
        <S.UserNameText>{user.name}</S.UserNameText>
        <div style={{ height: 8 }} />
        <S.DoorAccessText>
          {accesses
            .map((item: any) => {
              const name =
                variant == "organization" ? item.organizationName : item.buildingName;
              return name;
            })
            .join(" • ")}
        </S.DoorAccessText>
      </S.TextsContainer>

      <div style={{ justifyContent: "center" }}>
        <RightIcon />
      </div>
    </S.Container>
  );
}

export default UserItem;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px 24px;
`;
export const PageContainer = styled.div`
  justify-content: space-between;
  padding: 24px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
`;

export const BuildingsGroupContainer = styled.div`
  margin-bottom: 32px;
`;

export const BuildingContainer = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  display: flex;
`;

export const BuildingName = styled.h3`
  font-size: 20px;
  font-weight: 400;
  flex: 1;
  margin-left: 16px;
`;

export const GroupName = styled(BuildingName)`
  font-size: 20px;
  font-weight: 400;
  color: #202223;
  margin-left: 0px;
`;

export const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  display: flex;
`;

export const InputLabel = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: #6d7175;
  font-family: Helvetica Now Display Medium;
  font-style: normal;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  flex: 1;
`;

export const DeleteStaffWrapper = styled.div`
  flex: 0.2;
  align-self: center;
  justify-self: flex-end;
  position: fixed;
  bottom: 20px;
`;

// Edit Buildings Confirmation

// export const Container = styled.div`
//   flex-grow: 1;
//   padding: 24px 0px;
//   flex: 1;
// `;

export const CardContainer = styled.div`
  padding: 0px 16px;
  margin-bottom: 24px;
`;

// export const BuildingContainer = styled.div`
//   /* padding: 0px 24px 0px 24px; */
//   margin-bottom: 16px;
//   flex-direction: row;
//   align-items: center;
// `;

// export const IconContainer = styled.div`
//   width: 64px;
//   height: 64px;
//   border-radius: 32px;
//   justify-content: center;
//   align-items: center;
//   background-color: #121212;
// `;

// export const BuildingName = styled.span`
//   font-size: 20px;
//   font-weight: 400;
// `;

export const BuildingAddress = styled.span`
  font-size: 18px;
  color: #6d7175;
`;

export const Title = styled.span`
  font-size: 24px;
  color: #202223;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const EmptyWarning = styled.span`
  font-size: 16px;
  color: #707070;
  font-weight: 400;
  text-align: center;
  margin: 0px 0px 16px 0px;
`;

import * as S from "./styles";

interface Option {
  label: string;
  value: string;
}

export function SelectionOptions({
  options,
  selectedOption,
  setSelectedOption,
}: {
  options: Option[];
  selectedOption: Option;
  setSelectedOption: (option: Option) => void;
}) {
  return (
    <div>
      {options.map((option) => {
        const isSelected = option.value === selectedOption.value;
        return (
          <S.ItemContainer
            key={option.value}
            isSelected={isSelected}
            onClick={() => setSelectedOption(option)}
          >
            <S.Label isSelected={isSelected}>{option.label}</S.Label>
          </S.ItemContainer>
        );
      })}
      <div style={{ width: 24 }} />
    </div>
  );
}

import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "styled-components";

import { GuestInfo } from "../../../../../../common/redux/services/model";
import {
  Button,
  ButtonText,
  H3,
  TextField,
} from "../../../../../../common/components/design-system";

import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";

import * as S from "./styles";

const guestSchema = Yup.object().shape(
  {
    name: Yup.string().trim().required("Full Name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Email is invalid"),
  },
  []
);

export interface GuestInfoFormProps {
  guestInfo?: GuestInfo;
  onNext: ((params: GuestInfo) => void) | undefined;
  onCancel: (() => void) | undefined;
}

function GuestInfoFormComponent({
  guestInfo,
  onNext,
  onCancel,
}: GuestInfoFormProps) {
  const theme = useTheme();
  const {
    values,
    dirty,
    errors,
    isValid,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      name: guestInfo?.name || "",
      email: guestInfo?.invite_email || "",
    },
    validationSchema: guestSchema,
    onSubmit: async (params, helpers) => {
      onNext?.({
        name: params.name?.trim(),
        invite_email: params.email.trim(),
      });
    },
  });

  return (
    <form
      id="loginform"
      onSubmit={handleSubmit}
      data-testid="guest-info-form"
    >
      <H3 style={{ marginBottom: "19px" }}>Detail</H3>
      <div className="form-input-wrapper">
        <TextField
          data-testid="full-name-input"
          label="Full Name"
          name="fullName"
          id="fullName"
          value={values.name}
          onChange={handleChange("name")}
          onBlur={handleBlur("name")}
          error={touched?.name && !!errors?.name}
          errorMessage={errors?.name}
          suffixIcon={() => (
            <PersonIcon
              height={18}
              width={18}
            />
          )}
          suffixStyle={{
            fill: "none",
            stroke: "black",
          }}
        />

        <TextField
          data-testid="email-input"
          label="Email"
          name="email"
          id="email"
          value={values.email}
          onChange={handleChange("email")}
          onBlur={handleBlur("email")}
          error={touched?.email && !!errors?.email}
          errorMessage={errors?.email}
          suffixIcon={() => (
            <MailIcon
              height={18}
              width={18}
              stroke={theme.color.text.default}
            />
          )}
        />

        <Button
          disabled={values.email === "" && values.name === ""}
          type="submit"
          size="lg"
          variant="primary"
          data-testid="next-button"
          style={{ marginTop: 24 }}
        >
          <ButtonText>Next</ButtonText>
        </Button>

        <S.OrContainer>
          <S.OrLine />
          <S.OrText>or</S.OrText>
          <S.OrLine />
        </S.OrContainer>

        <div style={{ flexDirection: "row", marginBottom: 16 }}>
          <Button
            disabled={values.email !== "" || values.name !== ""}
            size="lg"
            variant="primary"
            data-testid="pin-only-button"
            // flex={1}
            // onPress={}
            onClick={() => {
              onNext?.({
                name: values.name?.trim(),
                invite_email: "",
              });
            }}
          >
            <ButtonText>Generate PIN Only</ButtonText>
          </Button>
        </div>

        <S.WarningText>
          Temporary Access PINs: Secure One-Time Use for Delivery Personnel and
          More!
        </S.WarningText>
      </div>
    </form>
  );
}

GuestInfoFormComponent.defaultProps = {
  guestInfo: undefined,
};

export { GuestInfoFormComponent };

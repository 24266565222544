import { TextField } from "@mui/material";
import styled from "styled-components";

import { InputHeightType } from "../../text-field/input-field/input-field";

export interface MuiTextFieldInputProps {
  color?: string;
  error?: boolean;
  backgroundColor?: string;
  inputHeight?: InputHeightType;
}

const MuiTextField = styled(TextField).attrs(
  // @ts-ignore
  (props: MuiTextFieldInputProps) => props
)`
  width: 100%;

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 48px;
    margin-top: 4px;
    background-color: ${(props) =>
      // @ts-ignore
      props.backgroundColor || props.theme.color.surface.default};
    padding: 13px 13px 13px 13px !important;

    ${(props) =>
      props.error &&
      `background-color: ${props.theme.color.surface.criticalSubdued}; border: 1px solid ${props.theme.color.border.criticalDefault}; `};

    & .MuiInputAdornment-root {
      margin-left: 0;
    }

    &:focus {
      background: ${(props) => props.theme.color.surface.default};
      border: 1px solid ${(props) => props.theme.color.border.subdued};
    }

    &:focus-visible {
      outline: 2px solid ${(props) => props.theme.color.focused.default};
      ${(props) =>
        props.error &&
        `background-color: ${props.theme.color.surface.criticalSubdued}; border: 1px solid ${props.theme.color.border.criticalDefault}; outline:none; `}
    }

    & .MuiIconButton-root {
      margin: 0;
      padding: 0 1px 0 0;

      & svg {
        height: 18px;
        width: 18px;
        fill: ${(props) => props.theme.color.text.default};
      }
    }

    & input {
      padding: 0;
      font-family: ${(props) => props.theme.fontFamily.normal};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.color.text.default};
    }

    & fieldset {
      display: none;

      & legend {
        display: none;
      }
    }

    .MuiInputBase-inputAdornedStart {
      padding: 0 !important;
    }

    .MuiInputBase-inputAdornedEnd {
      padding: 0 !important;
    }

    &:disabled {
      background: ${(props) => props.theme.color.surface.default};
      color: ${(props) => props.theme.color.text.disabled};

      & .MuiIconButton-root {
        & svg {
          fill: ${(props) => props.theme.color.text.critical};

          & path {
            fill: red;
          }
        }
      }
    }
  }
`;

export { MuiTextField };

import React from "react";
import "./right-sidebar-layout.scss";

export interface RightSidebarLayoutProps {
  visible: boolean;
  onClose: (() => void) | undefined;
}

function RightSidebarLayout({
  children,
  visible,
  onClose,
}: React.PropsWithChildren<RightSidebarLayoutProps>) {
  return (
    <div className="right-sidebar-layout" hidden={!visible}>
      <div className="overlay-blank" onClick={onClose} />
      <div
        className="form-wrapper"
        style={{ width: visible ? "483px" : 0, display: "flex" }}
      >
        {children}
      </div>
    </div>
  );
}

export { RightSidebarLayout };

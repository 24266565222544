import { DoorResponse } from "../../../../../../common/redux/services/model";
import {
  Checkbox,
  H4,
} from "../../../../../../common/components/design-system";
import { DoorImgWrapper } from "../../../../my-access/components/door/door";

import { ReactComponent as DoorIcon } from "assets/icons/door.svg";

interface DoorListSelectItemProps {
  door: DoorResponse;
  checked: boolean;
  otherDoorSelected?: boolean;
  onClick: (checked: boolean) => void;
}
function DoorListSelectItem({
  door,
  checked,
  onClick,
  otherDoorSelected,
}: DoorListSelectItemProps) {
  return (
    <div className="door-wrapper">
      <div className="door-title-img-wrapper">
        <DoorImgWrapper>
          <div
            style={{ width: "64px", display: "flex", justifyContent: "center" }}
          >
            <DoorIcon />
          </div>
        </DoorImgWrapper>
        <div style={{ flexDirection: "row" }}>
          <div className="door-title">
            <H4>{door?.doorName}</H4>
          </div>
          {/* <div>
          <H4>Message</H4>
        </div> */}
        </div>
      </div>
      <div className="door-pin">
        <Checkbox
          data-testid="door-list-select-item-checkbox"
          title=""
          onChange={() => onClick(!checked)}
          // disabled={otherDoorSelected && door?.door_type === "entrance"}
          checked={
            checked ?? (door.doorType === "entrance" && otherDoorSelected)
          }
        />
      </div>
    </div>
  );
}

DoorListSelectItem.defaultProps = {
  otherDoorSelected: false,
};
export { DoorListSelectItem };

import { Door } from "../../../../../common/redux/services/model";
import { DoorComponent } from "../door/door";
import "./door-list.scss";

interface DoorListPrams {
  doors: Door[];
  showPin?: boolean;
  setPinModalVisible?: (item: any) => void;
}
function DoorList({ doors, showPin, setPinModalVisible }: DoorListPrams) {
  return (
    <div className="door-list-wrapper">
      {doors.map((door) => (
        <DoorComponent
          key={door.id}
          door={door}
          showPin={showPin}
          setPinModalVisible={setPinModalVisible}
        />
      ))}
    </div>
  );
}
export { DoorList };

/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../navigations/Types";
import { TopNav } from "../../../common/components/top-nav";
import { HeaderOption } from "../../../common/components/top-nav/type";
import { Button } from "../../../common/components/design-system";
import { Api } from "../../../common/redux/services/base/api";

import { InputFieldIcon } from "../../../common/components/design-system/text-field/input-field-icon/input-field-icon";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import StaffCard from "./components/StaffCard/index";
import UserItem from "./components/UserItem";
import { RightSidebarLayout } from "common/components/right-sidebar-layout";

import * as S from "./styles";
import { AddNewStaff } from "./add-new-staff";

interface User {
  userUuid: string;
  name: string;
  buildingAccess: [];
  organizationAccess: [];
  status: string;
  email: string;
}

interface Building {
  uuid: string;
  name: string;
}

interface StaffData {
  statusCount: { pending: number; active: number; others: number };
  users: User[];
}

export async function getStaff(
  isManager: boolean,
  setLoading: any,
  setBuildings: any,
  setSelectedBuilding: any,
  setFilteredData: any,
  setStaffData: any,
  setBuildingManagers: any,
  setOrganizations: any,
  setOrganizationStaffData: any,
  setFilteredOrganizationStaffData: any,
  filteredData: any
): Promise<StaffData> {
  setLoading(true);
  const permissions = [] as string[];
  permissions.push(isManager ? "building-manager" : "building-staff");

  const result = await Api.post(
    "/api/v1/building-management/building-users/search",
    {
      buildingIds: "*",
      permissions: permissions,
    }
  );

  if (result != null) {
    let buildings: Building[] = [];
    const newStaffData = result.data;

    if (!newStaffData.users) {
      return Promise.reject();
    }

    for (let i = 0; i < newStaffData.users.length; i++) {
      const user = newStaffData.users[i];
      for (let j = 0; j < user?.buildingAccess.length; j++) {
        const door = user?.buildingAccess[j];
        if (door.buildingUuid) {
          const building = buildings.find(
            // @ts-ignore
            (building) => building.buildingUuid === door.buildingUuid
          );
          if (!building) {
            buildings.push({
              // @ts-ignore
              buildingUuid: door.buildingUuid,
              buildingName: door.buildingName,
            });
          }
        }
      }
    }

    setBuildings([
      {
        // @ts-ignore
        buildingName: "All Properties",
        buildingUuid: "*",
      },
      ...buildings,
    ]);

    setSelectedBuilding({
      name: "All Properties",
      uuid: "*",
    });

    if (isManager) {
      setFilteredData({
        ...filteredData,
        buildingManagers: newStaffData,
      });
      setBuildingManagers(newStaffData);
    } else {
      setFilteredData({
        ...filteredData,
        staff: newStaffData,
      });
      setStaffData(newStaffData);
    }
    setLoading(false);
    return Promise.resolve(result.data.rows);
  } else {
    setLoading(false);
    return Promise.reject();
  }
}

export async function getOrganizationStaff(
  setLoading: any,
  setOrganizations: any,
  setSelectedBuilding: any,
  setFilteredOrganizationStaffData: any,
  setOrganizationStaffData: any
): Promise<any> {
  setLoading(true);
  const result = await Api.post(
    "/api/v1/organization-management/organization-users/search",
    {
      organizationIds: ["*"],
      permissions: ["organization-manager", "organization-staff"],
    }
  );
  if (result != null) {
    let newOrganizations = [];
    const newStaffData = result.data;
    for (let i = 0; i < newStaffData.users.length; i++) {
      const user = newStaffData.users[i];
      for (let j = 0; j < user?.organizationAccess.length; j++) {
        const door = user?.organizationAccess[j];
        if (door.organizationUuid) {
          const organization = newOrganizations.find(
            (currOrganization) =>
              currOrganization.organizationUuid === door.organizationUuid
          );
          if (!organization) {
            newOrganizations.push({
              organizationUuid: door.organizationUuid,
              organizationName: door.organizationName,
            });
          }
        }
      }
    }

    setOrganizations([
      {
        // @ts-ignore
        organizationName: "All Organizations",
        organizationUuid: "*",
      },
      ...newOrganizations,
    ]);

    setSelectedBuilding({
      name: "All Properties",
      uuid: "*",
    });

    setFilteredOrganizationStaffData(newStaffData);
    setOrganizationStaffData(newStaffData);
    setLoading(false);
    return Promise.resolve(result.data.rows);
  } else {
    setLoading(false);
    return Promise.reject();
  }
}

export function BuildingStaff() {
  const navigate = useNavigate();
  const theme = useTheme();

  const userOption: HeaderOption = {
    url: "/BuildingStaff",
    title: "Building Staff",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [filteredData, setFilteredData] = useState({
    staff: [],
    buildingManagers: [],
    organization: [],
  });
  const [buildingManagers, setBuildingManagers] = useState<StaffData>(
    {} as StaffData
  );
  const [showForm, setShowForm] = useState<string | undefined>();

  const [staffData, setStaffData] = useState<StaffData>({} as StaffData);
  const [filteredStaffData, setFilteredStaffData] = useState<StaffData>();
  const [selectedBuilding, setSelectedBuilding] = useState({
    name: "All Properties",
    uuid: "*", // * means all buildings
  });
  const [buildings, setBuildings] = useState<Building[]>([
    {
      name: "All Properties",
      uuid: "*", // * means all buildings
    },
  ]);
  const [organizations, setOrganizations] = useState([
    {
      organizationName: "All Organizations",
      organizationUuid: "*", // * means all organizations
    },
  ]);

  const [organizationStaffData, setOrganizationStaffData] = useState([]);
  const [filteredOrganizationStaffData, setFilteredOrganizationStaffData] =
    useState([]);

  const variants = ["staff", "building-manager", "organization"];
  const [currentVariantIndex, setCurrentVariantIndex] = useState(0);

  useEffect(() => {
    getStaff(
      false,
      setLoading,
      setBuildings,
      setSelectedBuilding,
      setFilteredData,
      setStaffData,
      setBuildingManagers,
      setOrganizations,
      setOrganizationStaffData,
      setFilteredOrganizationStaffData,
      filteredData
    );
    getStaff(
      true,
      setLoading,
      setBuildings,
      setSelectedBuilding,
      setFilteredData,
      setStaffData,
      setBuildingManagers,
      setOrganizations,
      setOrganizationStaffData,
      setFilteredOrganizationStaffData,
      filteredData
    );
    getOrganizationStaff(
      setLoading,
      setOrganizations,
      setSelectedBuilding,
      setFilteredOrganizationStaffData,
      setOrganizationStaffData
    );
  }, [showForm]);

  useEffect(() => {
    // istanbul ignore next
    if (searchText) {
      let filtered = [];

      if (
        variants[currentVariantIndex] === "staff" ||
        variants[currentVariantIndex] === "building-manager"
      ) {
        let data =
          variants[currentVariantIndex] === "staff"
            ? staffData
            : buildingManagers;
        if (!data.users) {
          return;
        }
        for (let i = 0; i < data.users.length; i++) {
          if (
            data.users[i]?.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            // @ts-ignore
            filtered.push(data.users[i]);
          }
        }

        if (variants[currentVariantIndex] === "staff") {
          setFilteredData({
            ...filteredData,
            staff: {
              ...filteredData.staff,
              // @ts-ignore
              users: filtered,
            },
          });
        } else {
          setFilteredData({
            ...filteredData,
            buildingManagers: {
              ...filteredData.buildingManagers,
              // @ts-ignore
              users: filtered,
            },
          });
        }
      } else {
        // @ts-ignore
        for (let i = 0; i < organizationStaffData.users.length; i++) {
          if (
            // @ts-ignore
            organizationStaffData.users[i]?.name
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            // @ts-ignore
            filtered.push(organizationStaffData.users[i]);
          }
        }

        setFilteredData({
          ...filteredData,
          organization: {
            ...filteredData.organization,
            // @ts-ignore
            users: filtered,
          },
        });
      }
    } else {
      setFilteredData({
        // @ts-ignore
        staff: staffData,
        // @ts-ignore
        buildingManagers: buildingManagers,
        organization: organizationStaffData,
      });
    }
  }, [searchText, staffData, buildingManagers, organizationStaffData]);

  const renderSubcomponent = useCallback(() => {
    switch (showForm) {
      case "resident_form":
        return (
          <AddNewStaff
            buildingData={[]}
            organizations={organizations}
            onClose={() => {
              setShowForm(undefined);
            }}
          />
        );

      default:
        return undefined;
    }
  }, [showForm]);

  const HEADER = useMemo(() => {
    return (
      <S.HeaderContainer data-testid="staff-header">
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            // justifyContent: "space-between",
            gap: 24,
            alignItems: "center",
          }}
          data-testid="card-container"
        >
          {variants.map((variant, index) => {
            /* istanbul ignore next */
            return (
              <div
                onClick={() => {
                  setCurrentVariantIndex(index);
                }}
                data-testid={`staff-card-${variant}`}
              >
                <StaffCard
                  // @ts-ignore
                  staffData={
                    index == 0
                      ? staffData
                      : index == 1
                      ? buildingManagers
                      : organizationStaffData
                  }
                  variant={variant}
                  selected={index === currentVariantIndex}
                />
              </div>
            );
          })}
        </div>

        <RightSidebarLayout
          visible={!!showForm}
          onClose={() => setShowForm(undefined)}
        >
          {renderSubcomponent()}
        </RightSidebarLayout>
      </S.HeaderContainer>
    );
  }, [searchText, staffData, showForm, currentVariantIndex]);

  const data = useMemo(() => {
    return currentVariantIndex === 0
      ? filteredData.staff
      : currentVariantIndex === 1
      ? filteredData.buildingManagers
      : filteredData.organization;
  }, [currentVariantIndex, filteredData]);

  return (
    <>
      <div
        style={{
          marginTop: 20,
        }}
      >
        <TopNav navigationOption={userOption} />
      </div>

      {HEADER}
      <div
        data-testid="staff-page"
        style={{
          flex: 1,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              width: "100%",
              marginBottom: 24,
            }}
          >
            <InputFieldIcon
              data-testid="search-input"
              style={{
                width: "286px",
              }}
              prefixIcon={() => <SearchIcon />}
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <Button
              data-testid="add-staff-button"
              variant="basic"
              size="lg"
              style={{
                width: "54px",
                fontSize: "24px",
                color: theme.color.icon.hovered,
              }}
              onClick={() => {
                setShowForm("resident_form");
              }}
            >
              +
            </Button>
          </div>

          {/* @ts-ignore */}
          {data?.users &&
            // @ts-ignore
            data?.users.map((user, index) => {
              return (
                <UserItem
                  // @ts-ignore
                  user={user}
                  key={index}
                  variant={variants[currentVariantIndex]}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}
const route: AppRoute = {
  name: "BuildingStaff",
  screen: BuildingStaff,
  path: "/building-staff",
  role: [UserRole.Authenticated],
};
export { route };

// istanbul ignore file
import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import moment from "moment/moment";
import { toast } from "react-toastify";

import {
  DoorResponse,
  GuestAccessDate,
} from "../../../../common/redux/services/model";
import { DoorAccessWrapperComponent } from "../../guests/add-guest/components/door-access-wrapper/door-access-wrapper";

import { Button, H4 } from "../../../../common/components/design-system";
import { GuestSelectDoorsFormComponent } from "../../guests/add-guest/components/guest-select-doors-form/guest-select-doors-form";
import { StepIndicator } from "../../../../common/components/step-indicator";
import { AddGuestConfirmation } from "../../guests/add-guest/components/add-guest-confirmation/add-guest-confirmation";
import { addDoorAccess } from "common/redux/services/guests.service";

interface AddDoorProps {
  guestName?: string;
  guestId: string;
  guestInfo: any;
  onClose: () => void;
  defaultActiveStep?: number;
  offsetShortName?: string;
}

export function saveDoorAccess(
  doorAccessDate: any,
  doorList: any,
  guestId: string,
  guestName: string,
  setAddDoorAccessResponse: any

) {
  let startedAt = new Date(
    `${moment(doorAccessDate?.startDate).format("YYYY-MM-DD")}T${moment(
      doorAccessDate?.startTime
    ).format("HH:mm:ss")}`
  );

  let endedAt = doorAccessDate?.endDate
    ? new Date(
        `${moment(doorAccessDate?.endDate).format("YYYY-MM-DD")}T${moment(
          doorAccessDate?.endTime
        ).format("HH:mm:ss")}`
      )
    : undefined;

  const dateData = {
    startedAt: startedAt.toISOString(),
    // @ts-ignore
    endedAt: endedAt ?? undefined,
    permissionLevel: "resident",
    // startedAt: "2023-01-01 22:00:00",
    // endedAt: "2023-12-01 07:15:00",
  };
  const doorIdList = doorList?.map((door: any) => door.doorUuid) ?? [];

  const response = addDoorAccess({
    ...dateData,
    doorList: doorIdList,
    guestId,
    allowedNickname: guestName,
  });
  setAddDoorAccessResponse(response);
}

function AddDoor({
  guestName,
  guestId,
  onClose,
  guestInfo,
  defaultActiveStep,
}: AddDoorProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(defaultActiveStep ?? 0);
  const [doorList, setDoorList] = useState<DoorResponse[]>();
  const [doorAccessDate, setDoorAccessDate] = useState<
    GuestAccessDate | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [addDoorAccessResponse, setAddDoorAccessResponse] = useState<any>();

  const saveDoorAccessCallback = useCallback(() => {
    saveDoorAccess(
      doorAccessDate,
      doorList,
      guestId,
      guestName!,
      setAddDoorAccessResponse
    );
  }, [doorList, doorAccessDate]);

  useEffect(() => {
    const response = addDoorAccessResponse;
    if (response) {
      toast.success("Door added successfully");
      onClose?.();
    }
  }, [addDoorAccessResponse]);
  
  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <GuestSelectDoorsFormComponent
            data-testid={"guest-select-doors-form"}
            doorSelectVariant="add_door"
            selectedDoorList={doorList!}
            onNext={(doorList) => {
              setDoorList(doorList);
              setActiveStep(activeStep + 1);
            }}
            goBack={(doorList) => {
              setDoorList(doorList);
              setActiveStep(activeStep - 1);
            }}
            currentModule="manager"
          />
        );
      case 1:
        return (
          <DoorAccessWrapperComponent
            data-testid={"door-access-wrapper"}
            isLoading={isLoading}
            accessFormVariant="add_door"
            title="Access"
            doorAccessDate={doorAccessDate!}
            goBack={(doorAccessDate) => {
              setDoorAccessDate(doorAccessDate);
              setActiveStep(activeStep - 1);
            }}
            onDelete={() => {}}
            onSubmit={(doorAccessDate) => {
              setDoorAccessDate(doorAccessDate);
              setActiveStep(activeStep + 1);
            }}
          />
        );
      case 2:
        return (
          <AddGuestConfirmation
            data-testid={"add-guest-confirmation"}
            isLoading={isLoading}
            accessFormVariant="add_door"
            title="Access"
            doorAccessInfo={doorAccessDate!}
            guestInfo={guestInfo!}
            doorList={doorList!}
            // @ts-ignore
            goBack={(doorAccessDate: any) => {
              //setDoorAccessDate(doorAccessDate);
              setActiveStep(activeStep - 1);
            }}
            onDelete={(doorAccess: any) => {
              const newDoorList = doorList!.filter(
                (door) => door.doorUuid !== doorAccess.doorUuid
              );
              setDoorList(newDoorList);
            }}
            onSubmit={(doorAccessDate: any) => {
              setDoorAccessDate(doorAccessDate);
              saveDoorAccessCallback();
            }}
          />
        );
    }
  }, [activeStep, isLoading, doorList]);

  return (
    <div
      data-testid={"add-door-container"}
      className="common-form-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>Edit Access</H4>
          </div>
          <div className="form-cancel">
            <Button
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <StepIndicator
          style={{ marginTop: "14åpx", marginBottom: "49px" }}
          totalSteps={3}
          activeStep={activeStep}
        />
        {renderSubComponent()}
      </div>
    </div>
  );
}

export { AddDoor };

import { useState } from "react";

import {
  Button,
  ButtonText,
} from "../../../../common/components/design-system";
import { Api } from "common/redux/services/base/api";

import * as S from "./styles";
import i18n from 'i18n-js';

function DoorInfoConfirmation({
  addNewDoorData,
  setAddNewDoorData,
  onNext,
  goBack,
}: any) {
  const [loading, setLoading] = useState(false);

  async function addDoor(): Promise<any> {
    setLoading(true);
    try {
      const { lockId, doorName, floor, doorType, building } = addNewDoorData;
      const result = await Api.post("/api/v1/doors", {
        apadLockNumber: lockId,
        name: doorName,
        doorFloor: floor,
        doorUnit: "",
        doorType: doorType.toLowerCase(),
        wirelessCarrier: "2none",
        wirelessImei: "",
        wirelessApn: "2apn",
        wirelessTel: "21 28 1234",
        handsFree: true,
        buildingUuid: building.buildingUuid,
      });

      if (!result) {
        throw new Error(i18n.t("message.no_result_from_api"));
      }
      return Promise.resolve(result);
    } catch (error) {
      console.error("Error in addDoor:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const doorDetails = [
    {
      key: "Building",
      value: addNewDoorData.building.name,
    },
    {
      key: "Door Name",
      value: addNewDoorData.doorName,
    },
    {
      key: "Door Type",
      value: addNewDoorData.doorType,
    },
    {
      key: "Floor",
      value: addNewDoorData.floor,
    },
    {
      key: "Lock ID",
      value: addNewDoorData.lockId,
    },
  ];

  return (
    <div data-testid="door-info-container">
      <S.ContentContainer style={{ flexDirection: "column" }}>
        <h3 style={{ fontSize: 26, fontWeight: "400" }}>Confirm</h3>
        {/* Guest info */}
        <div style={{ marginTop: 24 }}>
          <h3 style={{ fontSize: 20, fontWeight: "400", marginBottom: 16 }}>
            Details
          </h3>
          {doorDetails.map((item) => (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <h3 style={{ fontSize: 18, fontWeight: "400", color: "#6D7175" }}>
                {item.key}
              </h3>
              <h3 style={{ fontSize: 16, fontWeight: "400", color: "#202223" }}>
                {item.value}
              </h3>
            </div>
          ))}
        </div>
        <div className="bottom-buttons">
          <Button
            size="lg"
            variant="basic"
            onClick={goBack}
          >
            <ButtonText>Previous</ButtonText>
          </Button>
          <div style={{ width: 20 }} />
          <Button
            data-testid="confirm-button"
            size="lg"
            variant="primary"
            onClick={async () => {
              await addDoor();
              onNext();
            }}
          >
            <ButtonText>Confirm</ButtonText>
          </Button>
        </div>
      </S.ContentContainer>
    </div>
  );
}

export { DoorInfoConfirmation };

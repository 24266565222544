/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AppRoute, UserRole } from "../../../navigations/Types";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";
import {
  Button,
  TextField,
  ButtonText,
} from "../../../../common/components/design-system";
import { Api } from "../../../../common/redux/services/base/api";
import { LocalStorage, Session } from "../../../../common/service";
import { Constants } from "../../../../assets/constants";

import "./change-name.scss";
import i18n from 'i18n-js';

interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  userUuid: string;
}

function ChangeName() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();
  const [userData, setUserData] = useState(null);
  const [name, setName] = useState("");
  const [userId, setUserId] = useState("");
  const {
    values,
    dirty,
    errors,
    isValid,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (params, helpers) => {},
  });

  const userOption: HeaderOption = {
    url: "/change-name",
    title: "Change Name",
    showLogo: false,
    rightComponent: () => (
      <div className="top-nav-right-wrapper">
        <Button
          variant="plain"
          onClick={() => navigate("/Profile")}
        >
          Cancel
        </Button>
      </div>
    ),
  };

  // @ts-ignore
  async function getUser(): Promise {
    const result = await Api.get("/api/v1/users/profile");
    if (result != null) {
      const user: User = result.data;
      setUserData(result.data);
      setName(user?.fullName);
      setUserId(user?.userUuid);
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  async function updateName(name: string): Promise<void> {
    try {
      const result = await Api.patch(`/api/v1/users/${userId}`, {
        name,
      });

      if (!result) {
        throw new Error(i18n.t("message.no_result_from_api"));
      }

      setUserData(result.data);
      toast.success("Name Changed Successfully");
      navigate("/Profile");
    } catch (error) {
      console.error("Error in updateName:", error);
      throw error;
    }
  }

  const checkName = (name: string) => {
    if (name.length > 0) {
      updateName(name);
    } else {
      toast.error("Name is required");
    }
  };

  return (
    <div
      style={{ marginTop: 20 }}
      data-testid="change-name-container"
    >
      <TopNav navigationOption={userOption} />
      <div className="guest-profile-wrapper">
        <div>
          <div style={{ marginTop: 10 }}>Name</div>

          <TextField
            data-testid="name-input"
            type={"text"}
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={handleBlur("password")}
          />
        </div>

        <div style={{ marginTop: 50 }}>
          <div style={{ height: 100, width: 100 }}></div>
          <Button
            data-testid="apply-button"
            type="submit"
            size="lg"
            variant="primary"
            onClick={() => checkName(name)}
          >
            <ButtonText>Apply</ButtonText>
          </Button>
        </div>
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "change-name",
  screen: ChangeName,
  path: "/change-name",
  role: [UserRole.Authenticated],
};
export { ChangeName, route };

import React from "react";
import styled from "styled-components";

import { Input, InputProps } from "../input-field/input-field";

export interface InputFieldWrapper
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      "ref"
    >,
    InputProps {}

const InputFieldIconWrapper = styled.div.attrs((props) => props)`
  position: relative;
`;

const PrefixIconWrapper = styled.div.attrs((props) => props)`
  position: absolute;
  top: 15px;
  left: 13px;
`;
const SuffixIconWrapper = styled.div.attrs((props) => props)`
  position: absolute;
  top: 15px;
  right: 13px;
`;

function InputFieldDelicate({ children, ...rest }: InputFieldWrapper) {
  const PrefixIcon = rest.prefixIcon;
  const SuffixIcon = rest.suffixIcon;
  return (
    <InputFieldIconWrapper>
      {PrefixIcon && (
        <PrefixIconWrapper style={rest.prefixStyle}>
          <PrefixIcon />
        </PrefixIconWrapper>
      )}

      <Input
        {...rest}
        // @ts-ignore
        error={rest.error}
      >
        {children}
      </Input>

      {SuffixIcon && (
        <SuffixIconWrapper style={rest.suffixStyle}>
          <SuffixIcon />
        </SuffixIconWrapper>
      )}
    </InputFieldIconWrapper>
  );
}

InputFieldDelicate.defaultProps = {
  prefixIcon: undefined,
  suffixIcon: undefined,
};
const InputFieldIcon = React.memo(InputFieldDelicate);

export { InputFieldIcon };

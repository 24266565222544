import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  ButtonText,
  SelectField,
} from "../../../../common/components/design-system";
import { Api } from "common/redux/services/base/api";
import { InputFieldIcon } from "common/components/design-system/text-field/input-field-icon/input-field-icon";
import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";

import * as S from "./styles";
import i18n from "i18n-js";

function DoorEdit({
  door,
  buildingData,
  addNewDoorData,
  setAddNewDoorData,
  onNext,
  goBack,
  route,
}: any) {
  const [loading, setLoading] = useState(true);

  const [buildingsNames, setBuildingsNames] = useState([]);
  const [doorsNames, setDoorsNames] = useState([]);

  const [selectedBuilding, setSelectedBuilding] = useState({
    buildingUuid: "",
  });
  const [selectedDoor, setSelectedDoor] = useState("");
  const [doorName, setDoorName] = useState(door.name);
  const [doorType, setDoorType] = useState(door.type);
  const [doorFloor, setDoorFloor] = useState(door.floor);
  const [buildingFloors, setBuildingFloors] = useState(
    buildingData[0].floors ?? []
  );
  const [error, setError] = useState("");
  const [selectedDoorType, setSelectedDoorType] = useState("");
  const [selectedDoorFloor, setSelectedDoorFloor] = useState(doorFloor);
  const [doorTypeList, setDoorTypeList] = useState(["private", "shared"]);

  useEffect(() => {
    const names = buildingData.map((building: any) => building.name);
    setBuildingsNames(names);
    setSelectedBuilding(names[0]);

    const newSelectedBuilding = buildingData.find((curr: any) => {
      return curr.name == door.buildingName;
    });
    setSelectedBuilding(newSelectedBuilding);
    setSelectedDoorType(doorType);
    const doors = buildingData.map((building: any) => building.data);
    const doorsNames = doors.map((door: any) => door.map((d: any) => d));
    const doorsNamesFlat = doorsNames.flat();

    setDoorsNames(doorsNamesFlat);
    const newSelectedDoor = doorsNamesFlat.find((curr: any) => {
      return (
        curr.name == door.name && newSelectedBuilding.name == door.buildingName
      );
    });
    setSelectedDoor(newSelectedDoor);

    if (doorType == "shared") {
      setSelectedDoorType("shared");
      setDoorTypeList(["shared", "private"]);
    } else {
      setSelectedDoorType("private");
      setDoorTypeList(["private", "shared"]);
    }

    setLoading(false);
  }, [buildingData]);

  async function handleSubmit() {
    setLoading(true);
    try {
      const result = await Api.put(
        `/api/v1/building-management/doors/${door.uuid}`,
        {
          name: doorName,
          doorFloor: selectedDoorFloor,
          doorType: selectedDoorType,
          buildingUuid: selectedBuilding.buildingUuid,
        }
      );

      if (!result) {
        throw new Error(i18n.t("message.no_result_from_api"));
      }

      toast.success("Door updated successfully");
      goBack(doorName);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error("Failed to update door");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {!loading && (
        <>
          <div
            data-testid="door-edit-container"
            style={{
              padding: "0 24px",
              paddingBottom: 24,
              borderBottomWidth: 1,
              borderColor: "#cbcbcb",
            }}
          >
            <h3 style={{ fontSize: 26, fontWeight: "400" }}>
              {door.buildingName}: {door.name}
            </h3>
          </div>
          <S.ContentContainer
            style={{
              flexDirection: "column",
              backgroundColor: "#FAFBFB",
            }}
          >
            <div style={{ marginTop: 24, flex: 1 }}>
              <div style={{ marginTop: 24 }}>
                <SelectField<any, any>
                  label="Building"
                  icon={DownIcon}
                  name="type"
                  data={buildingData}
                  selectedValue={selectedBuilding}
                  onSelection={(selected: any) => {
                    setSelectedBuilding(selected);
                  }}
                  valueExtractor={(item) => item}
                  labelExtractor={(item) => item.name}
                  keyExtractor={(item) => item.toString()}
                />
              </div>
              <div>
                <S.InputContainer>
                  <S.InputLabel>Door Name</S.InputLabel>
                  <InputFieldIcon
                    // @ts-ignore
                    onChange={(event: InputText) => {
                      setDoorName(event.target.value);
                    }}
                    value={doorName}
                    placeholder={door.name}
                  />
                  <div style={{ fontSize: 12, color: "red" }}>{error}</div>
                  <h4
                    style={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#808080",
                      marginTop: 8,
                    }}
                  ></h4>
                </S.InputContainer>
              </div>

              <div style={{ marginTop: 24 }}>
                <SelectField<any, any>
                  label="Door Type"
                  icon={DownIcon}
                  name="type"
                  data={doorTypeList}
                  selectedValue={selectedDoorType}
                  onSelection={(selected: any) => {
                    setSelectedDoorType(selected);
                  }}
                  valueExtractor={(item) => item}
                  labelExtractor={(item) =>
                    item.charAt(0).toUpperCase() + item.slice(1)
                  }
                  keyExtractor={(item) => item.toString()}
                />
              </div>

              <div style={{ marginTop: 24 }}>
                <SelectField<any, any>
                  label="Floor"
                  icon={DownIcon}
                  name="type"
                  data={buildingFloors}
                  selectedValue={doorFloor}
                  onSelection={(selected: any) => {
                    setSelectedDoorFloor(selected);
                  }}
                  valueExtractor={(item) => item}
                  labelExtractor={(item) => item}
                  keyExtractor={(item) => item.toString()}
                />
              </div>
            </div>
            <div className="bottom-buttons">
              <Button
                size="lg"
                variant="basic"
                onClick={goBack}
              >
                <ButtonText>Cancel</ButtonText>
              </Button>
              <div style={{ width: 20 }} />
              <Button
                data-testid="save-button"
                size="lg"
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <ButtonText>Save</ButtonText>
              </Button>
            </div>
          </S.ContentContainer>
        </>
      )}
    </>
  );
}

export { DoorEdit };

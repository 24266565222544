import { useEffect, useState } from "react";
import { InputFieldIcon } from "common/components/design-system/text-field/input-field-icon/input-field-icon";

import {
  Button,
  ButtonText,
  SelectField,
} from "../../../../common/components/design-system";
import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";

import * as S from "./styles";

function FillDoorInfo({
  addNewDoorData,
  setAddNewDoorData,
  onNext,
  goBack,
}: any) {
  const [doorData, setDoorData] = useState({
    doorName: addNewDoorData.doorName,
    doorType: addNewDoorData.doorType,
    floor: addNewDoorData.floor,
    lockId: addNewDoorData.lockId,
  });
  const [allowedToNext, setAllowedToNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setAddNewDoorData({ ...addNewDoorData, ...doorData });
  }, [doorData]);

  const nextButton = () => {
    if (doorData.doorName === "") {
      setErrorMessage("*Door Name cannot be empty");
    } else {
      onNext();
    }
  };
  return (
    <div data-testid="fill-door-info-container">
      <S.ContentContainer
        style={{
          flexDirection: "column",
        }}
      >
        <S.InputContainer>
          <S.InputLabel>Door Name</S.InputLabel>
          <InputFieldIcon
            data-testid="door-name-input"
            // @ts-ignore
            onChange={(event: InputText) => {
              setErrorMessage("");
              setDoorData({ ...doorData, doorName: event.target.value });
            }}
            value={doorData.doorName}
          />
        </S.InputContainer>

        <div style={{ marginTop: 24 }}>
          <SelectField<any, any>
            data-testid="door-type-input"
            label="Door Type"
            icon={DownIcon}
            name="type"
            data={["Private", "Shared"]}
            selectedValue={doorData.doorType}
            onSelection={(selected: any) => {
              setDoorData({ ...doorData, doorType: selected });
            }}
            valueExtractor={(item) => item}
            labelExtractor={(item) => item}
            keyExtractor={(item) => item.toString()}
          />
        </div>

        <div style={{ marginTop: 24 }}>
          <SelectField<any, any>
            data-testid="floor-input"
            label="Floor"
            icon={DownIcon}
            name="Floors"
            data={addNewDoorData.building.floors}
            selectedValue={doorData.floor}
            onSelection={(selected: any) => {
              setDoorData({ ...doorData, floor: selected });
            }}
            valueExtractor={(item) => item}
            labelExtractor={(item) => item}
            keyExtractor={(item) => item.toString()}
          />
        </div>

        <S.InputContainer>
          <S.InputLabel>Lock ID (Optional)</S.InputLabel>
          <InputFieldIcon
            data-testid="lock-id-input"
            // label="Lock ID (Optional)"
            // @ts-ignore
            onChange={(event: InputText) => {
              setDoorData({ ...doorData, lockId: event.target.value });
            }}
            value={doorData.lockId}
          />
          <h3 style={{ fontSize: 14, fontWeight: "400", color: "#808080" }}>
            Enter the Lock ID manually or scan the QR code on the packaging.˝
          </h3>
        </S.InputContainer>
      </S.ContentContainer>
      <div>
        <h2 style={{ fontSize: 12, color: "red", marginTop: 10 }}>
          {errorMessage}
        </h2>
      </div>

      <div className="bottom-buttons">
        <Button
          size="lg"
          variant="basic"
          onClick={goBack}
        >
          <ButtonText>Previous</ButtonText>
        </Button>
        <div style={{ width: 20 }} />
        <Button
          data-testid="next-button"
          size="lg"
          variant="primary"
          onClick={nextButton}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      </div>
    </div>
  );
}

export { FillDoorInfo };

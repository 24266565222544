import styled, { css } from "styled-components";

export type Size = "sm" | "md" | "lg";

export interface NavLinkButtonWrapperProps {
  size?: Size;
  isLinkOpened?: boolean;
}

const NavLinkWrapper = styled.button.attrs(
  // @ts-ignore
  (props: NavLinkButtonWrapperProps) => props
)`
  width: 100%;

  font-family: ${(props) => props.theme.fontFamily.medium};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  height: ${(props) => {
    // @ts-ignore
    return props.theme.size.navLink[props.size || "sm"];
  }};

  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  ${(props) => css`
    background: none;
    border: 1px solid transparent;
    color: ${props.theme.color.text.onPrimary};
    fill: ${props.theme.color.text.onPrimary};

    &:hover {
      background: ${props.theme.color.background.darkPressed};
    }

    &:focus {
      outline: 2px solid ${props.theme.color.focused.default} !important;
      outline-offset: 1px;
    }

    &:active {
    }

    &:disabled {
      background: ${props.theme.color.surface.disabled};
      border: 1px solid ${props.theme.color.border.disabled};
      color: ${props.theme.color.text.disabled};
      fill: ${props.theme.color.text.disabled};
    }
  `}

  ${(props) =>
    // @ts-ignore
    props.isLinkOpened &&
    css`
      font-weight: 700;
      border-radius: 4px;
      background: ${props.theme.color.background.darkPressed};
    `}
`;
NavLinkWrapper.defaultProps = {
  // @ts-ignore
  size: "sm",
};
export { NavLinkWrapper };

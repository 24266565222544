import * as React from "react";
import moment from "moment";

import * as S from "./styles";
import { Box } from "@mui/system";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ConfirmationModal } from "common/components/ConfirmationModal/ConfirmationModal";
import { accessTypesInfo } from "constants/accessTypeInfo";

interface Building {
  resourceName: string;
  permissionLevel: string;
  creatorName: string;
  doorAccess: any;
}
interface BuildingHeaderProps {
  building: Building;
}

function BuildingHeader({ building }: BuildingHeaderProps) {
  const oldestDoorAccess =
    building?.doorAccess.length > 0
      ? // @ts-ignore
        building?.doorAccess?.reduce((prev, current) => {
          return prev < current.startedAt ? prev : current.startedAt;
        })
      : "";
  const dateFormatted = moment(oldestDoorAccess).format("MMM DD, YYYY");
  const [modalVisible, setModalVisible] = React.useState(false);
  const formattedName = building?.permissionLevel
    ? building.permissionLevel.replace(/(-|^)([^-]?)/g, (_, prep, letter) => {
        return (prep && " ") + letter.toUpperCase();
      })
    : "Staff";

  const accessType = accessTypesInfo.find(
    (accessTypeInfo) => accessTypeInfo.value === building.permissionLevel
  );

  return (
    <>
      <S.ItemContainer
        style={{ marginTop: 16 }}
        data-testid="building-header"
      >
        <div
          style={{
            borderRadius: 32,
            marginRight: 16,
            width: 64,
            height: 64,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid",
            backgroundColor: "#101010",
          }}
        >
          <BuildingIcon />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <S.ItemName>{building.resourceName}</S.ItemName>
          {building.permissionLevel && (
            <S.PermissionText
              data-testid="door-item-permission-level"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {formattedName}
            </S.PermissionText>
          )}

          {building.creatorName && (
            <S.InvitedByText>Invited by {building.creatorName}</S.InvitedByText>
          )}
        </div>
      </S.ItemContainer>
      <ConfirmationModal
        title={formattedName}
        description={accessType?.description ?? ""}
        confirmText="Got it!"
        visible={modalVisible}
        onConfirm={() => {
          setModalVisible(false);
        }}
        onCancel={() => {
          setModalVisible(false);
        }}
        variant="confirm-only"
      />
    </>
  );
}

BuildingHeader.defaultProps = {
  expanded: false,
  containerStyle: undefined,
};

export { BuildingHeader };

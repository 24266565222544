/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AppRoute, UserRole } from "../../../navigations/Types";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";

import {
  Button,
  ButtonText,
} from "../../../../common/components/design-system";
import { Api } from "../../../../common/redux/services/base/api";
import { LocalStorage, Session } from "../../../../common/service";
import { Constants } from "../../../../assets/constants";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { E164Number } from "libphonenumber-js";

import "./styles";
import moment from "moment";
import { groupBy } from "common/utils";
import { InvitationByDate } from "./InvitationByDate";
import { SearchBar } from "common/components/SearchBar/SearchBar";
import { SelectionOptions } from "common/components/SelectionOptions/SelectionOptions";

function Invitations() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();

  const [invites, setInvites] = useState([]);
  const [filteredInvites, setFilteredInvites] = useState([]);
  const [groupedInvites, setGroupedInvites] = useState([] as any[]);

  function groupInvitationsByDate(invitations: any) {
    invitations.sort((a: any, b: any) => {
      const dateA = moment(a.startedAt);
      const dateB = moment(b.startedAt);
      return dateA.isBefore(dateB) ? 1 : dateA.isAfter(dateB) ? -1 : 0;
    });
    const groupedInvitations = groupBy(invitations, (invitation) => {
      // @ts-ignore
      return moment(invitation.startedAt).format("MMM DD, YYYY");
    });
    return groupedInvitations;
  }

  async function getInvitations(): Promise<any> {
    const result = await Api.get("/api/v1/users/invites");

    if (result != null) {
      setInvites(result.data?.invites ?? []);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  useEffect(() => {
    getInvitations();
  }, []);

  const [searchText, setSearchText] = useState("");
  const options = [
    {
      value: "*",
      label: "All Invites",
    },
    {
      value: "active",
      label: "Accepted",
    },
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "expired",
      label: "Expired",
    },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  function filterInvitesByStatus(invites: any) {
    const filteredInvites = invites.filter((invite: any) => {
      if (selectedOption.value === "*") return true;
      return invite.inviteStatus === selectedOption.value;
    });
    return filteredInvites;
  }

  function filterAndGroupInvites(invites: any) {
    // return groupInvitationsByDate(invites);
    let newInvites = filterInvitesByStatus(invites);
    if (searchText) {
      newInvites = newInvites.filter(
        (invite: any) =>
          invite?.buildingName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          invite?.invitedName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          invite?.permissionLevel
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
      );
    }
    return groupInvitationsByDate(newInvites);
  }

  useEffect(() => {
    let newInvites = filterAndGroupInvites(invites);
    // @ts-ignore
    newInvites = Object.keys(newInvites).map((key) => {
      return {
        date: key,
        invites: newInvites[key],
      };
    });
    // @ts-ignore
    setFilteredInvites(newInvites);
  }, [invites, selectedOption, searchText]);

  const invitationsOption: HeaderOption = {
    url: "/invitations",
    title: "My Invites",
    showLogo: false,
    rightComponent: () => <></>,
  };

  return (
    <>
      <TopNav navigationOption={invitationsOption} />

      <div
        style={{
          flexDirection: "column",
          padding: "24px 24px 0px 24px",
          gap: "24px",
          display: "flex",
        }}
      >
        <SearchBar
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <SelectionOptions
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </div>

      {filteredInvites?.map(({ date, invites }) => {
        return (
          <InvitationByDate
            date={date}
            invites={invites}
            key={date}
          />
        );
      })}
    </>
  );
}
const route: AppRoute = {
  name: "invitations",
  screen: Invitations,
  path: "/invitations",
  role: [UserRole.Authenticated],
};
export { Invitations, route };

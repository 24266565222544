import styled from "styled-components";

export const Container = styled.div`
  justify-content: space-between;
  margin: 24px;
  flex: 1;
`;

// Activity Item
export const ActivityItemContainer = styled.div`
  flex-direction: row;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const ActivityItemIconContainer = styled.div`
  background-color: #f1f2f3;
  padding: 24px;
  border-radius: 8px;
  margin-right: 16px;
  width: auto;
  height: 100%;
`;

export const ActivityItemInfoContainer = styled.div`
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

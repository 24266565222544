import Axios from "axios";

import { formatErrorAndThrow } from "./error-mapper";
import { requestInterceptor, responseInterceptor } from "./interceptor";

import { Constants } from "assets/constants";

const Api = Axios.create({
  baseURL: `${Constants.Config.api.APP_DOMAIN}`,
  timeout: Constants.Config.time.MAX_CONNECTION_TIMEOUT,
  headers: {
    Connection: "close",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

/**
 *  Apply the Api request & response interceptor
 */
// @ts-ignore
Api.interceptors.request.use(requestInterceptor, formatErrorAndThrow);
Api.interceptors.response.use(
  (response) => response,
  (error) => responseInterceptor(error, Api)
);

export { Api };

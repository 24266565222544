/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppRoute, UserRole } from "../../../navigations/Types";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";

import {
  Button,
  TextField,
  ButtonText,
} from "../../../../common/components/design-system";
import { Api } from "../../../../common/redux/services/base/api";
import mailIcon from "../../../../assets/svg/mail.svg";
import "./change-email.scss";
import i18n from 'i18n-js';

const LoginSchema = Yup.object().shape(
  {
    email: Yup.string()
      .required("Email is required")
      .test("email", "Email is invalid", function (value) {
        const { path, createError } = this;
        if (value !== undefined && Number.isNaN(+value)) {
          return /^((?!\.)[\w-_+.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(
            value
          );
        }
        return (
          value?.length === 10 ||
          createError({
            path,
            message: "Email is invalid",
          })
        );
      }),
    password: Yup.string().required("Password is required"),
  },
  []
);

interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  userUuid: string;
}

function ChangeEmail() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const theme = useTheme();
  const [userData, setUserData] = useState(null);
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [emailConfirmatiionVisble, setEmailConfirmationVisible] =
    useState(false);
  const {
    values,
    dirty,
    errors,
    isValid,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (params, helpers) => {},
  });

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      bottom: "auto",
      marginRight: "10%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
    },
  };

  const userOption: HeaderOption = {
    url: "/change-email",
    title: "Change Email",
    showLogo: false,
    rightComponent: () => (
      <div className="top-nav-right-wrapper">
        <Button
          variant="plain"
          onClick={() => navigate("/Profile")}
        >
          Cancel
        </Button>
      </div>
    ),
  };

  // @ts-ignore
  async function getUser(): Promise {
    const result = await Api.get("/api/v1/users/profile");
    if (result != null) {
      const user: User = result.data;
      setUserData(result.data);
      setCurrentEmail(user.email);
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }
  useEffect(() => {
    getUser();
  }, []);

  async function updateEmail(email: string): Promise<any> {
    try {
      const result = await Api.put("/api/v1/users/email", {
        newEmail: email,
      });

      if (!result) {
        throw new Error(i18n.t("message.no_result_from_api"));
      }

      setUserData(result.data);
      toast.success("Email Changed Successfully");
      navigate("/Profile");
      return result.data;
    } catch (error) {
      console.error("Error in updateEmail:", error);
      throw error;
    }
  }

  function checkEmail() {
    if (newEmail === "") {
      setErrorMessage1("*Email cannot be empty");
    } else if (reg.test(newEmail) === false) {
      setErrorMessage1("*Must be a valid email address");
    } else if (newEmail === currentEmail) {
      setErrorMessage1("*Email cannot be the same as current email");
    } else if (newEmail === confirmEmail) {
      setConfirmationModalVisible(true);
      setEmailConfirmationVisible(false);
    } else {
      setErrorMessage2("*Emails do not match");
    }
  }

  return (
    <div data-testid="change-email-page">
      <TopNav navigationOption={userOption} />
      <div className="guest-profile-wrapper">
        <div style={{ marginTop: 20 }}>
          <h5>
            Updating your email address will also update your login credentials
            and any email communications with the app
          </h5>
        </div>
        <div>
          <div style={{ marginTop: 10 }}>New Email</div>

          <TextField
            data-testid="new-email-input"
            type={"email"}
            name="newEmail"
            id="newEmail"
            value={newEmail}
            autoComplete="email"
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <div style={{ fontSize: 12, color: "red", marginTop: -15 }}>
            {errorMessage1}
          </div>
        </div>
        <div>
          <div style={{ marginTop: 10 }}>Confirm Email</div>

          <TextField
            data-testid="confirm-email-input"
            type={"email"}
            name="confirmEmail"
            id="confirmEmail"
            value={confirmEmail}
            autoComplete="email"
            onChange={(e) => setConfirmEmail(e.target.value)}
          />
          <div style={{ fontSize: 12, color: "red", marginTop: -15 }}>
            {errorMessage2}
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <div style={{ height: 100, width: 100 }}></div>
          <Button
            data-testid="apply-button"
            //isLoading={isLoading}
            type="submit"
            size="lg"
            variant="primary"
            onClick={() => checkEmail()}
          >
            <ButtonText>Apply</ButtonText>
          </Button>
        </div>
        <div style={{ backgroundColor: "blue" }}>
          <Modal
            isOpen={confirmationModalVisible}
            style={modalStyles}
          >
            <div className="text">
              <img
                width={90}
                height={90}
                src={mailIcon}
                alt="mail"
                className="mailIcon"
              />
              <h4 style={{ textAlign: "center" }}>Change Email</h4>
              <div style={{ textAlign: "center" }}>
                Your account and all email communications will be linked to{" "}
                {newEmail}
              </div>
            </div>
            <div className="mail-button">
              <Button
                onClick={() => {
                  setConfirmationModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ margin: "10px" }} />
              <Button
                data-testid="update-button"
                onClick={() => {
                  updateEmail(newEmail);
                }}
              >
                Update
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "change-email",
  screen: ChangeEmail,
  path: "/change-email",
  role: [UserRole.Authenticated],
};
export { ChangeEmail, route };

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  ButtonText,
  SelectField,
} from "../../../../common/components/design-system";
import { Api } from "common/redux/services/base/api";
import { InputFieldIcon } from "common/components/design-system/text-field/input-field-icon/input-field-icon";
import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";

import * as S from "./styles";

export async function saveNewLockId({
  lockId,
  setError,
  setLoading,
  goBack,
  selectedDoor,
}: {
  lockId: string;
  setError: (error: string) => void;
  setLoading: (loading: boolean) => void;
  goBack: () => void;
  selectedDoor: any;
}) {
  if (lockId === "") {
    setError("Please enter a lock ID");
    return;
  }

  setLoading(true);
  try {
    const result = await Api.put(
      "/api/v1/building-management/doors/" +
        // @ts-ignore
        selectedDoor.uuid +
        "/lock-number",
      {
        apadLockNumber: lockId,
      }
    );
    if (result != null) {
      setLoading(false);

      toast("The lock ID was saved successfully.", { type: "success" });
      goBack();
      return Promise.resolve();
    } else {
      setLoading(false);
      toast("Error saving the lock ID.", { type: "error" });
      return Promise.reject();
    }
  } catch (error) {
    setLoading(false);
    toast("Error saving the lock ID.", { type: "error" });
    return Promise.reject();
  }
}

function AddLock({
  door,
  buildingData,
  addNewDoorData,
  setAddNewDoorData,
  onNext,
  goBack,
}: any) {
  const [loading, setLoading] = useState(true);

  const [doorsNames, setDoorsNames] = useState([]);

  const [selectedBuilding, setSelectedBuilding] = useState(buildingData[0]);
  const [selectedDoor, setSelectedDoor] = useState(buildingData[0]?.data ?? []);
  const [lockId, setLockId] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const names = buildingData.map((building: any) => building.name);

    const newSelectedBuilding = buildingData.find((curr: any) => {
      return curr.name == door.buildingName;
    });
    setSelectedBuilding(newSelectedBuilding);

    const doors = buildingData.map((building: any) => building.data);
    const doorsNames = doors.map((door: any) => door.map((d: any) => d));
    const doorsNamesFlat = doorsNames.flat();

    setDoorsNames(doorsNamesFlat);
    const newSelectedDoor = doorsNamesFlat.find((curr: any) => {
      return (
        curr.name == door.name && newSelectedBuilding.name == door.buildingName
      );
    });
    setSelectedDoor(newSelectedDoor);

    setLoading(false);
  }, [buildingData]);

  return (
    <>
      {!loading && (
        <>
          <div
            data-testid="add-lock-container"
            style={{
              padding: "0 24px",
              paddingBottom: 24,
              borderBottomWidth: 1,
              borderColor: "#cbcbcb",
            }}
          >
            <h3 style={{ fontSize: 26, fontWeight: "400" }}>
              {door.buildingName}: {door.name}
            </h3>
          </div>
          <S.ContentContainer
            style={{
              flexDirection: "column",
              backgroundColor: "#FAFBFB",
            }}
          >
            <div style={{ marginTop: 24, flex: 1 }}>
              <div style={{ marginTop: 24 }}>
                <SelectField<any, any>
                  label="Building"
                  icon={DownIcon}
                  name="type"
                  data={[door.buildingName]}
                  selectedValue={door.buildingName}
                  onSelection={() => {}}
                  // data={buildingData}
                  // selectedValue={selectedBuilding}
                  // onSelection={(selected: any) => {
                  //   setSelectedBuilding(selected);
                  // }}
                  // valueExtractor={(item) => item}
                  // labelExtractor={(item) => item.name}
                  // keyExtractor={(item) => item.toString()}
                  valueExtractor={(item) => item}
                  labelExtractor={(item) => item}
                  keyExtractor={(item) => item.toString()}
                  disabled={true}
                />
              </div>

              <div style={{ marginTop: 24 }}>
                <SelectField<any, any>
                  label="Door"
                  icon={DownIcon}
                  name="type"
                  data={[door.name]}
                  selectedValue={door.name}
                  onSelection={() => {}}
                  // data={selectedBuilding?.data ?? []}
                  // selectedValue={selectedDoor}
                  // onSelection={(selected: any) => {
                  //   setSelectedDoor(selected);
                  // }}
                  // valueExtractor={(item) => item}
                  // labelExtractor={(item) => item.name}
                  // keyExtractor={(item) => item?.uuid?.toString()}
                  valueExtractor={(item) => item}
                  labelExtractor={(item) => item}
                  keyExtractor={(item) => item.toString()}
                  disabled={true}
                />
              </div>

              <S.InputContainer>
                <S.InputLabel>Lock ID</S.InputLabel>
                <InputFieldIcon
                  data-testid="lock-id-input"
                  // @ts-ignore
                  onChange={(event: InputText) => {
                    setError("");
                    setLockId(event.target.value);
                  }}
                  value={lockId}
                  error={error.length > 0}
                />
                <div style={{ fontSize: 12, color: "red" }}>{error}</div>
                <h4
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#808080",
                    marginTop: 8,
                  }}
                >
                  Enter the Lock ID manually or scan the QR code on the
                  packaging via mobile app.
                </h4>
              </S.InputContainer>
            </div>
            <div className="bottom-buttons">
              <Button
                size="lg"
                variant="basic"
                onClick={goBack}
              >
                <ButtonText>Cancel</ButtonText>
              </Button>
              <div style={{ width: 20 }} />
              <Button
                data-testid="save-button"
                size="lg"
                variant="primary"
                onClick={() => {
                  saveNewLockId({
                    lockId,
                    setError,
                    setLoading,
                    goBack,
                    selectedDoor,
                  });
                }}
              >
                <ButtonText>Save</ButtonText>
              </Button>
            </div>
          </S.ContentContainer>
        </>
      )}
    </>
  );
}

export { AddLock };

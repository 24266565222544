import * as React from "react";
/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../navigations/Types";
import { TopNav } from "../../../common/components/top-nav";
import { HeaderOption } from "../../../common/components/top-nav/type";
import { Button } from "../../../common/components/design-system";
import { Api } from "../../../common/redux/services/base/api";

import { InputFieldIcon } from "../../../common/components/design-system/text-field/input-field-icon/input-field-icon";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import ResidentsCard from "./components/ResidentsCard/index";
import UserItem from "./components/UserItem";
import { RightSidebarLayout } from "common/components/right-sidebar-layout";
import { AddNewResident } from "./add-new-resident";

import * as S from "./styles";
import StaffCard from "../building-staff/components/StaffCard";

interface User {
  userUuid: string;
  name: string;
  doorAccess: [];
  status: string;
}

interface Building {
  uuid: string;
  name: string;
}

interface ResidentData {
  statusCount: { pending: number; active: number; others: number };
  users: User[];
}

export function Residents() {
  const navigate = useNavigate();
  const theme = useTheme();

  const userOption: HeaderOption = {
    url: "/people",
    title: "People",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [showForm, setShowForm] = useState<string | undefined>();

  const [residentsData, setResidentsData] = useState<ResidentData>(
    {} as ResidentData
  );
  const [filteredResidentsData, setFilteredResidentsData] =
    useState<ResidentData>({} as ResidentData);

  const [guestData, setGuestData] = useState<ResidentData>({} as ResidentData);
  const [filteredGuestsData, setFilteredGuestsData] = useState<ResidentData>(
    {} as ResidentData
  );

  const [selectedBuilding, setSelectedBuilding] = useState({
    name: "All Properties",
    uuid: "*", // * means all buildings
  });
  const [buildings, setBuildings] = useState<Building[]>([
    {
      name: "All Properties",
      uuid: "*", // * means all buildings
    },
  ]);
  const variants: ("guests" | "residents")[] | ("guests" | "residents")[] = [
    "guests",
    "residents",
  ];
  const [currentVariantIndex, setCurrentVariantIndex] = useState(0);

  async function getResidents(): Promise<ResidentData> {
    setLoading(true);
    const result = await Api.post("/api/v1/building-management/users/search", {
      isManager: true,
      permissionLevel: "resident",
    });
    if (result != null) {
      let buildings: Building[] = [];
      const newResidentsData = result.data;
      for (let i = 0; i < newResidentsData.users.length; i++) {
        const user = newResidentsData.users[i];
        for (let j = 0; j < user?.doorAccess.length; j++) {
          const door = user?.doorAccess[j];
          if (door.buildingUuid) {
            const building = buildings.find(
              (building) => building.uuid === door.buildingUuid
            );
            if (!building) {
              buildings.push({
                uuid: door.buildingUuid,
                name: door.buildingName,
              });
            }
          }
        }
      }

      setBuildings([
        {
          name: "All Properties",
          uuid: "*",
        },
        ...buildings,
      ]);

      setSelectedBuilding({
        name: "All Properties",
        uuid: "*",
      });
      setFilteredResidentsData(newResidentsData);
      setResidentsData(newResidentsData);
      setLoading(false);
      return Promise.resolve(result.data.rows);
    } else {
      setLoading(false);
      return Promise.reject();
    }
  }
  async function getGuests(): Promise<ResidentData> {
    setLoading(true);
    const result = await Api.post("/api/v1/building-management/users/search", {
      isManager: true,
      permissionLevel: "guest",
    });
    if (result != null) {
      let buildings: Building[] = [];
      const newGuestData = result.data;
      for (let i = 0; i < newGuestData.users.length; i++) {
        const user = newGuestData.users[i];
        for (let j = 0; j < user?.doorAccess.length; j++) {
          const door = user?.doorAccess[j];
          if (door.buildingUuid) {
            const building = buildings.find(
              (building) => building.uuid === door.buildingUuid
            );
            if (!building) {
              buildings.push({
                uuid: door.buildingUuid,
                name: door.buildingName,
              });
            }
          }
        }
      }

      setBuildings([
        {
          name: "All Properties",
          uuid: "*",
        },
        ...buildings,
      ]);

      setSelectedBuilding({
        name: "All Properties",
        uuid: "*",
      });
      setFilteredGuestsData(newGuestData);
      setGuestData(newGuestData);
      setLoading(false);
      return Promise.resolve(result.data.rows);
    } else {
      setLoading(false);
      return Promise.reject();
    }
  }

  useEffect(() => {
    getGuests();
    getResidents();
  }, []);

  useEffect(() => {
    if (searchText) {
      let filtered = [];
      for (let i = 0; i < residentsData?.users?.length; i++) {
        if (
          residentsData.users[i]?.name
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          // @ts-ignore
          filtered.push(residentsData.users[i]);
        }
      }

      setFilteredResidentsData({ ...residentsData, users: filtered });
    } else {
      setFilteredResidentsData(residentsData);
    }
  }, [searchText, residentsData, guestData]);

  const renderSubcomponent = useCallback(() => {
    switch (showForm) {
      case "add_people_form":
        return (
          <AddNewResident
            onClose={() => {
              setShowForm(undefined);
              getGuests();
              getResidents();
            }}
          />
        );

      default:
        return undefined;
    }
  }, [showForm]);

  const HEADER = useMemo(() => {
    return (
      <S.HeaderContainer data-testid="residents-header">
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            // justifyContent: "space-between",
            gap: 24,
            alignItems: "center",
          }}
        >
          {variants.map((variant, index) => {
            return (
              <div
                onClick={() => {
                  setCurrentVariantIndex(index);
                }}
                data-testid={`people-card-${variant}`}
              >
                <StaffCard
                  // @ts-ignore
                  staffData={
                    index == 0 ? filteredGuestsData : filteredResidentsData
                  }
                  variant={variant}
                  selected={index === currentVariantIndex}
                />
              </div>
            );
          })}
        </div>

        <RightSidebarLayout
          visible={!!showForm}
          onClose={() => setShowForm(undefined)}
        >
          {renderSubcomponent()}
        </RightSidebarLayout>
      </S.HeaderContainer>
    );
  }, [searchText, residentsData, guestData, showForm, currentVariantIndex]);

  const data = useMemo(() => {
    return currentVariantIndex === 0
      ? filteredGuestsData.users
      : filteredResidentsData.users;
  }, [currentVariantIndex, filteredGuestsData, filteredResidentsData]);

  return (
    <>
      <div
        style={{
          marginTop: 20,
        }}
      >
        <TopNav navigationOption={userOption} />
      </div>

      {HEADER}
      <S.ResidentsPage data-testid="residents-page">
        <div
          data-testid="content-container"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              width: "100%",
              marginBottom: 24,
            }}
          >
            <InputFieldIcon
              data-testid="search-input"
              style={{
                width: "286px",
              }}
              prefixIcon={() => <SearchIcon />}
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />

            <Button
              data-testid="add-resident-button"
              variant="basic"
              size="lg"
              style={{
                width: "54px",
                fontSize: "24px",
                color: theme.color.icon.hovered,
              }}
              onClick={() => {
                setShowForm("add_people_form");
              }}
            >
              +
            </Button>
          </div>

          {data &&
            data.map((user, index) => {
              return (
                <UserItem
                  user={user}
                  key={index}
                />
              );
            })}
        </div>
      </S.ResidentsPage>
    </>
  );
}
const route: AppRoute = {
  name: "People",
  screen: Residents,
  path: "/people",
  role: [UserRole.Authenticated],
};
export { route };

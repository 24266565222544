import { Door } from "../../../../common/redux/services/model";
import "./door-list.scss";
import { DoorComponent } from "../door-component/door";

interface DoorListPrams {
  doors: Door[];
  buildingName: string;
  onClick: (door: Door) => void;
}
function DoorList({ doors, buildingName, onClick }: DoorListPrams) {
  return (
    <div className="door-list-wrapper">
      {doors.map((door) => (
        // TODO: Check if every door object changed from "id" to "uuid"
        <DoorComponent
          // @ts-ignore
          key={door.uuid}
          door={door}
          buildingName={buildingName}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
export { DoorList };

import styled from "styled-components";

export const StatusText = styled.h2<{ selected: boolean }>`
  font-size: 18px;
  font-weight: 400;
  color: white;
  ${(props) =>
    !props.selected &&
    `
    font-weight: 300;
    color: #ffffffe5;
  `}
`;

export const ResidentStatusText = styled(StatusText)<{ selected: boolean }>`
  font-size: 32px;
  ${(props) =>
    !props.selected &&
    `
    font-weight: 300;
    color: #ffffffe5;
  `}
`;

export const StatusContainer = styled.div``;

export const Container = styled.div<{ selected: boolean }>`
  border-radius: 8px;
  overflow: hidden;
  margin: 24px 0px;
  max-width: 400px;
  width: 100vh;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  &:hover {
    transform: scale(1.01);
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    ${StatusText}, ${ResidentStatusText}, ${StatusContainer} {
      transform: scale(1);
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0);
    }
  }
  ${(props) =>
    props.selected &&
    `
  `}
`;

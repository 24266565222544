import styled from "styled-components";

export const ItemContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
`;

export const ItemName = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: #202020;
  line-height: 24px;
`;

export const InfoText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export const PermissionText = styled(InfoText)`
  color: #0f62fe;
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const AccessTypeButton = styled.button`
  margin: 16px 4px 8px 0px;
  /* hides default styling */
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

export const AccessTypeText = styled(InfoText)`
  color: #0f62fe;
`;

export const DateText = styled(InfoText)`
  color: #202020;
`;

export const InvitedByText = styled(InfoText)`
  color: #707070;
`;

export const AccessTypeTitle = styled(InfoText)`
  font-size: 24px;
  color: black;
`;
export const AccessTypeDescription = styled(InfoText)`
  font-size: 16px;
  color: black;
  margin-top: 4px;
`;

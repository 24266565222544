import { useCallback, useState } from "react";

import {
  DoorResponse,
  GuestAccessDate,
  GuestInfo,
} from "../../../../common/redux/services/model";
import { Button, H4 } from "../../../../common/components/design-system";
import { StepIndicator } from "../../../../common/components/step-indicator";

import { GuestSelectDoorsFormComponent } from "./components/guest-select-doors-form/guest-select-doors-form";
import { GuestInfoFormComponent } from "./components/guest-info-form/guest-info-form";
import { DoorAccessWrapperComponent } from "./components/door-access-wrapper/door-access-wrapper";
import "./add-guest.scss";
import { AddGuestConfirmation } from "./components/add-guest-confirmation/add-guest-confirmation";

interface AddGuestProps {
  onClose: () => void;
  defaultActiveStep?: number;
}

function AddGuest({ onClose, defaultActiveStep }: AddGuestProps) {
  const [activeStep, setActiveStep] = useState(defaultActiveStep ?? 0);
  const [guestInfo, setGuestInfo] = useState<GuestInfo | undefined>();
  const [doorList, setDoorList] = useState<DoorResponse[]>();
  const [doorAccessDate, setDoorAccessDate] = useState<
    GuestAccessDate | undefined
  >();

  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <GuestInfoFormComponent
            guestInfo={guestInfo!}
            onNext={(params) => {
              setGuestInfo({ ...params, type: "guest" });
              setActiveStep(activeStep + 1);
            }}
            onCancel={onClose}
          />
        );
      case 1:
        return (
          <GuestSelectDoorsFormComponent
            selectedDoorList={doorList!}
            onNext={(doorList) => {
              setDoorList(doorList);
              setActiveStep(activeStep + 1);
            }}
            goBack={(doorList) => {
              setDoorList(doorList);
              setActiveStep(activeStep - 1);
            }}
          />
        );
      // istanbul ignore next
      case 2:
        return (
          <DoorAccessWrapperComponent
            accessFormVariant="add_guest"
            title="Access"
            doorAccessDate={doorAccessDate!}
            goBack={(doorAccessDate) => {
              setDoorAccessDate(doorAccessDate);
              setActiveStep(activeStep - 1);
            }}
            onDelete={() => {}}
            onSubmit={(doorAccessDate) => {
              setDoorAccessDate(doorAccessDate);
              setActiveStep(activeStep + 1);
            }}
            offsetShortName={doorList![0].offsetShortName}
          />
        );

      // istanbul ignore next
      case 3:
        return (
          <AddGuestConfirmation
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
            doorAccessInfo={doorAccessDate!}
            guestInfo={guestInfo!}
            doorList={doorList!}
            onClose={onClose}
            onDelete={(doorAccess: any) => {
              const newDoorList = doorList!.filter(
                (door) => door.doorUuid !== doorAccess.doorUuid
              );
              setDoorList(newDoorList);
            }}
          />
        );
    }
  }, [activeStep, doorList]);

  return (
    <div className="common-form-container">
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4>Add a Guest</H4>
          </div>
          <div className="form-cancel">
            <Button
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <StepIndicator
          style={{ marginTop: "14px", marginBottom: "49px" }}
          totalSteps={4}
          activeStep={activeStep}
        />
        {renderSubComponent()}
      </div>
    </div>
  );
}
export { AddGuest };

import { useEffect, useMemo, useState } from "react";
import { InputFieldIcon } from "common/components/design-system/text-field/input-field-icon/input-field-icon";

import {
  Button,
  ButtonText,
  SelectField,
} from "../../../../common/components/design-system";
import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { E164Number } from "libphonenumber-js";
import "./styles.scss";

import * as S from "./styles";
import { Api } from "common/redux/services/base/api";

function FillStaffInfo({
  addNewStaffData,
  setAddNewStaffData,
  onNext,
  goBack,
}: any) {
  const [staffData, setStaffData] = useState({
    name: addNewStaffData.name,
    email: addNewStaffData.email,
    phone: addNewStaffData.phone,
    role: addNewStaffData.role,
  });
  const [allowedToNext, setAllowedToNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setAddNewStaffData({ ...addNewStaffData, ...staffData });
  }, [staffData]);

  // TO DO: test this function
  // istanbul ignore next
  const nextButton = () => {
    //@ts-ignore
    if (organizationId !== userOrganizationId && userOrganizationId !== "") {
      setErrorMessage("* User is alredy asigned to a different organization");
    } else if (staffData.name === "") {
      setErrorMessage("* Name cannot be empty");
    } else if (staffData.email === "") {
      setErrorMessage("* Email cannot be empty");
    } else if (staffData.phone === "") {
      setErrorMessage("* Phone cannot be empty");
    } else if (addNewStaffData.role == "") {
      setAddNewStaffData({
        ...addNewStaffData,
        role: "building-staff",
      });
      onNext();
    } else {
      onNext();
    }
  };

  const [permissions, setPermissions] = useState<any>([]);

  async function getPermissions(): Promise<any> {
    const result = await Api.get("/api/v1/users/initial-data");
    if (result != null) {
      setPermissions(result.data);
      return Promise.resolve(result.data);
    } else {
      return Promise.reject();
    }
  }

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPermissions().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (permissions?.permissionLevels) {
      setPermissions(permissions);
    } else {
      setPermissions({ permissionLevels: [] });
    }
  }, [permissions]);

  const roleOptions = useMemo(() => {
    if (permissions.length === 0) return [];

    let options = [
      { value: "building-staff", label: "Staff" },
      { value: "building-manager", label: "Manager" },
    ];

    if (permissions?.permissionLevels.includes("organization-manager")) {
      // options.push("Organization Staff");
      options.push({ value: "organization-manager", label: "Org. Manager" });
    }

    return options;
  }, [permissions]);

  const [emailFilled, setEmailFilled] = useState(false);
  const [nameEditable, setNameEditable] = useState(false);
  const [phoneEditable, setPhoneEditable] = useState(false);
  const [roleEditable, setRoleEditable] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [userOrganizationId, setUserOrganizationId] = useState("");

  // istanbul ignore next
  async function searchUser(email: string) {
    const UPDATE_TIME = 200;
    setEmailFilled(false);
    const result = await Api.post(
      // @ts-ignore
      "/api/v1/building-management/building-users/search",
      {
        buildingIds: ["*"],
        permissions: [
          "building-manager",
          "building-staff",
          "organization-manager",
        ],
        allowedEmail: email,
      }
    );
    try {
      if (
        result != null &&
        result.data &&
        result.data.users?.length > 0
        // TO DO: verify if this behavior is correct
        // result.data.users[0].status !== "pending"
      ) {
        const user = result.data.users[0];

        let role = { value: "building-staff", label: "Staff" };
        for (let i = 0; i < user.buildingAccess.length; i++) {
          const permission = user.buildingAccess[i].permission;
          if (permission === "building-manager") {
            role = { value: "building-manager", label: "Manager" };
            break;
          }
        }

        setStaffData({
          ...staffData,
          name: user.name,
          phone: user.phoneNumber,
          //@ts-ignore
          buildingAccess: user.buildingAccess,
          role: role.value,
          status: user.status,
        });

        if (result.data.organizationUuid) {
          setOrganizationId(result.data.organizationUuid);
        } else {
          setOrganizationId("");
        }

        if (user.buildingAccess && user.buildingAccess[0]?.organizationUuid) {
          setUserOrganizationId(user.buildingAccess[0].organizationUuid);
        } else {
          setUserOrganizationId("");
        }

        setNameEditable(false);
        setPhoneEditable(false);
        setRoleEditable(user?.buildingAccess?.length > 0 ? false : true);
      } else {
        setNameEditable(true);
        setPhoneEditable(true);
        setRoleEditable(true);
      }

      setTimeout(() => {
        setEmailFilled(true);
      }, UPDATE_TIME);

      return Promise.resolve();
    } catch (error) {
      console.error("Error in handling result:", error);
      // Handle the error here or rethrow it if needed
      return Promise.reject(error);
    }
  }

  useEffect(() => {
    if (staffData.email.match(/^[\w.+%-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/)) {
      searchUser(staffData.email);
    } else {
      setEmailFilled(false);
      setStaffData({ ...staffData, name: "", phone: "", role: "" });
    }
  }, [staffData.email]);

  return (
    <div data-testid="fill-staff-info-container">
      <S.ContentContainer
        style={{
          flexDirection: "column",
        }}
      >
        <S.InputContainer>
          <S.InputLabel>Email</S.InputLabel>
          <InputFieldIcon
            data-testid="email-input"
            // @ts-ignore
            onChange={(event: InputText) => {
              setErrorMessage("");
              setStaffData({
                ...staffData,
                email: event.target.value,
                name: "",
                phone: "",
              });
            }}
            value={staffData.email}
          />
        </S.InputContainer>

        {emailFilled && (
          <>
            <S.InputContainer>
              <S.InputLabel>Name</S.InputLabel>
              <InputFieldIcon
                data-testid="name-input"
                // @ts-ignore
                onChange={(event: InputText) => {
                  setErrorMessage("");
                  setStaffData({ ...staffData, name: event.target.value });
                }}
                value={staffData.name}
                disabled={!nameEditable}
              />
            </S.InputContainer>

            <S.InputContainer>
              <S.InputLabel>Phone</S.InputLabel>
              <div className="phone-input-wrapper">
                <PhoneInput
                  data-testid="phone-input"
                  type="tel"
                  placeholder="Enter phone number"
                  value={staffData.phone}
                  flagWidth={20}
                  flagHeight={20}
                  onChange={(value?: E164Number) => {
                    if (value) {
                      setStaffData({ ...staffData, phone: value });
                    } else {
                      setStaffData({ ...staffData, phone: "" });
                    }
                  }}
                  disabled={!phoneEditable}
                />
              </div>
            </S.InputContainer>

            <div style={{ marginTop: 24 }}>
              <SelectField
                data-testid="role-input"
                label="Role"
                icon={DownIcon}
                name="type"
                data={roleOptions}
                selectedValue={staffData.role}
                onSelection={(selected: any) => {
                  setStaffData({ ...staffData, role: selected });
                }}
                valueExtractor={(item) => item.value}
                labelExtractor={(item) => item.label}
                keyExtractor={(item) => item.toString()}
                disabled={!roleEditable}
              />
            </div>
          </>
        )}
      </S.ContentContainer>

      <div>
        <h2 style={{ fontSize: 12, color: "red", marginTop: 10 }}>
          {errorMessage}
        </h2>
      </div>

      <div className="bottom-buttons">
        <Button
          size="lg"
          variant="basic"
          onClick={() => {
            goBack(goBack);
          }}
        >
          <ButtonText>Cancel</ButtonText>
        </Button>
        <div style={{ width: 20 }} />
        <Button
          data-testid="next-button"
          size="lg"
          variant="primary"
          onClick={nextButton}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      </div>
    </div>
  );
}

export { FillStaffInfo };

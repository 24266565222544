import { Api } from "./base/api";

export interface Door {
  name: string;
  doorAccessUuid: string;
  doorUuid: string;
  doorName: string;
  doorType: string;
  permissionLevel: string;
  startedAt: string;
  endedAt: string;
}

export interface DoorAccesses {
  private: Door[];
  shared: Door[];
}

export interface Building {
  buildingUuid: string;
  buildingName: string;
  doorAccess: DoorAccesses;
}

export async function getStaffData(userId: string) {
  const result = await Api.get("/api/v1/building-management/users/" + userId);
  if (result != null) {
    const user = result.data;
    const buildings = result.data.doorAccesses;
    buildings.forEach((building: Building) => {
      building.doorAccess.private.forEach((door: Door) => {
        door.name = door.doorName;
      });
      building.doorAccess.shared.forEach((door: Door) => {
        door.name = door.doorName;
      });
    });
    const doorAccesses = await buildings.map((building: any) => {
      building.data = building.doorAccess.private.concat(
        building.doorAccess.shared
      );
      building.name = building.buildingName;
      return building;
    });

    const residents = user;
    const staff = doorAccesses;
    return Promise.resolve({ staff, residents });
  } else {
    return Promise.reject();
  }
}

export async function getBuildingStaffData(userId: string) {
  const result = await Api.get(
    "/api/v1/building-management/building-users/" + userId
  );
  if (result != null) {
    const user = result.data;
    const buildings = result.data.buildingAccess;
    buildings.forEach((building: any) => {
      building.doorAccess.private.forEach((door: Door) => {
        door.name = door.doorName;
      });
      building.doorAccess.shared.forEach((door: Door) => {
        door.name = door.doorName;
      });
    });
    const doorAccesses = await buildings.map((building: any) => {
      building.data = building.doorAccess.private.concat(
        building.doorAccess.shared
      );
      building.name = building.buildingName;
      return building;
    });

    const staff = doorAccesses;
    const residents = user;
    return Promise.resolve({ staff, residents });
  } else {
    return Promise.reject();
  }
}

export async function getOrganizationUserData(userId: string) {
  const result = await Api.get(
    "/api/v1/organization-management/organization-users/" + userId
  );

  if (result != null) {
    const user = result.data;
    const organizations = result.data.organizationAccess;

    const organizationsData = organizations.map((organization: any) => {
      organization.data = [];
      organization = {
        ...organization,
        doorAccess: {
          private: [],
          shared: [],
        },
      };
      organization.name = organization.organizationName;
      return organization;
    });

    const staff = organizationsData;
    const residents = user;
    return Promise.resolve({ staff, residents });
  } else {
    return Promise.reject();
  }
}

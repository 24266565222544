/* eslint-disable react/no-direct-mutation-state */
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../../navigations/Types";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";

import "./contact.scss";
//   import { AccessList } from "./components/access-list/access-list";

interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  mobilePhone: string;
}

function Contact() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();

  const userOption: HeaderOption = {
    url: "/contact",
    title: "Contact",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <TopNav navigationOption={userOption} />
      </div>
      <div className="guest-profile-wrapper">
        <div
          data-testid={"support-text-1"}
          style={{ fontSize: 19, fontWeight: "400" }}
        >
          For support with issues with your residence or building access, please
          reach out to your building management
        </div>
        <div
          data-testid={"support-text-2"}
          style={{ fontSize: 19, fontWeight: "400", marginTop: 10 }}
        >
          For support with issues or concerns related to the APAD Access App,
          please contact APAD technical support at{" "}
          <a
            href="mailto:support@apadaccess.com"
            style={{ fontSize: 19, fontWeight: "400" }}
          >
            support@apadaccess.com
          </a>
        </div>
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "Contact",
  screen: Contact,
  path: "/contact",
  role: [UserRole.Authenticated],
};
export { Contact, route };

import React from "react";
import styled, { useTheme } from "styled-components";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";

import { CaptionText, LabelText } from "../typography/typography";
import { Button } from "../button/button";

import {
  MuiTextField,
  MuiTextFieldInputProps,
} from "./input-field-design/input-field-design";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import TimePicker from "react-time-picker";
import "./date-time.css";
import "react-clock/dist/Clock.css";

const ValidationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export type DateVariant = "time" | "date" | "datetime";

export interface DateTimeFieldProps
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      "ref"
    >,
    MuiTextFieldInputProps {
  label?: string;
  caption?: string;
  note?: string;
  errorMessage?: string;
  dateVariant: DateVariant;
  onValueChange: (datetime: any) => void;
  minDate?: Date;
  dateDisabled?: boolean;
  valueData?: Date;
  testId?: string;
}

function DateTimeDelicate({
  label,
  errorMessage,
  caption,
  note,
  dateVariant,
  onValueChange,
  minDate,
  dateDisabled,
  valueData,
  testId,
  ...rest
}: DateTimeFieldProps) {
  const theme = useTheme();
  const [value, setValue] = React.useState<any>(valueData ?? null);

  return (
    <div
      data-testid={testId ?? "datetime-container"}
      className="form-group text-field-wrapper"
    >
      <div className="text-field-title-wrapper">
        <LabelText color={theme.color.text.subdued}>{label}</LabelText>
        {caption && (
          <Button
            hidden={!caption}
            type="button"
            variant="plain"
          >
            {caption}
          </Button>
        )}
      </div>

      <LocalizationProvider dateAdapter={AdapterMoment}>
        {dateVariant === "date" && (
          // @ts-ignore
          <DatePicker<Date, Moment>
            className="date-picker"
            data-testid={"date-input"}
            disabled={dateDisabled}
            inputFormat="MMM DD, yyyy"
            minDate={minDate ? moment(minDate) : undefined}
            value={value}
            onChange={(newValue) => {
              setValue(newValue?.toDate() ?? null);
              onValueChange(newValue?.toDate() ?? null);
            }}
            renderInput={({ error, InputProps, ...params }) => (
              <MuiTextField
                error={rest.error}
                {...params}
                InputProps={{
                  ...InputProps,
                  endAdornment: InputProps?.endAdornment,
                  startAdornment: null,
                }}
                variant="outlined"
              />
            )}
          />
        )}

        {dateVariant === "time" && (
          <TimePicker
            className="time-picker"
            data-testid={"time-input"}
            format={"hh:mm a"}
            disableClock={true}
            clearIcon={null}
            value={value}
            // istanbul ignore next
            onChange={(newValue: any) => {
              const newTime = moment(newValue, "HH:mm").format();
              setValue(new Date(newTime) ?? null);
              onValueChange(new Date(newTime) ?? null);
            }}
          />
        )}

        {/* {dateVariant === "datetime" && (
          <DateTimePicker<Date, Moment>
            data-testid={"datetime-input"}
            disabled={dateDisabled}
            inputFormat="MMM D, yyyy hh:mm A"
            minDateTime={
              minDate ? moment(minDate).add(1, "minutes") : undefined
            }
            value={value}
            onChange={(newValue) => {
              setValue(newValue?.toDate() ?? null);
              onValueChange(newValue?.toDate() ?? null);
            }}
            renderInput={({ error, InputProps, ...params }) => (
              <MuiTextField
                error={rest.error}
                {...params}
                InputProps={{
                  ...InputProps,
                  endAdornment: InputProps?.endAdornment,
                  startAdornment: null,
                }}
                variant="outlined"
              />
            )}
          />
        )} */}
      </LocalizationProvider>

      <div className="text-field-bottom-text-wrapper">
        {!rest.error ? (
          <CaptionText color={theme.color.text.subdued}>{note}</CaptionText>
        ) : (
          <ValidationWrapper>
            <WarningIcon fill={theme.color.icon.critical} />
            <CaptionText color={theme.color.text.critical}>
              Please, insert a valid date
            </CaptionText>
          </ValidationWrapper>
        )}
      </div>
    </div>
  );
}

DateTimeDelicate.defaultProps = {
  label: undefined,
  errorMessage: undefined,
  caption: undefined,
  note: undefined,
  minDate: undefined,
  dateDisabled: false,
  valueData: null,
};
const DateTime = React.memo(DateTimeDelicate);
export { DateTime };

import * as React from "react";
import { SelectBuilding } from "../add-new-staff/select-building";

import * as S from "./styles";
import { Button, ButtonText } from "common/components/design-system";
import { Api } from "common/redux/services/base/api";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import i18n from "i18n-js";

function EditBuildingsConfirmation({
  user,
  staffData,
  selectedBuildings,
  onClose,
  onGoBack,
}: any) {
  const [buildingsAdded, setBuildingsAdded] = React.useState([]);
  const [buildingsRemoved, setBuildingsRemoved] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const newBuildingsAdded = selectedBuildings.filter((building: any) => {
      return !staffData.some((buildingAccess: any) => {
        return buildingAccess.buildingUuid == building.buildingUuid;
      });
    });

    const newBuildingsRemoved = staffData.filter(
      (buildingAccess: any) =>
        !selectedBuildings.some((building: any) => {
          return buildingAccess.buildingUuid == building.buildingUuid;
        })
    );

    setBuildingsAdded(newBuildingsAdded);
    setBuildingsRemoved(newBuildingsRemoved);
  }, [staffData, selectedBuildings]);
  interface Building {
    buildingUuid: string;
  }

  // TO DO: refactor this to another file and test it
  // istanbul ignore next
  async function editBuildings(): Promise<void> {
    setLoading(true);

    try {
      const buildingList = selectedBuildings.map(
        (building: any) => building.buildingUuid
      );

      const result = await Api.post(
        "/api/v1/building-management/building-users/splice-permissions",
        {
          buildingList,
          inviteEmail: user.email,
          name: user.name,
          permissionLevel: "building-staff",
        }
      );

      if (!result) {
        throw new Error(i18n.t("message.no_result_from_api"));
      }

      onClose();
      return Promise.resolve();
    } catch (error) {
      console.error("Error in editBuildings:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const BuildingItem = ({ building }: { building: any }) => {
    return (
      <S.BuildingContainer style={{ flex: 0, marginTop: 0 }}>
        <S.IconContainer>
          <BuildingIcon />
        </S.IconContainer>
        <div style={{ flexDirection: "column", flex: 1, marginLeft: 16 }}>
          <S.BuildingName>{building.buildingName}</S.BuildingName>
        </div>
      </S.BuildingContainer>
    );
  };

  return (
    <>
      <S.Container style={{ padding: 0, flex: 1, display: "flex" }}>
        <S.Title>Removed</S.Title>
        {buildingsRemoved.map((building) => {
          return <BuildingItem building={building} />;
        })}
        {buildingsRemoved.length == 0 && (
          <S.EmptyWarning>No buildings have been removed</S.EmptyWarning>
        )}
        <S.Title>Added</S.Title>
        {buildingsAdded.map((building) => {
          return <BuildingItem building={building} />;
        })}
        {buildingsAdded.length == 0 && (
          <S.EmptyWarning>No buildings have been added</S.EmptyWarning>
        )}
      </S.Container>
      <div style={{ flex: 1 }} />
      <div className="bottom-buttons">
        <Button
          size="lg"
          variant="basic"
          onClick={onGoBack}
        >
          <ButtonText>Previous</ButtonText>
        </Button>
        <div style={{ width: 20 }} />
        <Button
          data-testid="next-button"
          size="lg"
          variant="primary"
          // @ts-ignore
          onClick={editBuildings}
        >
          <ButtonText>Confirm</ButtonText>
        </Button>
      </div>
    </>
  );
}

export { EditBuildingsConfirmation };

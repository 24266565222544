import { useCallback, useState } from "react";
import { useTheme } from "styled-components";
import { Button, H4 } from "../../../../common/components/design-system";
import { StepIndicator } from "../../../../common/components/step-indicator";
import { SelectBuilding } from "./select-building";
import { FillDoorInfo } from "./fill-door-info";
import { DoorInfoConfirmation } from "./door-info-confirmation";

interface AddDoorProps {
  buildingData: any;
  onClose: () => void;
}
function AddNewDoor({ buildingData, onClose }: AddDoorProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [addNewDoorData, setAddNewDoorData] = useState({
    currentModule: "AddNewDoor",
    building: {
      locality: "",
      administrativeArea: "",
      address1: "",
      buildingName: "",
      buildingUuid: "",
      floors: [],
    },
    floor: "",
    doorName: "",
    doorType: "Private",
    lockId: "",
  });

  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <SelectBuilding
            buildingData={buildingData}
            addNewDoorData={addNewDoorData}
            setAddNewDoorData={setAddNewDoorData}
            onNext={() => {
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              onClose();
            }}
          />
        );
      case 1:
        return (
          <FillDoorInfo
            addNewDoorData={addNewDoorData}
            setAddNewDoorData={setAddNewDoorData}
            onNext={() => {
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
          />
        );
      case 2:
        return (
          <DoorInfoConfirmation
            addNewDoorData={addNewDoorData}
            setAddNewDoorData={setAddNewDoorData}
            onNext={() => {
              onClose();
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
          />
        );
    }
  }, [activeStep, isLoading]);

  return (
    <div
      data-testid={"add-door-container"}
      className="common-form-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>Add Door</H4>
          </div>
          <div className="form-cancel">
            <Button
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <StepIndicator
          style={{ marginTop: "14åpx", marginBottom: "49px" }}
          totalSteps={3}
          activeStep={activeStep}
        />
        {renderSubComponent()}
      </div>
    </div>
  );
}

export { AddNewDoor };

/* eslint-disable react/no-direct-mutation-state */
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../navigations/Types";
import { TopNav } from "../../../common/components/top-nav";
import { HeaderOption } from "../../../common/components/top-nav/type";
import { NavLink } from "../../../common/components/design-system";

import "./support.scss";

function Support() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();

  const userOption: HeaderOption = {
    url: "/support",
    title: "Support",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  const textStyle = {
    color: "black",
    fontSize: 20,
    fontWeight: 400,
    marginTop: 20,
  };

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <TopNav navigationOption={userOption} />
      </div>
      <div className="guest-profile-wrapper">
        <NavLink
          data-testid={"contact"}
          key="Contact"
          to="/contact"
          style={textStyle}
        >
          Contact
        </NavLink>

        <NavLink
          data-testid={"privacy-policy"}
          key="PrivacyPolicy"
          to="/privacy_policy"
          style={textStyle}
        >
          Privacy Policy
        </NavLink>

        <NavLink
          data-testid={"terms-of-service"}
          key="TermsOfService"
          to="/terms_of_service"
          style={textStyle}
        >
          Terms Of Service
        </NavLink>

        <NavLink
          data-testid={"deactivate-account"}
          key="DeactivateAccount"
          to="/deactivate_account"
          style={textStyle}
        >
          Deactivate Account
        </NavLink>
      </div>
    </div>
  );
}

const route: AppRoute = {
  name: "Support",
  screen: Support,
  path: "/support",
  role: [UserRole.Authenticated],
};

export { Support, route };

import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { GuestDetail } from "../../../../common/redux/services/model";
import { Button, H4 } from "../../../../common/components/design-system";

//import "./edit-guest.scss";
import { EditGuestInfoFormComponent } from "./components/edit-guest-info-form-component/edit-guest-info-form-component";
import { Api } from "common/redux/services/base/api";
import { LocalStorage, Session } from "../../../../common/service";
import { Constants } from "../../../../assets/constants";

import { useNavigate } from "react-router-dom";

interface EditGuestProps {
  residentData: GuestDetail;
  onClose: () => void;
}

function EditResident({ onClose, residentData }: EditGuestProps) {
  const theme = useTheme();
  const [guestInfo, setGuestInfo] = useState<GuestDetail | undefined>(
    residentData
  );

  return (
    <div
      data-testid="edit-profile"
      className="common-form-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>Edit Profile</H4>
          </div>
          <div className="form-cancel">
            <Button
              data-testid="cancel-button"
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <EditGuestInfoFormComponent
          guestInfo={guestInfo!}
          onCancel={onClose}
        />
      </div>
    </div>
  );
}
export { EditResident };

import * as React from "react";
import moment from "moment";

import * as S from "./styles";
import { ReactComponent as DoorIcon } from "assets/icons/door.svg";
import { Box } from "@mui/system";
import { ConfirmationModal } from "common/components/ConfirmationModal/ConfirmationModal";
import { accessTypesInfo } from "constants/accessTypeInfo";

interface DoorItemProps {
  door: any;
}

function DoorItem({ door }: DoorItemProps) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const dateFormatted = moment(door.startedAt).format("MMM DD, YYYY");

  const formattedName = door.permissionLevel.replace(
    /(-|^)([^-]?)/g,
    // @ts-ignore
    (_, prep, letter) => {
      return (prep && " ") + letter.toUpperCase();
    }
  );

  const accessType = accessTypesInfo.find(
    (accessTypeInfo) => accessTypeInfo.value === door.permissionLevel
  );

  return (
    <>
      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: "black",
          marginLeft: 32, //half of the icon width
        }}
        data-testid="door-item-divider"
      />
      <S.ItemContainer data-testid="door-item-container">
        <div
          style={{
            borderRadius: 32,
            marginRight: 16,
            width: 64,
            height: 64,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid",
          }}
        >
          <DoorIcon />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <S.ItemName>{door.resourceName}</S.ItemName>
          <S.DateText>
            <S.PermissionText
              data-testid="door-item-permission-level"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {formattedName}
            </S.PermissionText>{" "}
            since {dateFormatted}
          </S.DateText>
          <S.InvitedByText>Invited by {door.creatorName}</S.InvitedByText>
        </div>
      </S.ItemContainer>
      <ConfirmationModal
        title={formattedName}
        description={accessType?.description ?? ""}
        confirmText="Got it!"
        visible={modalVisible}
        onConfirm={() => {
          setModalVisible(false);
        }}
        onCancel={() => {
          setModalVisible(false);
        }}
        variant="confirm-only"
      />
    </>
  );
}

export { DoorItem };

/* eslint-disable react/no-direct-mutation-state */
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../../navigations/Types";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";

import "./deactivateAccount.scss";
//   import { AccessList } from "./components/access-list/access-list";

interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  mobilePhone: string;
}

function DeactivateAccount() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();

  const userOption: HeaderOption = {
    url: "/deactivate_account",
    title: "Deactivate Account",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  return (
    <div data-testid={"deactivate-account"}>
      <div style={{ marginTop: 20 }}>
        <TopNav navigationOption={userOption} />
      </div>
      <div className="guest-profile-wrapper">
        <div style={{ fontSize: 19, fontWeight: "400", marginTop: 10 }}>
          Please contact{" "}
          <a
            href="mailto:support@apadaccess.com"
            style={{ fontSize: 19, fontWeight: "400" }}
          >
            support@apadaccess.com
          </a>{" "}
          to initiate account deactivation.
        </div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "500",
            fontStyle: "italic",
            color: "#6D7175",
          }}
        >
          By deactivating your account, all your data will be deleted including,
          personal data, historical activity, access you have granted, etc.{" "}
        </div>
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "DeactivateAccount",
  screen: DeactivateAccount,
  path: "/deactivate_account",
  role: [UserRole.Authenticated],
};
export { DeactivateAccount, route };

import { Middleware } from "@reduxjs/toolkit";

import { guestMiddleware, guestReducer } from "./guest.service";

const reducers = {
  guest: guestReducer,
};
const middlewares: Middleware[] = [guestMiddleware];

export { reducers, middlewares };

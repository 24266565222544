import moment from "moment";
import { Api } from "./base/api";
import i18n from 'i18n-js';

interface StaffInviteData {
  buildings: { buildingUuid: string }[];
  role: string;
  email: string;
  name: string;
  phone: string;
}

interface Organization {
  organizationUuid: string;
}

export async function inviteStaff(
  data: StaffInviteData,
  hasOrganizationPermissions = false,
  organizations: Organization[] = []
): Promise<any> {
  const buildingList = data.buildings.map((building) => building.buildingUuid);
  const role = data.role;

  try {
    const apiUrl = hasOrganizationPermissions
      ? "/api/v1/organization-management/organization-users/invites"
      : "/api/v1/building-management/building-users/splice-permissions";

    const requestBody = {
      buildingList: hasOrganizationPermissions ? undefined : buildingList,
      organizationList: hasOrganizationPermissions
        ? [organizations[1]?.organizationUuid]
        : undefined,
      inviteEmail: data.email,
      name: data.name,
      phoneNumber: data.phone,
      permissionLevel: role,
      notifyAllowedViaEmail: true,
      expiresInHours: 48,
    };

    const result = await Api.post(apiUrl, requestBody);

    if (!result) {
      throw new Error(i18n.t("message.no_result_from_api"));
    }
    return Promise.resolve(result);
  } catch (error) {
    console.error("Error in inviteStaff:", error);
    throw error;
  }
}

// istanbul ignore next
export async function removeStaff(userId: string): Promise<boolean> {
  try {
    const result = await Api.post(
      `/api/v1/building-management/users/${userId}/revoke-building-access`
    );

    if (!result) {
      throw new Error(i18n.t("message.no_result_from_api"));
    }

    return Promise.resolve(true);
  } catch (error) {
    console.error("Error in removeStaff:", error);
    throw new Error("Failed to remove staff");
  }
}

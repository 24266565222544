import styled, { useTheme } from "styled-components";
import { DoorImgWrapper } from "../../my-access/components/door/door";
import { UserDetail } from "../../../../common/redux/services/model";
import { H2, LabelText } from "../../../../common/components/design-system";
import "./user-profile-card.scss";

import { ReactComponent as MultiUserIcon } from "assets/icons/multi-user.svg";
import EditIcon from "@mui/icons-material/Edit";

export const UserProfileCardWrapper = styled.div.attrs((props) => props)`
  background: ${(props) => props.theme.color.background.darkDefault};
  padding: 17px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 45px;
`;
interface UserProfileCardProps {
  user: UserDetail;
  onEditPress?: () => void;
}
function UserProfileCard({ user, onEditPress }: UserProfileCardProps) {
  const theme = useTheme();
  return (
    <UserProfileCardWrapper data-testid="user-profile-card">
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <H2 color={theme.color.text.onPrimary}>Hi, {user?.name}</H2>
        {onEditPress && (
          <EditIcon
            onClick={onEditPress}
            data-testid="edit-user-button"
            sx={{ color: "white", cursor: "pointer" }}
          />
        )}
      </div>
      <div>
        {user?.isResident && (
          <div
            style={{
              border: "2px solid " + theme.color.text.onPrimary,
              borderRadius: 15,
              width: 80,
              height: 30,
              textAlign: "center",
            }}
          >
            <LabelText
              color={theme.color.text.onPrimary}
              style={{ marginTop: 3 }}
            >
              {user?.status &&
                user.status.charAt(0).toUpperCase() + user.status.slice(1)}
            </LabelText>
          </div>
        )}
      </div>
      <div className="user-profile-detail-wrapper">
        <div className="user-profile-email-phone-wrapper">
          <LabelText color={theme.color.text.onPrimary}>
            {user?.phone ?? "--"}
          </LabelText>
          <div style={{ marginTop: 10 }} />
          <LabelText color={theme.color.text.onPrimary}>
            {user?.email ?? "--"}
          </LabelText>
        </div>
        <div className="user-profile-image-wrapper">
          <DoorImgWrapper
            style={{
              border: `1px solid ${theme.color.text.onPrimary}`,
            }}
          >
            <MultiUserIcon stroke={theme.color.text.onPrimary} />
          </DoorImgWrapper>
        </div>
      </div>
    </UserProfileCardWrapper>
  );
}
export { UserProfileCard };

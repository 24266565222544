import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Button, H4 } from "../../../../common/components/design-system";
import { StepIndicator } from "../../../../common/components/step-indicator";
import { DoorAccessWrapperComponent } from "screens/authenticated/guests/add-guest/components/door-access-wrapper/door-access-wrapper";
import { AddGuestConfirmation } from "screens/authenticated/guests/add-guest/components/add-guest-confirmation/add-guest-confirmation";
import { Api } from "common/redux/services/base/api";
import { GuestSelectDoorsFormComponent } from "screens/authenticated/guests/add-guest/components/guest-select-doors-form/guest-select-doors-form";
import moment from "moment";
import { addResidentDoorAccess } from "common/redux/services/residents.service";
import { f } from "msw/lib/glossary-de6278a9";

interface AddNewResidentProps {
  onClose: () => void;
  defaultActiveStep?: number;
  residentData: any;
}

function AddNewResidentDoor({
  onClose,
  defaultActiveStep,
  residentData,
}: AddNewResidentProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(defaultActiveStep ?? 0);
  const [isLoading, setIsLoading] = useState(false);

  const [addResidentData, setAddResidentData] = useState({
    name: residentData.name,
    email: residentData.email,
    doors: [],
    startedAt: "",
    endedAt: "",
    currentModule: "AddResidentDoor",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });

  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <GuestSelectDoorsFormComponent
            currentModule="residents"
            testId="select-doors-form"
            selectedDoorList={addResidentData.doors}
            onNext={(doorList: any) => {
              const firstDoor = doorList[0];

              setAddResidentData({
                ...addResidentData,
                doors: doorList,
                // @ts-ignore
                offsetShortName: firstDoor?.offsetShortName,
              });
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
          />
        );
      // TO DO: test this case
      /* istanbul ignore next */
      case 1:
        return (
          //@ts-ignore
          <DoorAccessWrapperComponent
            testId="door-access-wrapper"
            accessFormVariant="add_guest"
            title="Access"
            onSubmit={(doorAccessDate: any) => {
              setAddResidentData({
                ...addResidentData,
                ...doorAccessDate,
              });
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
            // @ts-ignore
            offsetShortName={addResidentData?.offsetShortName}
          />
        );

      case 2:
        return (
          <AddGuestConfirmation
            testId="add-resident-confirmation"
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
            //@ts-ignore
            doorAccessInfo={addResidentData}
            guestInfo={{
              ...addResidentData,
              invite_email: addResidentData.email,
            }}
            doorList={addResidentData.doors!}
            onClose={onClose}
            onSubmit={() => {
              addResidentDoorAccess(addResidentData).then(() => {
                setActiveStep(activeStep + 1);
              });
            }}
            onDelete={(doorAccess: any) => {
              const doorList = addResidentData.doors;
              const newDoorList = doorList!.filter(
                (door: any) => door.doorUuid !== doorAccess.doorUuid
              );
              setAddResidentData({ ...addResidentData, doors: newDoorList });
            }}
          />
        );
      case 3:
        onClose();
        return <></>;
      default:
        return <></>;
    }
  }, [activeStep, isLoading, addResidentData]);

  return (
    <div
      data-testid={"add-resident-door-container"}
      className="common-form-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>Add Door</H4>
          </div>
          <div className="form-cancel">
            <Button
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <StepIndicator
          style={{ marginTop: "14px", marginBottom: "49px" }}
          totalSteps={3}
          activeStep={activeStep}
        />
        {renderSubComponent()}
      </div>
    </div>
  );
}

export { AddNewResidentDoor };

import { useCallback, useState } from "react";

import { StartToEndDate } from "../start-to-end-date/start-to-end-date";
import "./door-access-wrapper.scss";
import { GuestAccessDate } from "../../../../../../common/redux/services/model";
import { off } from "process";

export type StartToEndVariant = "edit_access" | "add_guest" | "add_door";

export interface DoorAccessWrapperProps {
  isLoading?: boolean;
  title: string;
  doorAccessDate: GuestAccessDate;
  accessFormVariant: StartToEndVariant;
  goBack: ((params: GuestAccessDate) => void) | undefined;
  onSubmit: ((params: GuestAccessDate) => void) | undefined;
  onDelete: (() => void) | undefined;
  testId?: string;
  offsetShortName?: string;
}
function DoorAccessWrapperComponent({
  title,
  goBack,
  onSubmit,
  doorAccessDate,
  accessFormVariant,
  isLoading,
  onDelete,
  testId,
  offsetShortName,
}: DoorAccessWrapperProps) {
  const [accessType, setAccessType] = useState<string>("start_end_date");
  const renderComponent = useCallback(() => {
    switch (accessType) {
      default: {
        return (
          <StartToEndDate
            isLoading={isLoading}
            accessFormVariant={accessFormVariant}
            title={title}
            doorAccessDate={doorAccessDate}
            goBack={goBack}
            onNext={onSubmit}
            onDelete={onDelete}
            offsetShortName={offsetShortName}
          />
        );
      }
    }
  }, [accessType, isLoading]);
  return (
    <div
      className="door-access-wrapper"
      data-testid={testId ?? "door-access-wrapper-component"}
    >
      {renderComponent()}
    </div>
  );
}
DoorAccessWrapperComponent.defaultProps = {
  isLoading: undefined,
};
export { DoorAccessWrapperComponent };

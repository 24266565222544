import styled from "styled-components";

import { ReactComponent as DoorIcon } from "assets/icons/door.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/angle-right.svg";

import "./door.scss";
import { BodyText, H4 } from "common/components/design-system";
import { Door } from "common/redux/services/model";
import { GuestDoorAccess } from "../../../../common/redux/services/model";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { useTheme } from "styled-components";
import moment from "moment";

interface DoorParams {
  door: Door;
  buildingName: string;
  onClick: (door: Door) => void;
}
export const DoorImgWrapper = styled.div.attrs((props) => props)`
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.color.background.darkDefault};
`;
function DoorComponent({ door, buildingName, onClick }: DoorParams) {
  const theme = useTheme();
  // @ts-ignore
  const startedAt = door?.startedAt;
  // @ts-ignore
  const endedAt = door?.endedAt;

  return (
    <div
      data-testid="door-component"
      className="door-wrapper"
      style={{ cursor: "pointer" }}
    >
      <div className="door-title-img-wrapper" style={{ marginTop: -16 }}>
        <div
          style={{
            position: "relative",
            marginTop: startedAt != undefined ? -40 : -10,
          }}
        >
          <div
            style={{
              height: 25,
              width: 1,
              backgroundColor: "black",
              marginLeft: 32, //half of the icon width
            }}
            data-testid="door-item-divider"
          />
          <div
            style={{
              borderRadius: 32,
              width: 64,
              height: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid",
            }}
          >
            <DoorIcon />
          </div>
        </div>
        <div>
          <div className="door-title">
            <H4 style={{ fontSize: 20, color: "#202020", marginTop: (startedAt != undefined ? 0 : 20) }}>{door?.name}</H4>
          </div>
          {startedAt != undefined ? (
            <div className="access-date">
              <BodyText color={theme.color.text.subdued}>
                {`Start: ${
                  startedAt
                    ? moment(startedAt).format("MMM DD, YYYY [•] hh:mmA")
                    : "none"
                }`}
              </BodyText>
              <BodyText color={theme.color.text.subdued}>
                {`End: ${
                  endedAt
                    ? moment(endedAt).format("MMM DD, YYYY [•] hh:mmA")
                    : "none"
                }`}
              </BodyText>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="door-pin"
        onClick={() => {
          onClick(door);
        }}
      >
        <EditIcon fill={"#202223"} stroke={"#202223"} />
      </div>
    </div>
  );
}

export { DoorComponent };

import styled, { useTheme } from "styled-components";

import { CaptionText } from "../typography/typography";
import { genericMemo } from "../../../utils";

import { SelectDropDown, SelectDropDownProps } from "./select-drop-down/select-drop-down";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

export const ValidationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 20px;
`;

export interface SelectFieldProps<I, R> extends SelectDropDownProps<I, R> {
  label?: string;
  caption?: string;
  note?: string;
  error?: boolean;
  errorMessage?: string;
}

function SelectFieldDelicate<I = string, R = string>(
  {
    label,
    errorMessage,
    error,
    caption,
    note,
    ...rest
  }: SelectFieldProps<I, R>,
) {
  const theme = useTheme();
  return (
    <div className="form-group text-field-wrapper">

      <SelectDropDown error={error} {...rest} />

      <div className={`${note || error ? "text-field-bottom-text-wrapper" : ""}`}>
        {
          !error ? (
            <CaptionText color={theme.color.text.subdued}>
              {note}
            </CaptionText>
          )
            : (
              <ValidationWrapper>
                <WarningIcon fill={theme.color.icon.critical} />
                <CaptionText color={theme.color.text.critical}>{errorMessage}</CaptionText>
              </ValidationWrapper>
            )
        }

      </div>
    </div>
  );
}

SelectFieldDelicate.defaultProps = {
  label: undefined,
  error: false,
  errorMessage: undefined,
  caption: undefined,
  note: undefined,
};
const SelectField = genericMemo(SelectFieldDelicate);
export { SelectField };

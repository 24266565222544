import React from "react";
import styled from "styled-components";
import { Button } from "../design-system";

import * as S from "./styles";

interface ConfirmationModalProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description?: string;
  confirmText: string;
  cancelText?: string;
  variant?: "removal" | "confirm-only" | "confirm-and-cancel";
  children?: React.ReactNode;
}

export function ConfirmationModal({
  visible,
  onConfirm,
  onCancel,
  title,
  description = "",
  confirmText,
  cancelText,
  variant = "removal",
  children,
}: ConfirmationModalProps) {
  const [loading, setLoading] = React.useState(false);

  const buttonStyle = {
    height: 40,
    maxWidth: 320,
    zIndex: 1,
  };

  return (
    <>
      {visible && (
        <S.Container data-testid="confirmation-modal-container">
          <S.Backdrop
            onClick={() => {
              onCancel();
            }}
          />
          <S.ContentContainer>
            <S.Title>{title}</S.Title>
            {description && (
              <S.DescriptionText>{description}</S.DescriptionText>
            )}

            {children}

            <S.ButtonsContainer>
              {(variant === "removal" || variant === "confirm-and-cancel") && (
                <Button
                  style={buttonStyle}
                  onClick={() => {
                    onCancel();
                  }}
                >
                  {cancelText ?? ""}
                </Button>
              )}
              <Button
                style={buttonStyle}
                type="submit"
                isLoading={loading}
                variant={
                  variant === "removal" ? "outlineMonochrome" : "primary"
                }
                onClick={() => {
                  setLoading(true);
                  onConfirm();
                  setLoading(false);
                }}
              >
                {confirmText}
              </Button>
            </S.ButtonsContainer>
          </S.ContentContainer>
        </S.Container>
      )}
    </>
  );
}

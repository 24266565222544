import { useState } from "react";
import { useTheme } from "styled-components";

import { DoorList } from "../door-list/door-list";
import { BuildingWithDoors } from "../../../../../common/redux/services/model";
import { H3, H4 } from "../../../../../common/components/design-system";

import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as DownArrowIcon } from "assets/icons/angle-down.svg";

import "./building-list.scss";

interface BuildingListParams {
  buildingList: BuildingWithDoors[];
  showPin?: boolean;
  setPinModalVisible?: (item: any) => void;
}
function BuildingList({
  buildingList,
  showPin,
  setPinModalVisible,
}: BuildingListParams) {
  const [selectedBuildingIndex, setSelectedBuildingIndex] = useState(-1);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  const toggleBuilding = (index: number) => {
    setSelectedBuildingIndex((prevIndex) => (prevIndex === index ? -1 : index));
    setExpanded((prevExpanded) => !prevExpanded); // Toggle expanded state
  };
  return (
    <div
      className="building-list-wrapper"
      data-testid="building-list-container"
    >
      {buildingList?.map((buildingWithDoor, index) => (
        <div
          key={buildingWithDoor.id}
          className="building-wrapper"
        >
          <div
            className="building"
            data-testid="building-item"
            onClick={() =>
              // Set to -1 if the same building is clicked again
              toggleBuilding(index)
            }
          >
            <div className="building-name-image">
              <div className="image-wrapper">
                <BuildingIcon />
              </div>
              <div style={{ flexDirection: "column" }}>
                <div className="building-name">
                  <H3>{buildingWithDoor?.name}</H3>
                </div>
                {/* showmore or less button */}
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignSelf: "flex-start",
                    alignItems: "center",
                    marginTop: -8,
                  }}
                >
                  <DownArrowIcon
                    fill={"#707070"}
                    height={8}
                    style={{
                      transform:
                        selectedBuildingIndex == index ? "rotate(180deg)" : "",
                      marginBottom: selectedBuildingIndex == index ? 2 : 0,
                      marginTop: -4,
                      marginRight: 4,
                    }}
                  />
                  <H4 style={{ fontSize: 16, color: "#707070" }}>
                    {selectedBuildingIndex == index ? "Show Less" : "Show More"}
                  </H4>
                </div>
              </div>
            </div>

            {/* <div
              className="building-drop-icon"
              style={{
                transform:
                  selectedBuildingIndex == index ? "rotate(180deg)" : "",
              }}
            >
              <DownArrowIcon fill={theme.color.text.default} />
            </div> */}
          </div>

          <div
            style={{
              // height: selectedBuildingIndex === index ? 300 : 0,
              maxHeight: selectedBuildingIndex === index ? "100vh" : "0",
              overflow: "hidden",
              transition: "max-height 0.5s ease-out",
              paddingBottom: selectedBuildingIndex === index ? 20 : 0,
            }}
          >
            {selectedBuildingIndex == index && (
              <DoorList
                doors={buildingWithDoor?.doors}
                showPin={showPin}
                setPinModalVisible={setPinModalVisible}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
export { BuildingList };

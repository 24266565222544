function capitalize(value?: String): string | undefined {
  if (!value) {
    return value;
  }
  return value.charAt(0)
    .toUpperCase() + value.slice(1);
}

function insert(from: string, what: string, index: number): string {
  if (!from) {
    return from;
  }

  return index > 0
    ? from?.toString()?.replace(new RegExp(`.{${index}}`), `$&${what}`)
    : what + from;
}

export { capitalize, insert };

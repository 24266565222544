const Constants = {
  storageKey: {
    userSession: "session",
  },
  apiEndpoint: {
    users: "/api/v1/users",
    login: "/api/v1/authentication/login",
    googleLogin: "/api/v1/authentication/google-login",
    appleLogin: "/api/v1/authentication/apple-login",
    doorPins: "/api/v1/door-pins",
    doors: "/api/v1/users/doors",
    activityFilterableDoors: "/api/v1/activities/filterable-doors",
    doorsBuildingManager: "/api/v1/building-management/doors/search",
    // getGuests: "/api/v1/users/guests",//DEPRECATED
    getGuests: "/api/v1/door-pins/my-guests-pins",
    createUser: "/api/v1/users/invites",
    addDoorAccess: "/api/v1/door-access",
    doorAccess: "/api/v1/door-access",
  },
  Config: {
    api: {
      APP_DOMAIN: process.env.REACT_APP_DOMAIN,
      APP_TYPE: process.env.REACT_APP_TYPE,
    },
    time: {
      MAX_CACHE_TIMEOUT: 300000, // 5 minutes
      MAX_CONNECTION_TIMEOUT: 20000, // twenty-second
      MAX_CACHE_TIMEOUT_DIAGNOSTIC_CENTER: 60000, // 1 minutes,
    },
    paging: {
      page: 1, 
      pageSize: 500,
    },
  },
  oAuth: {
    fusionAuth: {
      apiKey: process.env.REACT_APP_FUSION_AUTH_API_KEY,
      url: process.env.REACT_APP_FUSION_AUTH_URL,
      redirectUri: "https://apadaccess.fusionauth.io/oauth2/callback",
      applicationId: process.env.REACT_APP_FUSION_AUTH_APPLICATION_ID,
      apple: {
        providerId: process.env.REACT_APP_FUSION_AUTH_APPLE_PROVIDER,
      },
    },
  },
};

export { Constants };

import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import moment from "moment/moment";
import { toast } from "react-toastify";

import {
  DoorResponse,
  GuestAccessDate,
} from "../../../../common/redux/services/model";
import { DoorAccessWrapperComponent } from "../add-guest/components/door-access-wrapper/door-access-wrapper";
import { Button, H4 } from "../../../../common/components/design-system";
import { GuestSelectDoorsFormComponent } from "../add-guest/components/guest-select-doors-form/guest-select-doors-form";
import { StepIndicator } from "../../../../common/components/step-indicator";
import { AddGuestConfirmation } from "../add-guest/components/add-guest-confirmation/add-guest-confirmation";
import { addDoorAccess } from "common/redux/services/guests.service";
import { door } from "constants/mockedData";

interface AddDoorProps {
  guestName?: string;
  guestId: string;
  guestInfo: any;
  onClose: () => void;
  defaultActiveStep?: number;
  offsetShortName?: string;
}
function AddDoor({
  guestName,
  guestId,
  onClose,
  guestInfo,
  defaultActiveStep,
  offsetShortName,
}: AddDoorProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(defaultActiveStep ?? 0);
  const [doorList, setDoorList] = useState<DoorResponse[]>();
  const [doorAccessDate, setDoorAccessDate] = useState<
    GuestAccessDate | undefined
  >();

  const [isLoading, setIsLoading] = useState(false);
  const [addDoorAccessResponse, setAddDoorAccessResponse] = useState<any>();
  const saveDoorAccess = useCallback(() => {
    let startedAt = new Date(
      `${moment(doorAccessDate?.startDate).format("YYYY-MM-DD")}T${moment(
        doorAccessDate?.startTime
      ).format("HH:mm:ss")}`
    );

    let endedAt = doorAccessDate?.endDate
      ? new Date(
          `${moment(doorAccessDate?.endDate).format("YYYY-MM-DD")}T${moment(
            doorAccessDate?.endTime
          ).format("HH:mm:ss")}`
        )
      : undefined;

    const dateData = {
      startedAt: startedAt.toISOString(),
      // @ts-ignore
      endedAt: endedAt ?? undefined,
      permissionLevel: "guest",
      // startedAt: "2023-01-01 22:00:00",
      // endedAt: "2023-12-01 07:15:00",
    };

    const doorIdList = doorList?.map((door) => door.doorUuid) ?? [];
    let response = null;
    if (guestId) {
      response = addDoorAccess({
        ...dateData,
        doorList: doorIdList,
        guestId,
        allowedNickname: guestName,
      });
    } else {
      response = addDoorAccess({
        ...dateData,
        doorList: doorIdList,
        guestId,
        doorAccessPinUuid: guestInfo.doorAccessPinUuid,
      });
    }
    setAddDoorAccessResponse(response);
  }, [doorList, doorAccessDate]);
  useEffect(() => {
    const response = addDoorAccessResponse;
    if (response) {
      toast.success("Door added successfully");
      onClose?.();
    }
    PageTransitionEvent;
  }, [addDoorAccessResponse]);

  // These steps are being tested separately
  // istanbul ignore next
  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <GuestSelectDoorsFormComponent
            data-testid={"guest-select-doors-form"}
            doorSelectVariant="add_door"
            selectedDoorList={doorList!}
            onNext={(doorList) => {
              setDoorList(doorList);
              setActiveStep(activeStep + 1);
            }}
            goBack={(doorList) => {
              setDoorList(doorList);
              setActiveStep(activeStep - 1);
            }}
          />
        );
      case 1:
        return (
          <DoorAccessWrapperComponent
            data-testid={"door-access-wrapper"}
            isLoading={isLoading}
            accessFormVariant="add_door"
            title="Access"
            doorAccessDate={doorAccessDate!}
            goBack={(doorAccessDate) => {
              setDoorAccessDate(doorAccessDate);
              setActiveStep(activeStep - 1);
            }}
            onDelete={() => {}}
            onSubmit={(doorAccessDate) => {
              setDoorAccessDate(doorAccessDate);
              setActiveStep(activeStep + 1);
            }}
            offsetShortName={doorList![0].offsetShortName}
          />
        );
      case 2:
        return (
          <AddGuestConfirmation
            data-testid={"add-guest-confirmation"}
            isLoading={isLoading}
            accessFormVariant="add_door"
            title="Access"
            doorAccessInfo={doorAccessDate!}
            guestInfo={guestInfo!}
            doorList={doorList!}
            // TO DO: test this case
            /* istanbul ignore next */
            // @ts-ignore
            goBack={(doorAccessDate: any) => {
              //setDoorAccessDate(doorAccessDate);
              setActiveStep(activeStep - 1);
            }}
            // TO DO: test this case
            /* istanbul ignore next */
            onDelete={(doorAccess: any) => {
              const newDoorList = doorList!.filter(
                (door) => door.doorUuid !== doorAccess.doorUuid
              );
              setDoorList(newDoorList);
            }}
            onSubmit={(doorAccessDate: any) => {
              setDoorAccessDate(doorAccessDate);
              saveDoorAccess();
            }}
          />
        );
    }
  }, [activeStep, isLoading, doorList]);

  return (
    <div
      data-testid={"add-door-container"}
      className="common-form-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>Edit Access</H4>
          </div>
          <div className="form-cancel">
            <Button
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <StepIndicator
          style={{ marginTop: "14åpx", marginBottom: "49px" }}
          totalSteps={3}
          activeStep={activeStep}
        />
        {renderSubComponent()}
      </div>
    </div>
  );
}

export { AddDoor };

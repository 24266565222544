import * as S from "./styles";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export function InvitationByDate({
  invites,
  date,
}: {
  invites: any;
  date: string;
}) {
  const optionsIcons = [
    {
      value: "active",
      icon: (
        <CheckIcon
          sx={{
            color: "#00D97A",
            fontSize: 32,
          }}
        />
      ),
    },
    {
      value: "pending",
      icon: (
        <AccessTimeOutlinedIcon
          sx={{
            color: "black",
            fontSize: 32,
          }}
        />
      ),
    },
    {
      value: "expired",
      icon: (
        <CancelOutlinedIcon
          sx={{
            color: "black",
            fontSize: 32,
          }}
        />
      ),
    },
  ];

  return (
    <>
      <S.GroupDate>
        {moment(date).isSame(moment(), "day")
          ? "Today"
          : moment(date).isSame(moment().subtract(1, "days"), "day")
          ? "Yesterday"
          : moment(date).format("MMM Do, YYYY")}
      </S.GroupDate>

      {invites.map((item: any) => {
        return (
          <S.InviteContainer>
            <S.IconContainer>
              {optionsIcons.map((option) => {
                if (option.value === item.inviteStatus) {
                  return option.icon;
                }
              })}
            </S.IconContainer>
            <S.InfoContainer>
              <S.InfoText>To: {item.invitedName}</S.InfoText>
              <S.InfoText>
                {item.buildingName}
              </S.InfoText>
              {/* @ts-ignore */}
              <S.InfoText numberOfLines={1}>
                {item.resources
                  .map((resource: any) => resource.resourceName)
                  .join(", ")}
              </S.InfoText>
            </S.InfoContainer>
            <S.Time>
              {
                // format to am/pm time using moment
                moment(item.startedAt).format("h:mm a")
              }
            </S.Time>
          </S.InviteContainer>
        );
      })}
    </>
  );
}

import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { InputFieldIcon } from "../design-system/text-field/input-field-icon/input-field-icon";
import { Button } from "../design-system";

export function SearchBar({
  searchText,
  setSearchText,
  onAddButtonPress,
}: {
  searchText: string;
  setSearchText: (text: string) => void;
  onAddButtonPress?: () => void;
}) {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        gap: "16px",
      }}
    >
      <InputFieldIcon
        data-testid="search-bar"
        style={{ width: "286px", marginTop: 0 }}
        prefixStyle={{ top: "11px" }}
        prefixIcon={() => <SearchIcon />}
        placeholder="Search"
        value={searchText}
        onChange={(e: any) => {
          setSearchText(e.target.value);
        }}
      />
      {onAddButtonPress && (
        <Button
          variant="basic"
          size="lg"
          style={{
            width: "54px",
            fontSize: "24px",
            color: theme.color.icon.hovered,
          }}
          onClick={() => onAddButtonPress?.()}
        >
          +
        </Button>
      )}
    </div>
  );
}

import styled from "styled-components";

export const PageContainer = styled.div`
  justify-content: space-between;
  padding: 24px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
`;

export const BuildingsGroupContainer = styled.div`
  margin-bottom: 32px;
`;

export const BuildingContainer = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  display: flex;
`;

export const BuildingName = styled.h3`
  font-size: 20px;
  font-weight: 400;
  flex: 1;
  margin-left: 16px;
`;

export const GroupName = styled(BuildingName)`
  font-size: 20px;
  font-weight: 400;
  color: #202223;
  margin-left: 0px;
`;

export const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  display: flex;
`;

export const InputLabel = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: #6d7175;
  font-family: Helvetica Now Display Medium;
  font-style: normal;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  flex: 1;
`;

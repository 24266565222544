import { CSSProperties } from "react";
import styled, { useTheme } from "styled-components";

export const StepIndicatorContainer = styled.div`
  display: flex;
  gap: 8px;
`;
export const StepIndicatorWrapper = styled.div`
  height: 5px;
  width: 100%;
  border-radius: 34px;
`;
type StepIndicatorProps = {
  totalSteps: number;
  activeStep: number;
  style?: CSSProperties;
};
function StepIndicator({ totalSteps, activeStep, style }: StepIndicatorProps) {
  const theme = useTheme();
  return (
    <StepIndicatorContainer style={style}>
      {[...Array(totalSteps)].map((step, index) => (
        <StepIndicatorWrapper
          key={step + Math.random().toString()}
          style={
            index <= activeStep
              ? { background: theme.color.brand }
              : { background: theme.color.background.hovered }
          }
        />
      ))}
    </StepIndicatorContainer>
  );
}
StepIndicator.defaultProps = {
  style: undefined,
};
export { StepIndicator };

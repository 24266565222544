/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../navigations/Types";
import { TopNav } from "../../../common/components/top-nav";
import { HeaderOption } from "../../../common/components/top-nav/type";
import { Button } from "../../../common/components/design-system";
import { Api } from "../../../common/redux/services/base/api";

import { UserProfileCard } from "./components/user-profile-card";
import "./profile.scss";

interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
}

function Profile() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();
  const [userData, setUserData] = useState<null | User>(null);

  const userOption: HeaderOption = {
    url: "/profile",
    title: "My Account",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  // @ts-ignore
  async function getUser(): Promise {
    const result = await Api.get("/api/v1/users/profile");
    if (result != null) {
      const user: User = result.data;
      setUserData(result.data);
      return Promise.resolve(result?.data);
    }
    // istanbul ignore else
    else {
      return Promise.reject();
    }
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <TopNav navigationOption={userOption} />
      </div>
      <div className="profile-wrapper">
        <UserProfileCard
          user={{
            name: userData?.firstName ?? "",
            phone: userData?.phoneNumber ?? "",
            email: userData?.email ?? "",
          }}
        />
        <div style={{ marginTop: 20 }}></div>

        <div className="user-fields">
          <div>Name: </div>

          <NavLink
            data-testid="change-name-link"
            key="ChangeName"
            to="/change-name"
            style={{
              fontSize: 16,
              marginLeft: 20,
              marginRight: 20,
              color: "#6D7175",
            }}
          >
            {userData?.fullName}
          </NavLink>
        </div>
        <div className="user-fields">
          <div>Email</div>

          <NavLink
            key="ChangeEmail"
            to="/change-email"
            style={{
              fontSize: 16,
              marginLeft: 20,
              marginRight: 20,
              color: "#6D7175",
            }}
          >
            {userData?.email}
          </NavLink>
        </div>

        <div className="user-fields">
          <div>Phone</div>

          <NavLink
            key="ChangePhone"
            to="/change-phone"
            style={{
              fontSize: 16,
              marginLeft: 20,
              marginRight: 20,
              color: "#6D7175",
            }}
          >
            {userData?.phoneNumber === "" ? (
              <Button variant="basic">Insert a Phone Number</Button>
            ) : (
              userData?.phoneNumber
            )}
          </NavLink>
        </div>
        <div className="user-fields">
          <div>Update Password</div>

          <NavLink
            key="UpdatePassword"
            to="/update-password"
            style={{
              fontSize: 16,
              marginLeft: 20,
              marginRight: 20,
              color: "#6D7175",
            }}
          >
            *************
          </NavLink>
        </div>

        <NavLink
          key="permissions"
          to="/permissions"
          style={{
            fontSize: 16,
            marginLeft: 20,
            marginRight: 20,
            color: "#6D7175",
          }}
        >
          <div>My Permissions</div>
        </NavLink>
        {/* <div className="user-fields"> */}
        <NavLink
          key="invitations"
          to="/invitations"
          style={{
            fontSize: 16,
            marginLeft: 20,
            marginRight: 20,
            color: "#6D7175",
          }}
        >
          <div>My Invites</div>
        </NavLink>
        {/* </div> */}
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "Profile",
  screen: Profile,
  path: "/profile",
  role: [UserRole.Authenticated],
};
export { Profile, route };

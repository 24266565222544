import styled from "styled-components";

export const RowsContainer = styled.div`
  height: fit-content;
  padding: 12px 12px 32px 12px;
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const Row = styled.div`
  width: 100%;
  flex-direction: row;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  display: flex;
  /* increase size animated */
  transition: transform 0.1s ease-in;

  /* change color on hover */
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
    transform: scale(1.01);
  }
`;

const circleSize = 64;
export const Circle = styled.div`
  height: ${circleSize}px;
  width: ${circleSize}px;
  border-radius: ${circleSize / 2}px;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TextsContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  padding: 0px 16px;
  width: 100%;
  flex: 1;
`;

export const RowTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  color: #202020;
`;

export const RowDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #202020;
`;

export const PinText = styled.h2`
  font-size: 32px;
  font-weight: 400;
  color: #707070;
  line-height: 40px;
  margin-bottom: 24px;
`;

export const DescriptionText = styled.p`
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  text-align: center;
  color: black;
  margin: 12px 0px 32px 0px;
`;

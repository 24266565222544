import { useCallback, useState } from "react";
import { useTheme } from "styled-components";

import { GuestDetail } from "../../../../common/redux/services/model";
import { Button, H4 } from "../../../../common/components/design-system";

import "./edit-guest.scss";
import { EditGuestInfoFormComponent } from "./components/edit-guest-info-form-component/edit-guest-info-form-component";

interface EditGuestProps {
  guest: GuestDetail;
  onClose: () => void;
}

function EditGuest({ onClose, guest }: EditGuestProps) {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const [guestInfo, setGuestInfo] = useState<GuestDetail | undefined>(guest);

  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <EditGuestInfoFormComponent
            guestInfo={guestInfo!}
            onCancel={onClose}
          />
        );
    }
  }, [activeStep]);
  
  return (
    <div
      data-testid="edit-profile"
      className="common-form-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>Edit Profile</H4>
          </div>
          <div className="form-cancel">
            <Button
              data-testid="cancel-button"
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        {renderSubComponent()}
      </div>
    </div>
  );
}
export { EditGuest };

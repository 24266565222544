import { useEffect, useMemo, useState } from "react";
import { InputFieldIcon } from "common/components/design-system/text-field/input-field-icon/input-field-icon";

import {
  Button,
  ButtonText,
  SelectField,
} from "../../../../common/components/design-system";
import { ReactComponent as DownIcon } from "assets/icons/down-arrow.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { E164Number } from "libphonenumber-js";
import "../add-new-staff/styles.scss";

import * as S from "../add-new-staff/styles";
import { Api } from "common/redux/services/base/api";

function FillStaffInfo({
  addNewStaffData,
  setAddNewStaffData,
  onNext,
  goBack,
}: any) {
  const [staffData, setStaffData] = useState({
    userUuid: addNewStaffData.userUuid,
    name: addNewStaffData.name,
    email: addNewStaffData.email,
    phoneNumber: addNewStaffData.phoneNumber,
    role: addNewStaffData.role,
  });
  const [allowedToNext, setAllowedToNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailEditable, setEmailEditable] = useState(true);
  const [nameEditable, setNameEditable] = useState(true);
  const [phoneEditable, setPhoneEditable] = useState(true);
  const [roleEditable, setRoleEditable] = useState(true);

  const nextButton = () => {
    if (staffData.name === "") {
      setErrorMessage("* Name cannot be empty");
    } else if (staffData.email === "") {
      setErrorMessage("* Email cannot be empty");
    } else if (staffData.phoneNumber === "") {
      setErrorMessage("* Phone cannot be empty");
    } else if (addNewStaffData.role == "") {
      setAddNewStaffData({
        ...addNewStaffData,
        role: roleOptions[0],
      });
    } else {
      updateStaff();
    }
  };

  const [permissions, setPermissions] = useState<any>([]);

  async function getPermissions(): Promise<any> {
    const result = await Api.get("/api/v1/users/initial-data");
    if (result != null) {
      setPermissions(result.data);
      return Promise.resolve(result.data);
    } else {
      return Promise.reject();
    }
  }

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPermissions().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (permissions?.permissionLevels) {
      setPermissions(permissions);
    } else {
      setPermissions({ permissionLevels: [] });
    }
  }, [permissions]);

  const roleOptions = useMemo(() => {
    if (permissions.length === 0) return [];

    let options = [
      { value: "building-staff", label: "Staff" },
      { value: "building-manager", label: "Manager" },
    ];

    if (staffData.role.includes("organization-manager")) {
      options.push({ value: "organization-manager", label: "Org. Manager" });
      setRoleEditable(false);
    } else {
      setRoleEditable(true);
    }

    return options;
  }, [permissions]);

  async function searchUser(email: string) {
    const result = await Api.post(
      // @ts-ignore
      "/api/v1/building-management/building-users/search",
      {
        buildingIds: ["*"],
        permissions: ["building-manager", "building-staff"],
        allowedEmail: email,
      }
    );
    if (result != null && result.data?.users?.length > 0) {
      const user = result.data.users[0];
      // Return if the email is already used by another user
      // @ts-ignore
      if (email != addNewStaffData.email) {
        setErrorMessage("Email already exists");
        return Promise.reject();
      }

      if (user.status == "active") {
        setNameEditable(false);
        setPhoneEditable(false);
        setEmailEditable(false);
      }
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  useEffect(() => {
    if (staffData.email.match(/^[\w.+%-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/)) {
      searchUser(staffData.email);
    }
  }, [staffData.email]);

  async function updateStaff(): Promise<void> {
    // @ts-ignore
    let role = staffData.role ? staffData?.role : roleOptions[0].value;
    role = role.replace(/\s+/g, "-").toLowerCase();
    const result = await Api.patch(
      "/api/v1/building-management/building-users/" + staffData?.userUuid,
      {
        name: staffData.name,
        phoneNumber: staffData.phoneNumber,
        permissionLevel: role,
        email: staffData.email,
      }
    );
    setAddNewStaffData({ ...addNewStaffData, ...staffData });
    goBack();
    if (result == null) {
      throw new Error("Failed to update user");
    }
  }

  return (
    <div data-testid="fill-staff-info-container">
      <S.ContentContainer
        style={{
          flexDirection: "column",
        }}
      >
        <S.InputContainer>
          <S.InputLabel>Email</S.InputLabel>
          <InputFieldIcon
            data-testid="email-input"
            // @ts-ignore
            onChange={(event: InputText) => {
              setErrorMessage("");
              setStaffData({ ...staffData, email: event.target.value });
            }}
            value={staffData.email}
            disabled={!emailEditable}
          />
          <div>
            <h2 style={{ fontSize: 12, color: "red", marginTop: 10 }}>
              {errorMessage}
            </h2>
          </div>
        </S.InputContainer>

        <>
          <S.InputContainer>
            <S.InputLabel>Name</S.InputLabel>
            <InputFieldIcon
              data-testid="name-input"
              // @ts-ignore
              onChange={(event: InputText) => {
                setErrorMessage("");
                setStaffData({ ...staffData, name: event.target.value });
              }}
              value={staffData.name}
              disabled={!nameEditable}
            />
          </S.InputContainer>

          <S.InputContainer>
            <S.InputLabel>Phone</S.InputLabel>
            <div className="phone-input-wrapper">
              <PhoneInput
                data-testid="phone-input"
                type="tel"
                placeholder="Enter phone number"
                value={staffData.phoneNumber}
                flagWidth={200}
                flagHeight={40}
                onChange={(value?: E164Number) => {
                  if (value) {
                    setStaffData({ ...staffData, phoneNumber: value });
                  } else {
                    setStaffData({ ...staffData, phoneNumber: "" });
                  }
                }}
                disabled={!phoneEditable}
              />
            </div>
          </S.InputContainer>

          <div style={{ marginTop: 24 }}>
            <SelectField
              data-testid="role-input"
              label="Role"
              icon={DownIcon}
              name="type"
              data={roleOptions}
              selectedValue={staffData.role}
              onSelection={(selected: any) => {
                setStaffData({ ...staffData, role: selected });
              }}
              valueExtractor={(item) => item.value}
              labelExtractor={(item) => item.label}
              keyExtractor={(item) => item.toString()}
              disabled={!roleEditable}
            />
          </div>
        </>
      </S.ContentContainer>

      <div className="bottom-buttons">
        <Button
          size="lg"
          variant="basic"
          onClick={() => {
            goBack(goBack);
          }}
        >
          <ButtonText>Cancel</ButtonText>
        </Button>
        <div style={{ width: 20 }} />
        <Button
          data-testid="next-button"
          size="lg"
          variant="primary"
          onClick={nextButton}
          disabled={errorMessage != ""}
        >
          <ButtonText>Confirm</ButtonText>
        </Button>
      </div>
    </div>
  );
}

export { FillStaffInfo };

import { useState } from "react";
import { useTheme } from "styled-components";

import { DoorList } from "../door-list/door-list";
import { BuildingWithDoors } from "../../../../common/redux/services/model";
import { H4 } from "../../../../common/components/design-system";

import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as DownArrowIcon } from "assets/icons/angle-down.svg";

import "./building-list.scss";
import { Door } from "../../../../common/redux/services/model/door";

interface BuildingListParams {
  buildingList: BuildingWithDoors[];
  onClick: (door: Door) => void;
}
function BuildingList({ buildingList, onClick }: BuildingListParams) {
  const [selectedBuildingIndex, setSelectedBuildingIndex] = useState(0);
  const theme = useTheme();
  return (
    <div className="building-list-wrapper">
      {buildingList?.map((buildingWithDoor: any, index) => {
        const expanded = selectedBuildingIndex == index;
        return (
          <div key={buildingWithDoor.id} className="building-wrapper">
            <div
              className="building"
              onClick={() =>
                // Set to -1 if the same building is clicked again
                setSelectedBuildingIndex(expanded ? -1 : index)
              }
            >
              <div className="building-name-image">
                <div className="image-wrapper">
                  <BuildingIcon />
                </div>
                <div
                  className="building-name"
                  style={{ flexDirection: "column" }}
                >
                  <H4 style={{ fontSize: 20, color: "#202020" }}>
                    {buildingWithDoor?.name}
                  </H4>
                  {/* showmore or less button */}
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignSelf: "flex-start",
                      alignItems: "center",
                      marginTop: -8,
                    }}
                  >
                    <DownArrowIcon
                      fill={"#707070"}
                      height={8}
                      style={{
                        transform: expanded ? "rotate(180deg)" : "",
                        marginBottom: expanded ? 2 : 0,
                        marginTop: -4,
                        marginRight: 4,
                      }}
                    />
                    <H4 style={{ fontSize: 16, color: "#707070" }}>
                      {expanded ? "Show Less" : "Show More"}
                    </H4>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                // height: selectedBuildingIndex === index ? 300 : 0,
                maxHeight:
                  selectedBuildingIndex === index && buildingWithDoor?.data
                    ? buildingWithDoor?.data?.length * 120
                    : "0",
                overflow: "hidden",
                transition: "max-height 0.5s ease-out",
                paddingBottom: selectedBuildingIndex === index ? 20 : 0,
              }}
            >
              {selectedBuildingIndex == index && (
                <DoorList
                  /* @ts-ignore */
                  doors={buildingWithDoor?.data}
                  buildingName={buildingWithDoor?.name}
                  onClick={onClick}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
export { BuildingList };

import styled, { css, CSSProperties } from "styled-components";

export type InputHeightType = "sm" | "lg";

export interface InputProps {
  color?: string;
  error?: boolean;
  backgroundColor?: string;
  inputHeight?: InputHeightType;
  prefixIcon?: () => JSX.Element;
  prefixStyle?: CSSProperties;
  suffixIcon?: () => JSX.Element;
  suffixStyle?: CSSProperties;
}

const Input = styled.input.attrs((props: InputProps) => props)`
  margin-top: 4px;
  padding: 8px 12px;
  width: 100%;
  height: 48px;
  background-color: ${(props) =>
    // @ts-ignore
    props.backgroundColor || props.theme.color.surface.default};
  border-radius: 4px;
  border: none;
  outline: 2px solid transparent;

  ${(props) =>
    // @ts-ignore
    props.prefixIcon &&
    css`
      padding: 13px 13px 13px 40px;
      line-height: 16px;
    `}
  ${(props) =>
    // @ts-ignore
    props.suffixIcon &&
    css`
      padding: 13px 40px 13px 13px;
      line-height: 16px;
    `}
  ${(props) =>
    props.readOnly &&
    `background-color: ${props.theme.color.surface.default}; `}
  ${(props) =>
    // @ts-ignore
    props.error &&
    `background-color: ${props.theme.color.surface.criticalSubdued}; border: 1px solid ${props.theme.color.border.criticalDefault}; `}
    /* Inside auto layout */
  &:focus {
    background: ${(props) => props.theme.color.surface.default};
    border: 1px solid ${(props) => props.theme.color.border.subdued};
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.color.focused.default};
    ${(props) =>
      // @ts-ignore
      props.error &&
      `background-color: ${props.theme.color.surface.criticalSubdued}; border: 1px solid ${props.theme.color.border.criticalDefault}; outline:none; `}
  }

  &:disabled {
    background: ${(props) => props.theme.color.surface.default};
    color: ${(props) => props.theme.color.text.disabled};
    fill: ${(props) => props.theme.color.text.disabled};
  }

  &::placeholder {
    font-family: ${(props) => props.theme.fontFamily.normal};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.color.text.subdued};
  }
`;

export { Input };

import styled from "styled-components";

export const HeaderContainer = styled.div`
  padding: 0px 24px;
  overflow-x: auto;
  /* hide scroll */
  /* //IE and Edge
  -ms-overflow-style: none;
  // Firefox
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    // Chrome, Safari, Opera
    display: none;
  } */
`;

export const ResidentsPage = styled.div`
  flex: 1;
  padding-left: 24px;
  padding-right: 24px;
`;

/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "screens/navigations/Types";
import * as S from "./styles";
import { UserProfileCard } from "screens/authenticated/profile/components/user-profile-card";
import { BuildingList } from "./components/building-list/building-list";
import { Button, H4 } from "common/components/design-system";
import { HeaderOption } from "common/components/top-nav/type";
import { TopNav } from "common/components/top-nav";
import { AddNewResidentDoor } from "../add-door/add-new-resident-door";
import { RightSidebarLayout } from "common/components/right-sidebar-layout";
import { getStaffData } from "common/redux/services/building-management.service";
import { EditGuestAccess } from "screens/authenticated/guests/edit-guest-access";
import { EditResident } from "../edit-resident";
import { removeResident } from "common/redux/services/residents.service";

import { ReactComponent as LoadingIcon } from "assets/icons/loadingIcon.svg";

export interface ResidentDetailsProps {
  userId: string;
}

export interface Door {
  name: string;
  doorAccessUuid: string;
  doorUuid: string;
  doorName: string;
  doorType: string;
  permissionLevel: string;
  startedAt: string;
  endedAt: string;
}

export interface DoorAccesses {
  private: Door[];
  shared: Door[];
}

export interface Building {
  buildingUuid: string;
  buildingName: string;
  doorAccess: DoorAccesses;
}

export interface Resident {
  name: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  doorAccesses: Building[];
  status: string;
  receivedAccessFromMe: boolean;
}

interface AddNewResidentProps {
  onClose: () => void;
  defaultActiveStep?: number;
  residentData: Resident;
  residentName: string;
}

function ResidentDetails({ defaultActiveStep }: AddNewResidentProps) {
  const { userId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(defaultActiveStep ?? 0);
  const [residentData, setResidentData] = useState({} as Resident);
  const [doorAccess, setDoorAccess] = useState();

  const location = useLocation();
  const isResident = location.state?.isResident;

  async function getData(userId: string) {
    const { staff, residents } = await getStaffData(userId);
    setResidentData(residents);
    setLoading(false);
  }

  const isGuest = (user: any): boolean => {
    try {
      for (const building of user.doorAccesses) {
        for (const access of building.doorAccess.private) {
          if (access.permissionLevel === "guest") {
            return true;
          }
        }
        for (const access of building.doorAccess.shared) {
          if (access.permissionLevel === "guest") {
            return true;
          }
        }
      }
    } catch (error) {
      return false;
    }
    return false;
  };

  useEffect(() => {
    if (showForm === undefined) {
      getData(userId ?? "");
    }
  }, [showForm]);

  const renderSubcomponent = useCallback(() => {
    switch (showForm) {
      case "resident_form":
        return (
          <AddNewResidentDoor
            onClose={() => {
              setShowForm(undefined);
            }}
            residentData={residentData}
          />
        );

      case "edit_access":
        return (
          <EditGuestAccess
            totalAccess={residentData?.doorAccesses?.length!}
            userId={userId ?? ""}
            // @ts-ignore
            doorAccess={doorAccess ?? {}}
            onClose={() => {
              setShowForm(undefined);
            }}
          />
        );
      case "edit_resident":
        return (
          <EditResident
            //@ts-ignore
            residentData={residentData}
            // @ts-ignore
            doorAccess={doorAccess ?? {}}
            onClose={() => {
              setShowForm(undefined);
            }}
          />
        );

      default:
        return undefined;
    }
  }, [showForm]);

  const title = isGuest(residentData) ? "Guest" : "Resident";

  const residentOption: HeaderOption = {
    url: "/people",
    title: title,
    showLogo: false,
    rightComponent: () => {
      return (
        <div className="top-nav-right-wrapper">
          <Button
            data-testid="add-door-button"
            variant="plain"
            onClick={() => setShowForm("resident_form")}
          >
            Add door
          </Button>

          {residentData?.status === "pending" &&
            residentData?.receivedAccessFromMe && (
              <Button
                variant="plain"
                // @ts-ignore
                onClick={() => setShowForm("edit_resident", { residentData })}
                data-testid="edit-resident-button"
              >
                Edit
              </Button>
            )}
        </div>
      );
    },
  };

  return (
    <>
      {!loading && (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TopNav navigationOption={residentOption} />
          <S.Container data-testid="resident-details-container">
            <RightSidebarLayout
              visible={!!showForm}
              onClose={() => setShowForm(undefined)}
            >
              {renderSubcomponent()}
            </RightSidebarLayout>
            <UserProfileCard
              user={{
                name: residentData?.name ?? "",
                phone: residentData?.phoneNumber ?? "",
                email: residentData?.email ?? "",
                status: residentData?.status ?? "",
                isResident: isResident,
              }}
            />
            <div
              style={{
                overflowY: "auto",
                flex: 1,
                height: "max-content",
                display: "flex",
                alignContent: "space-between",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              {residentData?.doorAccesses?.length > 0 && (
                <BuildingList
                  //  @ts-ignore
                  buildingList={residentData.doorAccesses ?? []}
                  onClick={(doorAccess) => {
                    // @ts-ignore
                    setDoorAccess(doorAccess);
                    setShowForm("edit_access");
                  }}
                />
              )}
              <S.DeleteResidentWrapper
                data-testid="delete-resident-wrapper"
                className="delete-wrapper"
              >
                <Button
                  variant="plain"
                  data-testid="delete-resident-button"
                  color={theme.color.text.critical}
                  onClick={async () => {
                    if (
                      window.confirm(
                        `Are you sure you want to delete this ${title.toLowerCase()}?`
                      )
                    ) {
                      const result = await removeResident(userId ?? "");
                      if (result) {
                        navigate("/people");
                      } else {
                        window.alert(
                          `Something went wrong while removing the ${title.toLowerCase()}`
                        );
                      }
                    }
                  }}
                >
                  <H4 color={theme.color.interactive.critical}>
                    Remove {title}
                  </H4>
                </Button>
              </S.DeleteResidentWrapper>
            </div>
          </S.Container>
        </div>
      )}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100vh",
          }}
          data-testid="loading-indicator"
        >
          <LoadingIcon className="rotate" />
        </div>
      )}
    </>
  );
}
const route: AppRoute = {
  name: "People Details",
  screen: ResidentDetails,
  path: "/people/:userId",
  role: [UserRole.Authenticated],
};

export { route, ResidentDetails };

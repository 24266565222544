// istanbul ignore file
import { useState } from "react";
import { useTheme } from "styled-components";

import {
  BuildingWithDoorAccess,
  GuestDoorAccess,
} from "../../../../../../common/redux/services/model";
import { H3, H4 } from "../../../../../../common/components/design-system";
import { Button } from "../../../../../../common/components/design-system";
import { AccessItem } from "../access-item/access-item";

import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as DownArrowIcon } from "assets/icons/angle-down.svg";

export interface AccessListProps {
  accessVariant?: "add_guest" | "profile";
  showPin?: boolean;
  accessList: BuildingWithDoorAccess[];
  onEdit: (doorAccess: GuestDoorAccess) => void;
  onDelete: (doorAccess: GuestDoorAccess) => void;
  showExpired?: boolean;
  hasValidDoorAccess?: boolean;
}
function AccessList({
  accessList = [],
  onEdit,
  accessVariant,
  onDelete,
  showPin,
  showExpired,
}: AccessListProps) {
  const [isDoorListShown, setIsDoorListShown] = useState(true);
  const [selectedBuildingIndex, setSelectedBuildingIndex] = useState(-1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentDoor, setCurrentDoor] = useState({} as GuestDoorAccess);
  const [buildingVisibility, setBuildingVisibility] = useState(
    new Array(accessList.length).fill(false)
  );
  const [currentBuildingNumberOfDoors, setCurrentBuildingNumberOfDoors] =
    useState(0);
  const theme = useTheme();

  function closeModal() {
    setModalIsOpen(false);
  }

  // check if there is at least one door with valid access
  // @ts-ignore
  const hasValidDoorAccess = accessList
    ? accessList.some((access) => {
        // @ts-ignore
        return access?.doors?.some((door) => {
          // @ts-ignore
          return (
            // @ts-ignore
            door.isExpired === false ||
            // @ts-ignore
            door.isExpired === null ||
            // @ts-ignore
            door.isExpired === undefined
          );
        });
      })
    : false;

  const toggleDoorList = (index: number) => {
    const updatedVisibility = [...buildingVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setBuildingVisibility(updatedVisibility);
  };
  return (
    <div
      style={{ display: "flex", flex: 1, flexDirection: "column", flexGrow: 1 }}
    >
      {modalIsOpen && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 100,
          }}
        >
          <div
            style={{
              width: "80%",
              padding: 24,
              backgroundColor: "white",
              border: "1px solid #E5E5E5",
              marginTop: "5vh",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
            }}
          >
            <h5 style={{ textAlign: "center" }}>Door removal</h5>

            <div style={{ textAlign: "center" }}>
              Are you sure you want to remove {currentDoor.doorName}?
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "space-between",
                justifyContent: "space-between",
                alignContent: "space-between",
                marginTop: 16,
              }}
            >
              <Button
                style={{ alignSelf: "center", marginRight: 8 }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ alignSelf: "center", marginLeft: 8 }}
                onClick={() => {
                  if (accessList.length > 0 && accessList[0].doors.length > 1) {
                    onDelete(currentDoor);
                    closeModal();
                  } else {
                    if (currentBuildingNumberOfDoors > 1) {
                      onDelete(currentDoor);
                      closeModal();
                    } else {
                      window.alert("You must have at least one door selected");
                      closeModal();
                    }
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="access-list-wrapper">
        <div className="building-list-wrapper">
          {accessList?.map((access, index) => {
            let doors = access.doors;
            const hasValidDoorAccess = doors.some(
              (door) =>
                // @ts-ignore
                door.isExpired === false ||
                // @ts-ignore
                door.isExpired === null ||
                // @ts-ignore
                door.isExpired === undefined
            );
            if (!showExpired) {
              // If there are no doors, don't show the building
              if (!hasValidDoorAccess) {
                return null;
              }
              doors = access.doors.filter(
                // @ts-ignore
                (door) =>
                  // @ts-ignore
                  door.isExpired === false ||
                  // @ts-ignore
                  door.isExpired === null ||
                  // @ts-ignore
                  door.isExpired === undefined
              );
            }
            return (
              <div
                key={access.id}
                className="building-wrapper"
              >
                <div
                  data-testid="building-item"
                  className="building"
                  onClick={() =>
                    //@ts-ignore
                    toggleDoorList(index)
                  }
                >
                  <div className="building-name-image">
                    <div className="image-wrapper">
                      <BuildingIcon />
                    </div>
                    <div
                      className="building-name"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="building-name">
                        <H3>{access?.name}</H3>
                      </div>
                      {/* showmore or less button */}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignSelf: "flex-start",
                          alignItems: "center",
                          marginTop: -8,
                        }}
                      >
                        <DownArrowIcon
                          fill={"#707070"}
                          height={8}
                          style={{
                            transform:
                              //@ts-ignore
                              buildingVisibility[index] ? "rotate(180deg)" : "",
                            //@ts-ignore
                            marginBottom: buildingVisibility[index] ? 2 : 0,
                            marginTop: -4,
                            marginRight: 4,
                          }}
                        />
                        <H4 style={{ fontSize: 16, color: "#707070" }}>
                          {buildingVisibility[index]
                            ? "Show Less"
                            : "Show More"}
                        </H4>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="building-drop-icon"
                    style={{
                      transform: isDoorListShown ? "rotate(180deg)" : "",
                    }}
                  >
                    <DownArrowIcon fill={theme.color.text.default} />
                  </div> */}
                </div>
                <div className="door-list-container">
                  {buildingVisibility[index] && (
                    <div>
                      {doors.map((door) => {
                        return (
                          <AccessItem
                            date-testid="access-item"
                            key={door?.doorAccessUuid}
                            doorAccess={door}
                            onClick={onEdit}
                            showPin={showPin}
                            accessVariant={accessVariant}
                            onDelete={(door: GuestDoorAccess) => {
                              setCurrentBuildingNumberOfDoors(
                                access.doors.length
                              );
                              setModalIsOpen(true);
                              setCurrentDoor(door);
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!hasValidDoorAccess && !showExpired && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            flex: 1,
            flexGrow: 1,
          }}
        >
          <h5
            style={{
              textAlign: "center",
              marginTop: 20,
              fontStyle: "italic",
              fontFamily: "arial",
            }}
          >
            No active access
          </h5>
        </div>
      )}
    </div>
  );
}
AccessList.defaultProps = {
  accessVariant: "profile",
};
export { AccessList };

import * as React from "react";
import { useState } from "react";
import { BuildingHeader } from "./building-header";
import { DoorItem } from "./door-item";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Api } from "common/redux/services/base/api";
import { AppRoute, UserRole } from "screens/navigations/Types";
import { UserProfileCard } from "../components/user-profile-card";

function Permissions({ route }: any) {
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState();
  const [loading, setLoading] = useState(true);

  async function getPermissions(): Promise<any> {
    const result = await Api.get("/api/v1/users/access");

    if (result != null) {
      setPermissions(result.data ?? []);
      setLoading(false);
      return Promise.resolve();
    } else {
      setLoading(false);
      return Promise.reject();
    }
  }

  React.useEffect(() => {
    getPermissions();
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      {!loading && permissions && permissions.organizationAccess && permissions.organizationAccess.length > 0 ? (
        <div style={{ flexGrow: 1, padding: 24 }}>
          <UserProfileCard
            user={{
              // @ts-ignore
              name: permissions?.name,
              // @ts-ignore
              email: permissions?.email,
              // @ts-ignore
              phone: permissions?.phoneNumber,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <S.AccessTypeButton
              data-testid="access-type-button"
              onClick={() => {
                navigate("access-types", {
                  // @ts-ignore
                  organizations: permissions?.organizationAccess,
                });
              }}
            >
              <S.AccessTypeText>Access Types</S.AccessTypeText>
            </S.AccessTypeButton>
          </div>
          {permissions &&
            // @ts-ignore
            permissions?.organizationAccess.map((organization: any) => {
              return organization?.buildingAccess.map((building: any) => {
                return (
                  <>
                    <BuildingHeader building={building} />
                    {building?.doorAccess.map((doorAccess: any) => {
                      return (
                        <>
                          <DoorItem door={doorAccess} />
                        </>
                      );
                    })}
                  </>
                );
              });
            })}
        </div>
      ) : (
        <div style={{ flexGrow: 1, padding: 24 }}>
          <p>No permissions available for this user.</p>
        </div>
      )}
      {loading && (
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 24,
          }}
        >
          {/* <ActivityIndicator /> */}
        </div>
      )}
    </>
  );
}

const route: AppRoute = {
  name: "Permissions",
  screen: Permissions,
  path: "/permissions",
  role: [UserRole.Authenticated],
};
export { Permissions, route };

import { useCallback, useEffect, useMemo } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import {
  BuildingWithDoorAccess,
  DoorResponse,
  GuestAccessDate,
  GuestInfo,
} from "../../../../../../common/redux/services/model";
import { AccessList } from "../../../guest-profile/components/access-list/access-list";
import { AddGuestProfileCard } from "../add-guest-profile-card/add-guest-profile-card";
import { groupBy } from "../../../../../../common/utils";
import {
  Button,
  ButtonText,
} from "../../../../../../common/components/design-system";
import { useAddGuestMutation } from "../../../../../../common/redux/services/guest.service";

interface AddGuestConfirmationProps {
  guestInfo: GuestInfo;
  doorAccessInfo: GuestAccessDate;
  doorList: DoorResponse[];
  goBack: (() => void) | undefined;
  onSubmit?: ((params: GuestAccessDate) => void) | undefined;
  onClose?: (() => void) | undefined;
  onDelete?: any;
  currentModule?: string;
  testId?: string;
}
function AddGuestConfirmation({
  guestInfo,
  doorAccessInfo,
  doorList,
  goBack,
  onSubmit,
  onClose,
  onDelete,
  currentModule,
  testId,
}: AddGuestConfirmationProps) {
  // istanbul ignore next
  const buildingData = useMemo(
    () =>
      doorList &&
      Object.values(groupBy(doorList, (item) => item.buildingUuid))
        .filter((it) => it.length > 0)
        .map((items) => {
          return {
            id: items[0].buildingUuid,
            name: items[0].buildingName,
            doors: items.map((door) => ({
              doorUuid: door?.doorUuid,
              doorName: door?.doorName,
              buildingUuid: door?.buildingUuid,
              buildingName: door?.buildingName,
              startedAt: `${moment(doorAccessInfo?.startDate).format(
                "YYYY-MM-DD"
              )} ${moment(doorAccessInfo?.startTime).format("HH:mm:ss")}`,
              endedAt:
                doorAccessInfo?.endDate && doorAccessInfo?.endTime
                  ? `${moment(doorAccessInfo?.endDate).format(
                      "YYYY-MM-DD"
                    )} ${moment(doorAccessInfo?.endTime).format("HH:mm:ss")}`
                  : undefined,
              padPin: door?.padPin,
            })),
          } as Omit<BuildingWithDoorAccess, "doorAccessUuid">;
        }),
    [doorList]
  );

  const [
    addGuest,
    { isLoading, isSuccess, data: addGuestResponse, ...addGuestMutation },
  ] = useAddGuestMutation();

  // TO DO: test this function
  /* istanbul ignore next */
  const saveGuest = useCallback(async () => {
    let startedAt = new Date(
      `${moment(doorAccessInfo?.startDate).format("YYYY-MM-DD")}T${moment(
        doorAccessInfo?.startTime
      ).format("HH:mm:ss")}`
    );

    let endedAt = doorAccessInfo?.endDate
      ? new Date(
          `${moment(doorAccessInfo?.endDate).format("YYYY-MM-DD")}T${moment(
            doorAccessInfo?.endTime
          ).format("HH:mm:ss")}`
        )
      : undefined;

    const door_list = doorList.map((door) => door.doorUuid) ?? [];
    const data = {
      startedAt: startedAt,
      // @ts-ignore
      endedAt: endedAt ?? endedAt,
      doorList: door_list,
      inviteEmail: guestInfo?.invite_email
        ? guestInfo?.invite_email
        : undefined,
      name: guestInfo?.name ? guestInfo?.name : "",
    };
    // @ts-ignore
    addGuest(data);
  }, [guestInfo, doorAccessInfo, doorList]);

  useEffect(() => {
    const response = addGuestResponse;
    if (isSuccess && response) {
      toast.success("Guest added successfully");
      if (onClose) onClose();
      // close the add door modal
    }
  }, [isSuccess, addGuestResponse]);

  useEffect(() => {
    if (addGuestMutation.isError) {
      toast.error((addGuestMutation.error as Error).message);
      addGuestMutation.reset();
    }
  }, [
    addGuestMutation.error,
    addGuestMutation.isError,
    addGuestMutation.reset,
  ]);

  const handleNameChange = (name: string) => {
    guestInfo.name = name;
  };

  return (
    <div
      style={{ marginLeft: -10 }}
      data-testid={testId ?? "add-guest-confirmation"}
      className="add-guest-confirmation-wrapper"
    >
      <AddGuestProfileCard
        guest={{
          ...guestInfo,
          name:
            guestInfo?.name ||
            // @ts-ignore
            (guestInfo?.doorAccesses &&
              // @ts-ignore
              guestInfo.doorAccesses[0]?.allowedNickname) ||
            // @ts-ignore
            (doorList &&
              // @ts-ignore
              doorList[0]?.doorName),
        }}
        // @ts-ignore
        isPinOnly={guestInfo.invite_email == "" ? true : false}
        onNameChange={handleNameChange}
      />

      <AccessList
        accessList={buildingData!}
        accessVariant="add_guest"
        onEdit={(doorAccess) => {}}
        onDelete={onDelete}
      />

      <div
        style={{ marginBottom: 20 }}
        className="form-button-wrapper guest-button-marg-top"
      >
        <Button
          size="lg"
          variant="basic"
          onClick={goBack}
        >
          <ButtonText>Previous</ButtonText>
        </Button>

        <Button
          data-testid={"submit-button"}
          isLoading={isLoading}
          type="submit"
          size="lg"
          variant="primary"
          onClick={async () => {
            // @ts-ignore
            if (guestInfo?.type === "guest") {
              await saveGuest();
            } else if (onSubmit) {
              onSubmit(doorAccessInfo);
            }
          }}
        >
          <ButtonText>Confirm</ButtonText>
        </Button>
      </div>
    </div>
  );
}

export { AddGuestConfirmation };

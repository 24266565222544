import { route as homeRoute } from "../home";
import { route as myAccessRoute } from "../authenticated/my-access";
import { route as guestRoute } from "../authenticated/guests/guest-list";
import { route as guestProfileRoute } from "../authenticated/guests/guest-profile";
import { route as activitiesRoute } from "../authenticated/activities/activities";
import { route as profileRoute } from "../authenticated/profile/profile";
import { route as updatePasswordRoute } from "../authenticated/update-password/update-password";
import { route as supportRoute } from "../authenticated/support/support";
import { route as contactRoute } from "../authenticated/support/contact/contact";
import { route as termsOfServiceRoute } from "../authenticated/support/termsOfService/termsOfService";
import { route as privacyPolicyRoute } from "../authenticated/support/privacyPolicy/privacyPolicy";
import { route as deactivateAccountRoute } from "../authenticated/support/deacticvateAccount/deactivateAccount";
import { route as changeNameRoute } from "../authenticated/profile/change-name/change-name";
import { route as changeEmailRoute } from "../authenticated/profile/change-email/change-email";
import { route as changePhoneRoute } from "../authenticated/profile/change-phone/change-phone";
import { route as invitationsRoute } from "../authenticated/profile/invitations/invitations";
import { route as permissionsRoute } from "../authenticated/profile/permissions";
import { route as accessTypesRoute } from "../authenticated/profile/permissions/access-types";
import { route as buildingsRoute } from "../authenticated/buildings";
import { route as doorsRoute } from "../authenticated/doors";
import { route as residentsRoute } from "../authenticated/residents";
import { route as residentDetailsRoute } from "screens/authenticated/residents/resident-details/resident-details";
import { route as buildingStaffRoute } from "../authenticated/building-staff";
import { route as staffProfileRoute } from "../authenticated/building-staff/staff-profile/staff-profile";

import { AppRoute } from "./Types";

const appRoute: AppRoute[] = [
  homeRoute,
  myAccessRoute,
  guestRoute,
  guestProfileRoute,
  activitiesRoute,
  profileRoute,
  updatePasswordRoute,
  supportRoute,
  contactRoute,
  termsOfServiceRoute,
  privacyPolicyRoute,
  deactivateAccountRoute,
  changeNameRoute,
  changeEmailRoute,
  changePhoneRoute,
  buildingsRoute,
  doorsRoute,
  residentsRoute,
  residentDetailsRoute,
  buildingStaffRoute,
  staffProfileRoute,
  invitationsRoute,
  permissionsRoute,
  accessTypesRoute,
];

export { appRoute };

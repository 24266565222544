/* eslint-disable react/no-direct-mutation-state */
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../../navigations/Types";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";

import "./termsOfService.scss";

function TermsOfService() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();

  const userOption: HeaderOption = {
    url: "/terms_of_service",
    title: "Terms of Service",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <TopNav navigationOption={userOption} />
      </div>
      <div style={{ marginTop: 40 }}>
        <iframe
          src="https://www.apadaccess.com/terms-of-service"
          width="100%"
          height="700px"
          data-testid="terms-of-service-iframe"
        />
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "TermsOfService",
  screen: TermsOfService,
  path: "/terms_of_service",
  role: [UserRole.Authenticated],
};
export { TermsOfService, route };

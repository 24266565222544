import { useTheme } from "styled-components";

import { GuestInfo } from "../../../../../../common/redux/services/model";
import {
  H2,
  LabelText,
} from "../../../../../../common/components/design-system";
import { DoorImgWrapper } from "../../../../my-access/components/door/door";
import { GuestProfileCardWrapper } from "../../../guest-profile/components/guest-profile-card/guest-profile-card";

import { ReactComponent as MultiUserIcon } from "assets/icons/multi-user.svg";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as PinIcon } from "assets/svg/pinIcon.svg";

interface AddGuestProfileCardProps {
  guest: GuestInfo;
  isPinOnly?: boolean;
  addStaff?: boolean;
  onNameChange?: (name: string) => void;
}
function AddGuestProfileCard({
  guest,
  isPinOnly,
  addStaff,
  onNameChange,
}: AddGuestProfileCardProps) {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [guestName, setGuestName] = useState("PIN Only Access");
  const textInputRef = useRef<HTMLInputElement | null>(null);
  const borderColor = isEditing ? "white" : "none";
  const borderWidth = isEditing ? 1 : 0;

  useEffect(() => {
    if (isPinOnly) {
      if (guestName !== "PIN Only Access") {
        // @ts-ignore
        onNameChange(guestName);
      } else {
        // @ts-ignore
        onNameChange("");
      }
    }
  }, [guestName]);

  const handleIconClick = () => {
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <GuestProfileCardWrapper>
      {isPinOnly ? (
        <div
          className="guest-name"
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <input
              style={{
                flex: 1,
                width: 290,
                backgroundColor: "#1B1B1B",
                color: "white",
                borderWidth: borderWidth,
                borderColor: borderColor,
                outline: "none",
                fontSize: 20,
                fontWeight: 600,
                padding: 8,
                borderRadius: 8,
              }}
              maxLength={25}
              type="text"
              placeholder="Guest name"
              value={guestName}
              disabled={!isEditing}
              onChange={(text) => setGuestName(text.target.value)}
              ref={textInputRef}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: 16,
              cursor: "pointer",
            }}
            data-testid="edit-button"
            className="access-edit-icon"
            onClick={() => handleIconClick()}
          >
            <EditIcon
              fill={"white"}
              stroke={"white"}
            />
          </div>
        </div>
      ) : (
        <div className="guest-name">
          <H2 color={theme.color.text.onPrimary}>{guest?.name ?? "--"}</H2>
        </div>
      )}
      {isPinOnly ? (
        <div style={{ color: "#FFFFFF", marginTop: -40, width: 250 }}>
          A customized name can be given after creating the PIN
        </div>
      ) : null}
      {guest.addStaff ? (
        <div style={{ marginTop: -30, marginBottom: -20 }}>
          <div
            style={{
              height: 25,
              display: "inline-block",
              borderWidth: "1px",
              borderColor: "#FFFFFF",
              borderStyle: "solid",
              borderRadius: 8,
              lineHeight: "10px",
              verticalAlign: "middle",
            }}
          >
            <div style={{ color: "#FFFFFF", textAlign: "center", padding: 5 }}>
              {guest?.role ?? "--"}
            </div>
          </div>
        </div>
      ) : null}
      {isPinOnly ? (
        <div className="guest-profile-detail-wrapper">
          <div
            style={{
              height: 25,
              width: 70,
              borderWidth: "1px",
              borderColor: "#FFFFFF",
              borderStyle: "solid",
              borderRadius: 8,
            }}
          >
            <div style={{ color: "#FFFFFF", textAlign: "center" }}>
              PIN Only
            </div>
          </div>
          <div className="guest-profile-image-wrapper">
            <DoorImgWrapper
              style={{
                border: `1px solid ${theme.color.text.onPrimary}`,
              }}
            >
              <PinIcon stroke={theme.color.text.onPrimary} />
            </DoorImgWrapper>
          </div>
        </div>
      ) : (
        <div className="guest-profile-detail-wrapper">
          <div className="guest-profile-email-phone-wrapper">
            <LabelText color={theme.color.text.onPrimary}>
              {guest?.invite_email ?? "--"}
            </LabelText>
          </div>
          <div className="guest-profile-image-wrapper">
            <DoorImgWrapper
              style={{
                border: `1px solid ${theme.color.text.onPrimary}`,
              }}
            >
              <MultiUserIcon stroke={theme.color.text.onPrimary} />
            </DoorImgWrapper>
          </div>
        </div>
      )}
    </GuestProfileCardWrapper>
  );
}
export { AddGuestProfileCard };

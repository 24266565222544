// istanbul ignore file
// TODO: Find out how to test the time picker
// This component is being tested, but because of the timepicker, it is not being covered by the test.
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  ButtonText,
  H3,
  H4,
} from "../../../../../../common/components/design-system";
import { DateTime } from "../../../../../../common/components/design-system/date-time/date-time";
import "./start-to-end-date.scss";
import { GuestAccessDate } from "../../../../../../common/redux/services/model";
import type { StartToEndVariant } from "../door-access-wrapper/door-access-wrapper";
import { start } from "repl";
import { off } from "process";
import { d } from "msw/lib/glossary-de6278a9";

export interface StartToEndDateProps {
  isLoading?: boolean;
  title: string;
  doorAccessDate: GuestAccessDate;
  goBack: ((params: GuestAccessDate) => void) | undefined;
  onNext: ((params: GuestAccessDate) => void) | undefined;
  onDelete: (() => void) | undefined;
  accessFormVariant: StartToEndVariant;
  offsetShortName?: string;
}

const isEqualDateOnly = (date1: Date, date2: Date): boolean => {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    return false;
  }

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const isEqualTimeOnly = (date1: Date, date2: Date): boolean =>
  date1.getHours() === date2.getHours() &&
  date1.getMinutes() === date2.getMinutes() &&
  date1.getSeconds() === date2.getSeconds();

const isBeforeTimeOnly = (date1: Date, date2: Date): boolean => {
  if (date1.getHours() === date2.getHours()) {
    return date1.getMinutes() < date2.getMinutes();
  }
  return date1.getHours() < date2.getHours();
};

export const validateEndTime = (
  schema: Yup.DateSchema,
  eventEndDate?: Date | undefined,
  eventStartDate?: Date | undefined,
  eventStartTime?: Date | undefined
): Yup.DateSchema =>
  schema.test(
    "notEqualDate",
    "Start time and end time cannot be the same",
    function () {
      //@ts-ignore
      const { path, createError, parent = {} } = this;

      const startDate = eventStartDate || parent.startDate || null;
      const endDate = eventEndDate || parent.endDate || null;
      const startTime = eventStartTime || parent.startTime || null;
      const endTime = parent.endTime || null;

      if (!startTime || !endTime) {
        return true;
      }

      if (startDate && endDate && isEqualDateOnly(startDate, endDate)) {
        if (isEqualTimeOnly(startTime, endTime)) {
          return false;
        }
        return isBeforeTimeOnly(endTime, startTime)
          ? createError({
              path,
              message: "End time cannot be less than start time",
            })
          : true;
      }
      return true;
    }
  );

const startendedAtSchema = Yup.object().shape({
  startDate: Yup.date().required("Start date is required"),
  startTime: Yup.date().required("Start time is required"),
  endDate: Yup.date(),
  endTime: Yup.date(),
});

function StartToEndDate({
  goBack,
  onNext,
  title,
  isLoading,
  onDelete,
  doorAccessDate,
  accessFormVariant,
  offsetShortName,
}: StartToEndDateProps) {
  const {
    values,
    dirty,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      startDate: doorAccessDate?.startDate ?? undefined,
      endDate: doorAccessDate?.endDate ?? undefined,
      startTime: doorAccessDate?.startTime ?? undefined,
      endTime: doorAccessDate?.endTime ?? undefined,
      offsetShortName: doorAccessDate?.offsetShortName ?? undefined,
    },
    validationSchema: startendedAtSchema,
    onSubmit: (values) => {
      onNext?.({
        startDate: values.startDate,
        startTime: values.startTime,
        endDate: values.endDate,
        endTime: values.endTime,
      });
    },
  });
  const goPrevious = useCallback(() => {
    goBack?.({
      startDate: values.startDate,
      startTime: values.startTime,
      endDate: values.endDate,
      endTime: values.endTime,
    });
  }, [values]);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [showMessage, setShowMessage] = useState(false);
  const [showStartTimeMessage, setShowStartTimeMessage] = useState(false);

  const minEndTime = React.useMemo(() => {
    if (
      values.startDate &&
      values.endDate &&
      isEqualDateOnly(values.startDate, values.endDate)
    ) {
      return values.startTime;
    }
    return undefined;
  }, [values.startDate, values.endDate, values.startTime]);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (
      values.startDate ||
      values.endDate ||
      values.startTime ||
      values.endTime
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(dirty);
    }
  }, [dirty]);

  useEffect(() => {
    // @ts-ignore
    if (values.startDate > values.endDate) {
      setShowStartTimeMessage(true);
    } else {
      setShowStartTimeMessage(false);
    }
  }, [values.startDate, values.endDate]);
  return (
    <form
      id="loginform"
      onSubmit={handleSubmit}
      data-testid={"start-to-end-date-container"}
    >
      <H3 style={{ marginBottom: "19px", marginTop: "38px" }}>{title}</H3>
      <div className="form-input-wrapper">
        <H4>Start</H4>
        <div className="form-input-date-wrapper">
          <DateTime
            testId={"start-date-input"}
            dateVariant="date"
            label="Date"
            valueData={values.startDate}
            onValueChange={(datetime) => {
              setFieldTouched("startDate");
              setFieldValue("startDate", datetime);
            }}
            onBlur={handleBlur("startDate")}
            error={touched?.startDate && !!errors?.startDate}
            errorMessage={errors?.startDate}
          />

          <DateTime
            testId={"start-time-input"}
            dateVariant="time"
            label={`Time (${doorAccessDate?.offsetShortName ?? offsetShortName})`}
            valueData={values.startTime}
            onValueChange={(datetime) => {
              setFieldTouched("startTime");
              setFieldValue("startTime", datetime);
            }}
            onBlur={handleBlur("startTime")}
            error={touched?.startTime && !!errors?.startTime}
            errorMessage={errors?.startTime}
          />
        </div>
        {showStartTimeMessage && (
          <div>
            <h6 style={{ color: "red", fontSize: "12px" }}>
              * Start Date must be before end Date
            </h6>
          </div>
        )}

        <H4>End</H4>
        <div className="form-input-date-wrapper">
          <DateTime
            testId={"end-date-input"}
            dateDisabled={!values.startDate || !values.startTime}
            dateVariant="date"
            label="Date"
            note="Optional"
            minDate={values?.startDate}
            valueData={values.endDate}
            onChange={handleChange("endDate")}
            onBlur={handleBlur("endDate")}
            onValueChange={(datetime) => {
              setFieldTouched("endDate");
              setShowMessage(true);
              setFieldValue("endDate", datetime);
            }}
            error={touched?.endDate && !!errors?.endDate}
            errorMessage={errors?.endDate}
          />
          <DateTime
            testId={"end-time-input"}
            dateDisabled={
              !values.startDate || !values.startTime || !values.endDate
            }
            dateVariant="time"
            label={`Time (${doorAccessDate?.offsetShortName ?? offsetShortName})`}
            valueData={values.endTime}
            minDate={minEndTime}
            onValueChange={(datetime) => {
              setFieldTouched("endTime");
              setFieldValue("endTime", datetime);
            }}
            onBlur={handleBlur("endTime")}
            error={touched?.endTime && !!errors?.endTime}
            errorMessage={errors?.endTime}
          />
        </div>
        {showMessage && values.endDate && values.endDate < yesterday && (
          <div>
            <h6 style={{ color: "red", fontSize: "12px" }}>
              * End date cannot be in the past
            </h6>
          </div>
        )}
        {accessFormVariant === "add_guest" && (
          <div className="form-button-wrapper guest-button-marg-top">
            <Button
              size="lg"
              variant="basic"
              onClick={goPrevious}
            >
              <ButtonText>Previous</ButtonText>
            </Button>

            <Button
              disabled={values.endDate && values.endDate < yesterday}
              type="submit"
              size="lg"
              variant="primary"
            >
              <ButtonText>Next</ButtonText>
            </Button>
          </div>
        )}

        {accessFormVariant === "edit_access" && (
          <div className="form-button-wrapper guest-button-marg-top">
            <Button
              isLoading={isLoading}
              type="button"
              size="lg"
              variant="outline-monochrome"
              onClick={onDelete}
            >
              <ButtonText>Remove Access</ButtonText>
            </Button>

            <Button
              disabled={
                (values.endDate && values.endDate < yesterday) ||
                //@ts-ignore
                values.startDate > values.endDate
              }
              data-testid="next-button-edit-access"
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isLoading}
            >
              <ButtonText>Save</ButtonText>
            </Button>
          </div>
        )}

        {accessFormVariant === "add_door" && (
          <div className="form-button-wrapper guest-button-marg-top">
            <Button
              size="lg"
              variant="basic"
              onClick={goPrevious}
            >
              <ButtonText>Previous</ButtonText>
            </Button>

            <Button
              data-testid="next-button-add-door"
              disabled={!isDirty}
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isLoading}
            >
              <ButtonText>Next</ButtonText>
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
StartToEndDate.defaultProps = {
  isLoading: undefined,
};
export { StartToEndDate };

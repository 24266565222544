/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "screens/navigations/Types";
import * as S from "./styles";
import { UserProfileCard } from "screens/authenticated/profile/components/user-profile-card";
import { BuildingList } from "./components/building-list";
import { Button, H4 } from "common/components/design-system";
import { HeaderOption } from "common/components/top-nav/type";
import { TopNav } from "common/components/top-nav";
import { AddDoor } from "../add-door/add-door";
// import { AddNewResidentDoor } from "../add-door/add-new-resident-door";
import { RightSidebarLayout } from "common/components/right-sidebar-layout";
import {
  getBuildingStaffData,
  getOrganizationUserData,
} from "common/redux/services/building-management.service";
import { FillStaffInfo } from "./fill-staff-info";
import { EditGuestAccess } from "screens/authenticated/guests/edit-guest-access";
import { removeStaff } from "../../../../common/redux/services/staff.service";
import { EditBuildings } from "./edit-buildings";
import { Api } from "../../../../common/redux/services/base/api";

export interface Door {
  name: string;
  doorAccessUuid: string;
  doorUuid: string;
  doorName: string;
  doorType: string;
  permissionLevel: string;
  startedAt: string;
  endedAt: string;
  offsetShortName: string;
}

export interface DoorAccesses {
  private: Door[];
  shared: Door[];
}

export interface Doors {
  private: Door[];
  shared: Door[];
}

export interface Building {
  buildingUuid: string;
  buildingName: string;
  doorAccess: DoorAccesses;
  Doors: Doors;
  offsetShortName: string;
}

export interface Resident {
  name: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  doorAccesses: Building[];
  status: string;
}
export async function getData(
  userId: string,
  variant: string,
  setLoading: any,
  setResidentData: any,
  setStaffData: any
) {
  setLoading(true);
  let { staff, residents } =
    variant == "organization"
      ? await getOrganizationUserData(userId)
      : await getBuildingStaffData(userId);

  let role = "building-staff";
  if (variant == "organization") {
    role = "organization-manager";
  } else {
    // @ts-ignore
    for (let i = 0; i < residents.buildingAccess.length; i++) {
      if (
        // @ts-ignore
        residents.buildingAccess[i].permissionLevel == "building-manager"
      ) {
        role = "building-manager";
      }
    }
  }

  setResidentData({ ...residents, role: role });
  setStaffData(staff);
  setLoading(false);
}

function StaffProfile() {
  const { variant, userId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [residentData, setResidentData] = useState({} as Resident);
  const [staffData, setStaffData] = useState([]);
  const [doorAccess, setDoorAccess] = useState({});
  const [headerRole, setHeaderRole] = useState("");
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [buildings, setBuildings] = useState([]);

  async function getBuildings(): Promise<any> {
    setLoading(true);
    const result = await Api.post(
      "/api/v1/building-management/search?page=1&pageSize=50&orderBy=buildingName&orderDir=ASC",
      {
        nameOrAddress: "*",
        isManager: true,
        organizationId:
          staffData.length > 0
            ? // @ts-ignore
              staffData[0].organizationUuid
            : undefined,
      }
    );
    if (result != null) {
      // selectUserBuildings(result.data.rows);
      setBuildings(result.data.rows);
      setLoading(false);
      return Promise.resolve(result.data.rows);
    } else {
      setLoading(false);
      return Promise.reject();
    }
  }

  useEffect(() => {
    getBuildings();
    // @ts-ignore
    if (residentData.role == "building-manager") {
      setHeaderRole("Manager");
      // @ts-ignore
    } else if (residentData.role == "building-staff") {
      setHeaderRole("Staff");
      // @ts-ignore
    } else if (residentData.role == "organization-manager") {
      setHeaderRole("Org. Manager");
    }
  }, [residentData]);

  const dynamicTitle = headerRole;

  const staffOption: HeaderOption = {
    url: "/staff-profile/",
    title: dynamicTitle,
    showLogo: false,
    rightComponent: () => (
      <div
        className="top-nav-right-wrapper"
        data-testid="top-nav-right-wrapper"
      >
        {/* <Button
          data-testid="add-door-button"
          variant="plain"
          onClick={() => setShowForm("add_door")}
        >
          Add door
        </Button> */}

        {headerRole != "Org. Manager" && (
          <Button
            variant="plain"
            data-testid="edit-buildings-button"
            onClick={() => {
              setShowForm("edit_buildings");
            }}
          >
            Edit Buildings
          </Button>
        )}
      </div>
    ),
  };

  useEffect(() => {
    getData(
      userId ?? "",
      variant ?? "",
      setLoading,
      setResidentData,
      setStaffData
    );
  }, [showForm]);

  const renderSubcomponent = useCallback(() => {
    switch (showForm) {
      // istanbul ignore next
      case "edit_staff":
        return (
          <div style={{ flex: 1, padding: 24 }}>
            <H4>Edit Staff</H4>
            <FillStaffInfo
              addNewStaffData={residentData}
              setAddNewStaffData={setResidentData}
              onNext={() => {}}
              goBack={() => {
                setShowForm(undefined);
              }}
            />
          </div>
        );

      // istanbul ignore next
      case "add_door":
        return (
          <AddDoor
            guestName={residentData?.name}
            //@ts-ignore
            guestId={residentData?.userUuid!}
            guestInfo={residentData!}
            onClose={() => {
              setShowForm(undefined);
              getData(
                userId ?? "",
                variant ?? "",
                setLoading,
                setResidentData,
                setStaffData
              );
            }}
          />
        );

      // istanbul ignore next
      case "edit_door":
        return (
          <EditGuestAccess
            totalAccess={residentData?.doorAccesses?.length!}
            // @ts-ignore
            userId={residentData?.userUuid!}
            // @ts-ignore
            doorAccess={doorAccess!}
            onClose={() => {
              setShowForm(undefined);
            }}
            // @ts-ignore
            offsetShortName={residentData?.buildingAccess[0].offsetShortName}
          />
        );

      // istanbul ignore next
      case "edit_buildings":
        return (
          <EditBuildings
            user={residentData}
            staffData={staffData}
            onClose={() => {
              setShowForm(undefined);
            }}
          />
        );

      default:
        return undefined;
    }
  }, [showForm]);

  let title = "Staff";
  if (variant == "staff") {
    title = "Staff";
  } else if (variant == "building-manager") {
    title = "Manager";
  }

  return (
    <>
      <TopNav navigationOption={staffOption} />
      <S.Container data-testid="staff-profile-container">
        <RightSidebarLayout
          visible={!!showForm}
          onClose={() => setShowForm(undefined)}
        >
          {renderSubcomponent()}
        </RightSidebarLayout>
        <UserProfileCard
          user={{
            name: residentData?.name ?? "",
            phone: residentData?.phoneNumber ?? "",
            email: residentData?.email ?? "",
            status: residentData?.status ?? "",
          }}
          onEditPress={() => {
            setShowForm("edit_staff");
          }}
        />
        <div style={{ flex: 1 }}>
          <div style={{ overflowY: "auto", paddingTop: 16 }}>
            <div
              style={{
                marginBottom: variant !== "organization" ? "60px" : "0",
              }}
            >
              {staffData?.length > 0 && (
                <BuildingList
                  //  @ts-ignore
                  buildingList={
                    variant == "organization"
                      ? buildings.map((building: any) => {
                          return {
                            ...building,
                            name: building.buildingName,
                            permissionLevel: "building-manager",
                            doors: {
                              private: [],
                              shared: [],
                            },
                          };
                        })
                      : staffData ?? []
                  }
                  onClick={(door) => {
                    setDoorAccess(door);
                    setShowForm("edit_door");
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {variant != "organization" && (
          <S.DeleteStaffWrapper
            data-testid="delete-resident-wrapper"
            className="delete-wrapper"
            style={{
              position: "fixed",
              bottom: 0,
              left: 214, // Center the container horizontally
              // transform: 'translateX(-50%)', // Center the container horizontally
              width: "90%", // Adjust width as needed
              // maxWidth: '900px', // Set max width if required
              padding: "10px",
              backgroundColor: "white",
              textAlign: "center", // Center button content horizontally
            }}
          >
            <div style={{ marginBottom: "10px" }}>
              <Button
                variant="plain"
                data-testid="delete-resident-button"
                color={theme.color.text.critical}
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this " + title + "?"
                    )
                  ) {
                    const result = await removeStaff(userId ?? "");
                    if (result) {
                      navigate("/building-staff");
                    } else {
                      window.alert(
                        "Something went wrong while removing the " + title + "."
                      );
                    }
                  }
                }}
              >
                <H4 color={theme.color.interactive.critical}>Remove {title}</H4>
              </Button>
            </div>
          </S.DeleteStaffWrapper>
        )}
      </S.Container>
    </>
  );
}
const route: AppRoute = {
  name: "Staff Profile",
  screen: StaffProfile,
  path: "/staff-profile/:variant/:userId",
  role: [UserRole.Authenticated],
};

export { route, StaffProfile };

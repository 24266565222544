import React, { useEffect, useState } from "react";

import {
  Button,
  ButtonText,
} from "../../../../common/components/design-system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import * as S from "./styles";

function DoorInfo({
  door,
  addNewDoorData,
  permissions,
  setAddNewDoorData,
  onNext,
  goBack,
  onButtonClick,
}: any) {
  const [loading, setLoading] = useState(false);

  const [doorDetails, setDoorDetails] = useState([] as any[]);
  const [doorName, setDoorName] = useState(addNewDoorData.name);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (
      permissions &&
      permissions.length > 0 &&
      permissions[0] === "building-staff" &&
      addNewDoorData.apadLockNumber != "" &&
      addNewDoorData.apadLockNumber != null
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
    setDoorDetails([
      {
        key: "Door Status",
        value: addNewDoorData.lockStatusName,
      },
      {
        key: "Door Type",
        value:
          addNewDoorData.type.charAt(0).toUpperCase() +
          addNewDoorData.type.slice(1),
      },
      {
        key: "Floor",
        value: addNewDoorData.floor,
      },
      {
        key: "Lock ID",
        value: addNewDoorData.apadLockNumber,
      },
    ]);
  }, [addNewDoorData]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // Enable this if you want to change tabs on click
    // setValue(newValue);
  };

  // Details component

  function DetailsComponent() {
    return (
      <>
        <div
          style={{
            marginTop: 24,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3 style={{ fontSize: 20, fontWeight: "400", marginBottom: 16 }}>
            Details
          </h3>
          {doorDetails.map((item) => (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <h3 style={{ fontSize: 18, fontWeight: "400", color: "#6D7175" }}>
                {item.key}
              </h3>
              <h3 style={{ fontSize: 16, fontWeight: "400", color: "#202223" }}>
                {item.value}
              </h3>
            </div>
          ))}
        </div>
        <div className="bottom-buttons">
          <Button
            data-testid="assign-lock-button"
            size="lg"
            disabled={buttonDisabled}
            variant={addNewDoorData?.apadLockNumber ? "basic" : "primary"}
            onClick={onButtonClick}
          >
            <ButtonText>
              {addNewDoorData?.apadLockNumber
                ? "Assign New Lock"
                : "Assign Lock"}
            </ButtonText>
          </Button>
        </div>
      </>
    );
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: 24,
          paddingTop: 32,
        }}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  const getTabWidth = (label: string) => {
    const labelWidth = label.length * 10;
    return `${labelWidth}px`;
  };

  return (
    <>
      <h3 style={{ fontSize: 26, fontWeight: "400", marginLeft: 48 }}>
        {doorName}
      </h3>

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="options tabs"
        style={{
          padding: "0px 48px",
          width: "auto",
          borderBottom: "1px solid #E0E0E0",
        }}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#202223",
          },

          "& .MuiTab-root": {
            color: "#202223",
            fontSize: 16,
            textTransform: "none",
            opacity: 0.5,
            // "&:hover": {
            //   color: "#202223",
            //   opacity: 1,
            // },
            "&.Mui-selected": {
              color: "#202223",
              opacity: 1,
            },
          },
        }}
      >
        <Tab
          label="Details"
          {...a11yProps(0)}
          style={{
            minWidth: 0,
            whiteSpace: "nowrap",
            width: getTabWidth("Details"),
          }}
        />
        <Tab
          label="Activity"
          {...a11yProps(1)}
          style={{
            minWidth: 0,
            whiteSpace: "nowrap",
            width: getTabWidth("Activity"),
          }}
        />
        <Tab
          label="Users"
          {...a11yProps(2)}
          style={{
            minWidth: 0,
            whiteSpace: "nowrap",
            width: getTabWidth("Users"),
          }}
        />
      </Tabs>
      <S.ContentContainer style={{ flexDirection: "column", flex: 1 }}>
        <TabPanel
          value={value}
          index={0}
        >
          {DetailsComponent()}
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
        >
          {/* Item Two */}
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
        >
          {/* Item Three */}
        </TabPanel>
      </S.ContentContainer>
    </>
  );
}

export { DoorInfo };

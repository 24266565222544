import React from "react";
import styled, { css } from "styled-components";

import { InputHeightType } from "../../text-field/input-field/input-field";

export interface SelectProps {
  disabled?: boolean;
  icon?: React.ElementType;
  color?: string;
  error?: boolean;
  backgroundColor?: string;
  inputHeight?: InputHeightType;
}

// ${(props) => props.readOnly && `background-color: ${props.theme.color.surface.default}; `}

const Select = styled.select.attrs((props: SelectProps) => props)`
  margin-top: 4px;
  padding: 8px 12px;
  width: 100%;
  height: 48px;
  background-color: ${(props) =>
    // @ts-ignore
    props.backgroundColor || props.theme.color.surface.default};
  border-radius: 4px;
  border: none;
  margin-top: 4px;
  margin-bottom: 4px;
  ${(props) =>
    // @ts-ignore
    props.icon && "-webkit-appearance: none;  -moz-appearance: none;"};

  ${(props) =>
    // @ts-ignore
    props.error &&
    `background-color: ${props.theme.color.surface.criticalSubdued}; border: 1px solid ${props.theme.color.border.criticalDefault}; `}
  /* Inside auto layout */
  &:focus {
    background: ${(props) => props.theme.color.surface.default};
    border: 1px solid ${(props) => props.theme.color.border.subdued};
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.color.focused.default};
    ${(props) =>
      // @ts-ignore
      props.error &&
      `background-color: ${props.theme.color.surface.criticalSubdued}; border: 1px solid ${props.theme.color.border.criticalDefault}; outline: none;`}
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.color.surface.default};
      color: ${props.theme.color.text.disabled};
      fill: ${props.theme.color.text.disabled};
      border: none;
    `};

  &::placeholder {
    font-family: ${(props) => props.theme.fontFamily.normal};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.color.text.default};
  }
`;
export { Select };

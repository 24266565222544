import { useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";

import { AppRoute, UserRole } from "../../navigations/Types";
import { TopNav } from "../../../common/components/top-nav";
import { HeaderOption } from "../../../common/components/top-nav/type";
import { CaptionTextSmall } from "../../../common/components/design-system";
import { groupBy } from "../../../common/utils";
import { BuildingWithDoors } from "../../../common/redux/services/model";

import { BuildingList } from "./components/building-list/building-list";

import "./my-access.scss";
import { getDoors } from "common/redux/services/doors.service";
import { Button } from "@mui/material";
import { Pin } from "@mui/icons-material";
import { PinModal } from "./components/PinModal/PinModal";

const dashboardOptions: HeaderOption = {
  url: "/",
  title: "My Access",
  showLogo: true,
};

function MyAccess() {
  const theme = useTheme();

  const [doorList, setDoorList] = useState([]);
  const [showPin, setShowPin] = useState(false);
  const [selectedPin, setSelectedPin] = useState(null);

  async function getData() {
    let result = [];
    result = await getDoors();
    setDoorList(result);
  }

  useEffect(() => {
    getData();
  }, [selectedPin]);

  const buildingData = useMemo(
    () =>
      Object.values(groupBy(doorList, (item: any) => item.buildingUuid))
        .filter((it) => it.length > 0)
        .map(
          (items: any) =>
            ({
              id: items[0].buildingUuid,
              address: items[0].address1,
              name: items[0].buildingName,
              doors: items.map((door: any) => ({
                pin: door.padPin,
                id: door.doorUuid,
                name: door.doorName,
                buildingId: door.buildingUuid,
                buildingAddress: door.address1,
                ...door,
              })),
            } as BuildingWithDoors)
        ),

    [doorList]
  );

  const pinMessage = showPin ? (
    <h5
      style={{
        textAlign: "right",
        margin: 10,
        color: "#0F62FE",
        fontSize: 16,
      }}
    >
      Hide PINs
    </h5>
  ) : (
    <h5
      style={{
        textAlign: "right",
        margin: 10,
        color: "#0F62FE",
        fontSize: 16,
      }}
    >
      Show PINs
    </h5>
  );

  const refreshPins = async () => {
    try {
      const updatedDoors = await getDoors();
      setDoorList(updatedDoors);
    } catch (error) {
      console.error("Error refreshing pins:", error);
    }
  };

  return (
    <div data-testid="my-access-page">
      <div style={{ marginTop: 20 }}>
        <TopNav navigationOption={dashboardOptions} />
      </div>
      <div className="my-access-wrapper">
        <div className="my-access-note">
          <CaptionTextSmall color={theme.color.text.subdued}>
            Do not share your PIN codes with anyone{" "}
          </CaptionTextSmall>
        </div>
        {doorList.length === 0 ? null : (
          <div>
            <Button
              data-testid="show-pin-button"
              variant="text"
              onClick={() => {
                setShowPin(!showPin);
              }}
              style={{ float: "right" }}
            >
              {pinMessage}
            </Button>
          </div>
        )}
        <div style={{ marginTop: 60 }}>
          <BuildingList
            buildingList={buildingData}
            showPin={showPin}
            setPinModalVisible={setSelectedPin}
          />
        </div>

        <PinModal
          visible={selectedPin != null}
          onConfirm={() => {
            setSelectedPin(null);
            refreshPins();
          }}
          onCancel={() => {
            setSelectedPin(null);
            refreshPins();
          }}
          selectedPin={selectedPin}
          // @ts-ignore
          refreshPins={refreshPins}
        />
      </div>
    </div>
  );
}

const route: AppRoute = {
  name: "My Access",
  screen: MyAccess,
  path: "/",
  role: [UserRole.Authenticated],
};

export { MyAccess, route };

import React from "react";

export enum UserRole {
  Authenticated = "Authenticated",
  General = "General",
  Default = "Default",
}

export interface AppRoute{
  name: string;
  screen: React.ComponentType<any>;
  options?: object,
  path: string,
  role: UserRole[];
  childRoutes?: AppRoute[];
  sidebar?: boolean;
}

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "styled-components";
import { toast } from "react-toastify";

import { GuestDetail } from "../../../../../../common/redux/services/model";
import {
  Button,
  ButtonText,
  CaptionTextSmall,
  H3,
  LabelText,
  TextField,
} from "../../../../../../common/components/design-system";
import { useUpdateGuestMutation } from "../../../../../../common/redux/query";

import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { ReactComponent as DownArrowIcon } from "assets/icons/angle-down.svg";
import "./edit-guest-info-form-component.scss";
import { LocalStorage, Session } from "common/service";
import { Constants } from "../../../../../../assets/constants";
import { Api } from "common/redux/services/base/api";
import { set } from "lodash";

const guestSchema = Yup.object().shape(
  {
    name: Yup.string().trim().required("Full Name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Email is invalid"),
  },
  []
);

export interface EditGuestInfoFormComponentProps {
  guestInfo?: GuestDetail;
  onCancel: (() => void) | undefined;
}

export async function updateResident(
  name: string,
  guestInfo: GuestDetail | undefined,
  onCancel: (() => void) | undefined
): Promise<void> {
  const session = LocalStorage.getItem<Session>(
    Constants.storageKey.userSession
  );

  if (session == null) return;

  /* istanbul ignore next */
  const result = await Api.patch(
    //@ts-ignore
    "/api/v1/building-management/users/" + guestInfo.userUuid,
    {
      name: name,
    }
  );

  /* istanbul ignore if */
  if (result == null) {
    toast.error("Failed to update user");
    onCancel!();
    throw new Error("Failed to update user");
  }

  onCancel!();
  toast.success("Resident updated successfully");
}

function EditGuestInfoFormComponent({
  guestInfo,
  onCancel,
}: EditGuestInfoFormComponentProps) {
  const theme = useTheme();
  const [isDoorListShown, setIsDoorListShown] = useState(false);

  const [residentName, setResidentName] = useState(guestInfo?.name);
  const [residentEmail, setResidentEmail] = useState(guestInfo?.email);
  const [loading, setLoading] = useState(false);

  return (
    //@ts-ignore
    <form
      data-testid="edit-guest-info-form"
      id="loginform"
      onSubmit={() => {
        event?.preventDefault();
      }}
    >
      <H3 style={{ marginBottom: "19px", marginTop: "20px" }}>Profile</H3>
      <div className="form-input-wrapper">
        <TextField
          data-testid="full-name-input"
          label="Full Name"
          name="fullName"
          id="fullName"
          value={residentName}
          //@ts-ignore
          onChange={(e) => setResidentName(e.target.value)}
          suffixIcon={() => (
            <PersonIcon
              height={18}
              width={18}
            />
          )}
          suffixStyle={{
            fill: "none",
            stroke: "black",
          }}
        />

        <TextField
          data-testid="email-input"
          disabled
          label="Email"
          name="email"
          id="email"
          value={residentEmail}
          suffixIcon={() => (
            <MailIcon
              height={18}
              width={18}
              stroke={theme.color.text.disabled}
            />
          )}
        />
      </div>

      <div className="edit-guest-note-wrapper">
        <div
          data-testid="note-title-wrapper"
          className="note-title-wrapper"
          onClick={() => setIsDoorListShown(!isDoorListShown)}
        >
          <LabelText color={theme.color.interactive.default}>
            Why can’t I edit email?
          </LabelText>
          <div
            className="building-drop-icon"
            style={{ transform: isDoorListShown ? "rotate(180deg)" : "" }}
          >
            <DownArrowIcon fill={theme.color.interactive.default} />
          </div>
        </div>

        {isDoorListShown && (
          <div
            data-testid="note-wrapper"
            className="note-wrapper"
            style={{ transition: "10s" }}
          >
            <CaptionTextSmall color={theme.color.text.subdued}>
              The email is tied to the guest account. If you want to change
              email, you must delete and create a new, updated guest profile, or
              add a new guest.
            </CaptionTextSmall>
          </div>
        )}
      </div>
      <div className="form-button-wrapper">
        <Button
          data-testid="save-button"
          // type="submit"
          size="lg"
          variant="primary"
          disabled={residentName === guestInfo?.name && !loading}
          isLoading={loading}
          //@ts-ignore
          onClick={async () => {
            setLoading(true);
            await updateResident(residentName ?? "", guestInfo, onCancel);
            setLoading(false);
          }}
        >
          <ButtonText>Save</ButtonText>
        </Button>
      </div>
    </form>
  );
}

EditGuestInfoFormComponent.defaultProps = {
  guestInfo: undefined,
};

export { EditGuestInfoFormComponent };

import * as React from "react";

import { AppRoute, UserRole } from "screens/navigations/Types";
import * as S from "./styles";
import { Api } from "common/redux/services/base/api";
import { accessTypesInfo } from "constants/accessTypeInfo";

interface DoorAccess {
  name: string;
  permissionLevel: string;
}

interface BuildingAccess {
  name: string;
  permissionLevel: string;
  doorAccess: DoorAccess[];
}
interface OrganizationAccess {
  name: string;
  permissionLevel: string;
  buildingAccess: BuildingAccess[];
}

export function hasManagerPermissions(organizations: any) {
  // @ts-ignore
  for (let i = 0; i < organizations.length; i++) {
    // @ts-ignore
    const organization = organizations[i];
    if (
      organization?.permissionLevel &&
      (organization?.permissionLevel == "organization-manager" ||
        organization?.permissionLevel == "organization-staff")
    ) {
      return true;
    }
    for (let j = 0; j < organization.buildingAccess.length; j++) {
      const building = organization.buildingAccess[j];
      if (
        building?.permissionLevel &&
        (building?.permissionLevel == "building-manager" ||
          building?.permissionLevel == "building-staff")
      ) {
        return true;
      }
      for (let k = 0; k < building.doorAccess.length; k++) {
        const doorAccess = building.doorAccess[k];
        if (
          doorAccess?.permissionLevel &&
          (building?.permissionLevel == "building-manager" ||
            building?.permissionLevel == "building-staff")
        ) {
          return true;
        }
      }
    }
  }
  return false;
}

function AccessTypes() {
  const [organizations, setOrganizations] =
    React.useState<OrganizationAccess[]>();

  async function getAccesses(): Promise<any> {
    const result = await Api.get("/api/v1/users/access");

    if (result != null) {
      setOrganizations(result.data?.organizationAccess ?? []);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  React.useEffect(() => {
    getAccesses();
  }, []);

  if (!organizations) return null;

  // istanbul ignore next
  const isManager = hasManagerPermissions(organizations);

  // istanbul ignore next
  return (
    <div
      style={{ flexGrow: 1, padding: 24 }}
      data-testid="access-types"
    >
      {accessTypesInfo.map((accessType, index) => {
        if (index > 1 && !isManager) return null;

        return (
          <div
            style={{
              marginBottom: 24,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <S.AccessTypeTitle>{accessType.title}</S.AccessTypeTitle>
            <S.AccessTypeDescription>
              {accessType.description}
            </S.AccessTypeDescription>
          </div>
        );
      })}
    </div>
  );
}

const route: AppRoute = {
  name: "AccessTypes",
  screen: AccessTypes,
  path: "/permissions/access-types",
  role: [UserRole.Authenticated],
};
export { AccessTypes, route };

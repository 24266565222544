import styled from "styled-components";

import { insert } from "../../../../../common/utils";
import { Door } from "../../../../../common/redux/services/model";
import { H3 } from "../../../../../common/components/design-system";

import { ReactComponent as DoorIcon } from "assets/icons/door.svg";
import copyClipboard from "../../../../../assets/svg/clipboard-copy-icon.svg";
import "./door.scss";
import { useState } from "react";
import { toast } from "react-toastify";

interface DoorParams {
  door: Door;
  showPin?: boolean;
  setPinModalVisible?: (item: any) => void;
}
export const DoorImgWrapper = styled.div.attrs((props) => props)`
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.color.background.darkDefault};
`;
//@ts-ignore
const Toast = ({ message }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "20px",
        right: "20px",
        backgroundColor: "black",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      {message}
    </div>
  );
};
function DoorComponent({ door, showPin, setPinModalVisible }: DoorParams) {
  const [showToast, setShowToast] = useState(false);
  // TO DO: test this later
  // istanbul ignore next
  const copyToClipboard = (pin: string) => {
    navigator.clipboard
      .writeText(pin ?? "XXX XXX")
      .then(() => {
        // Show toast notification using react-toastify
        toast("PIN copied to clipboard!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
        });
      })
      .catch((error) => {
        // Handle error if clipboard operation fails
        toast.error("Error copying PIN");
      });
  };
  return (
    <div
      className="door-wrapper"
      data-testid="door-item"
    >
      <div className="door-title-img-wrapper">
        <div
          style={{
            position: "relative",
            marginTop: -25,
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: "black",
              marginLeft: 32, //half of the icon width
            }}
            data-testid="door-item-divider"
          />
          <div
            style={{
              borderRadius: 32,
              width: 64,
              height: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid",
            }}
          >
            <DoorIcon />
          </div>
        </div>
        <div className="door-title">
          <H3>{door?.name}</H3>
        </div>
      </div>
      <div>
        {showPin && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              className="door-pin"
              onClick={() => setPinModalVisible?.(door)}
              style={{ cursor: "pointer" }}
            >
              <H3>{insert(door?.pin, " ", 3) ?? "XXX XXX"}</H3>
            </div>
            <div
              onClick={() => copyToClipboard(door?.pin)}
              style={{ cursor: "pointer" }}
            >
              <img
                src={copyClipboard}
                alt="copy clipboard"
                style={{ height: 20, marginLeft: 10, marginBottom: 5 }}
              />
            </div>
          </div>
        )}

        {/* Toast Notification */}
        {showToast && <Toast message="PIN copied to clipboard!" />}
      </div>
    </div>
  );
}

export { DoorComponent };

/* eslint-disable react/no-direct-mutation-state */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../navigations/Types";
import { TopNav } from "../../../common/components/top-nav";
import { HeaderOption } from "../../../common/components/top-nav/type";
import { Api } from "../../../common/redux/services/base/api";

import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/angle-right.svg";

import { InputFieldIcon } from "../../../common/components/design-system/text-field/input-field-icon/input-field-icon";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import "./buildings.scss";

import * as S from "./styles";

function Buildings() {
  const navigate = useNavigate();
  const theme = useTheme();

  const userOption: HeaderOption = {
    url: "/buildings",
    title: "Properties",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  interface BuildingData {
    buildingUuid: string;
    buildingName: string | undefined;
    address1: string | undefined;
    administrativeArea: string | undefined;
    locality: string | undefined;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [buildings, setBuildings] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredBuildings, setFilteredBuildings] = useState<BuildingData[]>(
    []
  );

  useEffect(() => {
    if (searchText) {
      const filtered = buildings.filter((building: BuildingData) =>
        building.buildingName?.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredBuildings(filtered);
    } else {
      setFilteredBuildings(buildings);
    }
  }, [searchText, buildings]);

  async function getBuildings(): Promise<BuildingData[]> {
    setLoading(true);
    const result = await Api.post(
      "/api/v1/building-management/search?page=1&pageSize=50&orderBy=buildingName&orderDir=ASC",
      {
        nameOrAddress: "*",
        isManager: true,
      }
    );
    if (result != null) {
      setBuildings(result.data.rows);
      setLoading(false);
      return Promise.resolve(result.data.rows);
    } else {
      setLoading(false);
      return Promise.reject();
    }
  }

  useEffect(() => {
    getBuildings();
  }, []);

  return (
    <div style={{ flex: 1, paddingLeft: 24, paddingRight: 24 }}>
      <div style={{ marginTop: 20, marginBottom: 24, marginLeft: -24 }}>
        <TopNav navigationOption={userOption} />
      </div>

      <InputFieldIcon
        data-testid="search-input"
        style={{
          width: "286px",
          // margin: "0px 24px 24px 24px",
        }}
        prefixIcon={() => <SearchIcon />}
        placeholder="Search"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      {filteredBuildings.map((building: BuildingData) => {
        return (
          <S.BuildingContainer>
            <div className="image-wrapper">
              <BuildingIcon />
            </div>
            <div
              style={{
                flexDirection: "column",
                flex: 1,
                marginLeft: 24,
                display: "flex",
              }}
            >
              <S.BuildingName>{building.buildingName}</S.BuildingName>
              <S.BuildingAddress>{building.address1}</S.BuildingAddress>
            </div>
            <RightArrowIcon />
          </S.BuildingContainer>
        );
      })}
    </div>
  );
}
const route: AppRoute = {
  name: "Properties",
  screen: Buildings,
  path: "/Properties",
  role: [UserRole.Authenticated],
};
export { Buildings, route };

import { CSSProperties } from "react";
import styled from "styled-components";

import { CaptionText, LabelText } from "../typography/typography";

const Wrapper = styled.div`
  display: flex;
  gap: 9px;

  input {
    display: none;
  }

  .checkbox-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 4px;
    background: #ffffff;
    border: ${(props) => `2px solid ${props.theme.color.border.default}`};

    &:hover {
      background: ${(props) => props.theme.color.surface.hovered};
      border: ${(props) => `2px solid ${props.theme.color.border.default}`};
    }
  }

  input:checked + .checkbox-div {
    background: ${(props) => props.theme.color.brand};
    border: ${(props) => `2px solid ${props.theme.color.interactive.default}`};

    svg {
      opacity: 1;
      transform: scale(1);
    }
  }

  input:checked + .checkbox-div:hover {
    background: ${(props) => props.theme.color.brand};
    border: ${(props) => `2px solid ${props.theme.color.interactive.hovered}`};
  }

  input:disabled:checked + .checkbox-div {
    background: ${(props) => props.theme.color.interactive.disabled};
    border: ${(props) => `2px solid ${props.theme.color.interactive.disabled}`};
  }

  input:disabled:not(:checked) + .checkbox-div {
    background: ${(props) => props.theme.color.surface.disabled};
    border: ${(props) => `2px solid ${props.theme.color.border.disabled}`};
  }

  input:checked + .checkbox-div > svg {
    opacity: 1;
    transform: scale(1);
  }

  input:not(:checked) + .checkbox-div {
    svg {
      opacity: 0;
      transform: scale(0);
    }
  }

  .checkbox-labels {
    display: flex;
    flex-direction: column;
  }
`;

export interface CheckboxProps {
  title: string;
  subtitle?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  style?: CSSProperties;
  color?: string;
}

function Checkbox({
  title,
  subtitle,
  checked,
  disabled,
  onChange,
  style,
  color,
}: CheckboxProps) {
  return (
    <Wrapper
      data-testid="checkbox-container"
      style={style}
      onClick={disabled ? undefined : () => onChange?.(!checked)}
    >
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => {}}
      />
      <div className="checkbox-div">
        {checked && (
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L3.5 7L1 4.27273"
              stroke="white"
              strokeWidth="1.6666"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <div className="checkbox-labels">
        <LabelText>{title}</LabelText>
        {subtitle && <CaptionText color="subdued">{subtitle}</CaptionText>}
      </div>
    </Wrapper>
  );
}

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  subtitle: undefined,
  onChange: undefined,
  style: undefined,
};

export { Checkbox };

import moment from "moment";
import * as S from "./styles";

import LockedIcon from "../../../../assets/svg/lockedIcon.svg";
import FailedIcon from "../../../../assets/svg/failedIcon.svg";
import UnlockedDoorIcon from "../../../../assets/svg/unlockedDoorIcon.svg";
import UnlockedDoorIconKey from "../../../../assets/svg/unlockedDoorIconKey.svg";
import UnlockedDoorIconMobile from "../../../../assets/svg/unlockedDoorIconMobile.svg";
import UnlockedDoorIconPin from "../../../../assets/svg/unlockedDoorIconPin.svg";

const icons: any = {
  handle_lock: <img src={LockedIcon} alt="Locked" />,
  failed: <img src={FailedIcon} alt="Failed Icon" />,
  unlock: <img src={UnlockedDoorIcon} alt="Unlocked Door Icon" />,
  pin_unlock: <img src={UnlockedDoorIconPin} alt="Unlocked Door Icon" />,
  handle_unlock: <img src={UnlockedDoorIcon} alt="Unlocked Door Icon" />,
  key_unlock: <img src={UnlockedDoorIconKey} alt="Unlocked Door Icon" />,
};

const itemTitle: any = {
  handle_lock: "Locked",
  pin_unlock: "Unlocked",
  handle_unlock: "Unlocked",
  key_unlock: "Unlocked",
};

interface ActivityItemProps {
  activity: any;
}

export default function ActivityItem({ activity }: ActivityItemProps) {
  return (
    <S.ActivityItemContainer>
      <S.ActivityItemIconContainer>
        {icons[activity.activityType]}
      </S.ActivityItemIconContainer>

      <S.ActivityItemInfoContainer>
        {/* h3 title small */}
        <h1 style={{ fontSize: 20 }}>{itemTitle[activity.activityType]}</h1>

        {/* <div style={{ flexDirection: "row" }}>
          <h1 style={{ color: "#6D7175", fontSize: 18 }}>
            {activity.createdByName} - {activity.activityType}
          </h1>
        </div> */}
        <h1 style={{ color: "#6D7175", fontSize: 18 }}>{activity.doorName}</h1>
      </S.ActivityItemInfoContainer>

      {/* text default */}
      <h1 style={{ color: "#202223", fontSize: 18 }}>
        {moment(activity.createdAt).format("hh:mm A")}
      </h1>
    </S.ActivityItemContainer>
  );
}

import { useState } from "react";
import { useTheme } from "styled-components";

import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as DownArrowIcon } from "assets/icons/angle-down.svg";

import "./building-list.scss";
import { BuildingWithDoors, Door } from "common/redux/services/model";
import { H4 } from "common/components/design-system";
import { DoorList } from "screens/authenticated/doors/door-list/door-list";
import { Building } from "../../resident-details";
import { AccessItem } from "../access-item/access-item";

import * as S from "./styles";

function DoorListContainer({
  doors,
  doorGroupName,
  buildingName,
  onClick,
}: any) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="building-list-wrapper" data-testid="door-list-container">
      <div
        // key={buildingWithDoor.id}
        className="building-wrapper"
      >
        <div
          data-testid="clickable-door-group"
          className="building"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="building-name-image">
            <div
              className="image-wrapper"
              style={{
                backgroundColor: "white",
                borderColor: "black",
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <span>{doors.length}</span>
            </div>
            <div className="building-name" style={{ flexDirection: "column" }}>
              <H4 style={{ fontSize: 20, color: "#202020" }}>
                {doorGroupName}
              </H4>
              {/* showmore or less button */}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignSelf: "flex-start",
                  alignItems: "center",
                  marginTop: -8,
                }}
              >
                <DownArrowIcon
                  fill={"#707070"}
                  height={8}
                  style={{
                    transform: expanded ? "rotate(180deg)" : "",
                    marginBottom: expanded ? 2 : 0,
                    marginTop: -4,
                    marginRight: 4,
                  }}
                />
                <H4 style={{ fontSize: 16, color: "#707070" }}>
                  {expanded ? "Show Less" : "Show More"}
                </H4>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // height: selectedBuildingIndex === index ? 300 : 0,
            // maxHeight:
            //   selectedBuildingIndex === index && buildingWithDoor?.data
            //     ? buildingWithDoor?.doorAccess?.private.length * 120
            //     : "0",
            overflow: "hidden",
            transition: "max-height 0.5s ease-out",
            // paddingBottom: selectedBuildingIndex === index ? 20 : 0,
          }}
        >
          {expanded &&
            doors.map((door: any) => {
              return (
                <S.DoorAccessContainer data-testid="door-item">
                  <AccessItem
                    doorAccess={door}
                    onClick={onClick}
                    onDelete={() => {}}
                    showPin={false}
                  />
                </S.DoorAccessContainer>
              );
            })}
        </div>
      </div>
    </div>
  );
}
export { DoorListContainer };

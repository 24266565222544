import styled from "styled-components";

export const BuildingContainer = styled.div`
  /* padding: 0px 24px 0px 24px; */
  margin-top: 24px;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: center;
  display: flex;
`;

export const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  background-color: #121212;
`;

export const BuildingName = styled.span`
  font-size: 20px;
  font-weight: 400;
`;

export const BuildingAddress = styled.span`
  font-size: 18px;
  color: "#6D7175";
`;

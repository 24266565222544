import styled from "styled-components";

export const Container = styled.div`
  flex-direction: row;
  /* margin-bottom: 24px; */
  padding: 8px 16px 8px 8px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

const circleSize = 64;
export const IconContainer = styled.div`
  background-color: #101010;
  height: ${circleSize}px;
  width: ${circleSize}px;
  border-radius: ${circleSize / 2}px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const TextsContainer = styled.div`
  flex: 1;
  margin-left: 16px;
  justify-content: center;
`;

export const UserNameText = styled.h2`
  font-size: 20px;
  font-weight: 400;
  color: #202020;
`;

export const DoorAccessText = styled.h2`
  font-size: 18px;
  font-weight: 400;
  color: #6d7175;
`;

// @ts-ignore
import axios from "axios";

import { Constants } from "../../../assets/constants";

export interface LoginParams {
  username: string;
  password: string;
}

export type LoginResponse = {
  user?: any;
  token: string;
  refreshToken?: string;
};

async function login(email: string, password: string): Promise<LoginResponse> {
  const session = await axios.post(
    `${Constants.Config.api.APP_DOMAIN}${Constants.apiEndpoint.login}`,
    { email, password }
  );
  return session.data;
}

const googleSSO = async (token: string) => {
  const session = await axios.post(
    `${Constants.Config.api.APP_DOMAIN}${Constants.apiEndpoint.googleLogin}`,
    { token }
  );
  return session.data;
};

const appleSSO = async (
  token: string,
  code: string
): Promise<{ token: string; refreshToken: string }> => {
  const session = await axios.post(
    `${Constants.Config.api.APP_DOMAIN}${Constants.apiEndpoint.appleLogin}`,
    { token, code }
  );
  return session.data;
};

export { login, appleSSO, googleSSO };

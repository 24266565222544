import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";
import { AppRoute, UserRole } from "../../../navigations/Types";
import { TopNav } from "../../../../common/components/top-nav";
import { HeaderOption } from "../../../../common/components/top-nav/type";

import "./privacyPolicy.scss";

function PrivacyPolicy() {
  const { id: user_uuid } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const userOption: HeaderOption = {
    url: "/privacy_policy",
    title: "Privacy Policy",
    showLogo: false,
    rightComponent: () => <div className="top-nav-right-wrapper"></div>,
  };

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <TopNav navigationOption={userOption} />
      </div>
      <div style={{ marginTop: 40 }}>
        <iframe
          data-testid={"privacy-policy"}
          src="https://apadaccess.com/privacy-policy"
          width="100%"
          height="700px"
        ></iframe>
      </div>
    </div>
  );
}
const route: AppRoute = {
  name: "PrivacyPolicy",
  screen: PrivacyPolicy,
  path: "/privacy_policy",
  role: [UserRole.Authenticated],
};
export { PrivacyPolicy, route };

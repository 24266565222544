import React from "react";
import styled, { useTheme } from "styled-components";

import { CaptionText, LabelText } from "../typography/typography";
import { Button } from "../button/button";

import { InputFieldIcon } from "./input-field-icon/input-field-icon";
import { Input, InputProps } from "./input-field/input-field";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

import "./text-field.scss";

const ValidationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: self-start;
  gap: 10px;
`;

export interface TextFieldProps
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      "ref"
    >,
    InputProps {
  label?: string;
  caption?: string;
  note?: string;
  error?: boolean;
  errorMessage?: string;
}

function TextFieldDelicate({
  label,
  errorMessage,
  error,
  caption,
  note,
  ...rest
}: TextFieldProps) {
  const theme = useTheme();
  return (
    <div className="form-group text-field-wrapper">
      <div className="text-field-title-wrapper">
        <LabelText color={theme.color.text.subdued}>{label}</LabelText>
        {caption && (
          <Button
            hidden={!caption}
            type="button"
            variant="plain"
          >
            {caption}
          </Button>
        )}
      </div>
      {rest.prefixIcon || rest.suffixIcon ? (
        <InputFieldIcon
          {...rest}
          error={error}
        />
      ) : (
        <Input
          // @ts-ignore
          error={error}
          {...rest}
        />
      )}

      <div className="text-field-bottom-text-wrapper">
        {!error ? (
          <CaptionText color={theme.color.text.subdued}>{note}</CaptionText>
        ) : (
          <ValidationWrapper>
            <div className="text-icon-wrapper">
              <WarningIcon fill={theme.color.icon.critical} />
            </div>
            <CaptionText color={theme.color.text.critical}>
              {errorMessage}
            </CaptionText>
          </ValidationWrapper>
        )}
      </div>
    </div>
  );
}

TextFieldDelicate.defaultProps = {
  label: undefined,
  error: false,
  errorMessage: undefined,
  caption: undefined,
  note: undefined,
};
const TextField = React.memo(TextFieldDelicate);
export { TextField };

import { useState } from "react";
import { useTheme } from "styled-components";

import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as DownArrowIcon } from "assets/icons/angle-down.svg";

import "./building-list.scss";
import { BuildingWithDoors, Door } from "common/redux/services/model";
import { H4 } from "common/components/design-system";
import { DoorList } from "screens/authenticated/doors/door-list/door-list";
import { Building } from "../../resident-details";
import { DoorListContainer } from "./door-list-container";

interface BuildingListParams {
  buildingList: Building[];
  onClick: (door: Door) => void;
}
function BuildingList({ buildingList, onClick }: BuildingListParams) {
  const [selectedBuildingIndex, setSelectedBuildingIndex] = useState(0);
  const theme = useTheme();

  return (
    <div
      className="building-list-wrapper"
      data-testid="building-list"
    >
      {buildingList?.map((buildingWithDoor: any, index) => {
        const expanded = selectedBuildingIndex == index;
        return (
          <div
            key={buildingWithDoor.id}
            className="building-wrapper"
            data-testid="building-wrapper"
          >
            <div
              className="building"
              onClick={() =>
                // Set to -1 if the same building is clicked again
                setSelectedBuildingIndex(expanded ? -1 : index)
              }
            >
              {buildingWithDoor?.doorAccess.shared.length > 0 ||
              buildingWithDoor?.doorAccess.private.length > 0 ? (
                <div className="building-name-image">
                  <div className="image-wrapper">
                    <BuildingIcon />
                  </div>
                  <div
                    className="building-name"
                    style={{ flexDirection: "column" }}
                  >
                    <H4 style={{ fontSize: 20, color: "#202020" }}>
                      {buildingWithDoor?.buildingName}
                    </H4>
                    {/* showmore or less button */}
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignSelf: "flex-start",
                        alignItems: "center",
                        marginTop: -8,
                      }}
                    >
                      <DownArrowIcon
                        fill={"#707070"}
                        height={8}
                        style={{
                          transform: expanded ? "rotate(180deg)" : "",
                          marginBottom: expanded ? 2 : 0,
                          marginTop: -4,
                          marginRight: 4,
                        }}
                      />
                      <H4 style={{ fontSize: 16, color: "#707070" }}>
                        {expanded ? "Show Less" : "Show More"}
                      </H4>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              style={{
                // height: selectedBuildingIndex === index ? 300 : 0,
                // maxHeight:
                //   selectedBuildingIndex === index && buildingWithDoor?.data
                //     ? buildingWithDoor?.doorAccess?.private.length * 120
                //     : "0",
                overflow: "hidden",
                transition: "max-height 0.5s ease-out",
                paddingBottom: selectedBuildingIndex === index ? 20 : 0,
              }}
            >
              {selectedBuildingIndex == index && (
                <>
                  {buildingWithDoor?.doorAccess.shared.length > 0 && (
                    <DoorListContainer
                      /* @ts-ignore */
                      doors={buildingWithDoor?.doorAccess.shared}
                      doorGroupName={"Common"}
                      buildingName={buildingWithDoor?.buildingName}
                      onClick={onClick}
                    />
                  )}

                  {buildingWithDoor?.doorAccess.private.length > 0 && (
                    <DoorListContainer
                      /* @ts-ignore */
                      doors={buildingWithDoor?.doorAccess.private}
                      doorGroupName={"Private"}
                      buildingName={buildingWithDoor?.buildingName}
                      onClick={onClick}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
export { BuildingList };

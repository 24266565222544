import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Button, H4 } from "../../../../common/components/design-system";
import { StepIndicator } from "../../../../common/components/step-indicator";
import { FillUserInfo } from "./fill-user-info";
import { DoorAccessWrapperComponent } from "screens/authenticated/guests/add-guest/components/door-access-wrapper/door-access-wrapper";
import { AddGuestConfirmation } from "screens/authenticated/guests/add-guest/components/add-guest-confirmation/add-guest-confirmation";
import { Api } from "common/redux/services/base/api";
import { GuestSelectDoorsFormComponent } from "screens/authenticated/guests/add-guest/components/guest-select-doors-form/guest-select-doors-form";
import moment from "moment";
import { Add } from "@mui/icons-material";

interface AddNewResidentProps {
  onClose: () => void;
  defaultActiveStep?: number;
}

function AddNewResident({ onClose, defaultActiveStep }: AddNewResidentProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(defaultActiveStep ?? 0);
  const [isLoading, setIsLoading] = useState(false);

  const [addResidentData, setAddResidentData] = useState({
    name: "",
    email: "",
    doors: [],
    startedAt: "",
    endedAt: "",
    currentModule: "AddResident",
    type: "resident",
  });

  async function inviteResident() {
    let startedAt = new Date(
      // @ts-ignore
      `${moment(addResidentData?.startDate).format("YYYY-MM-DD")}T${moment(
        // @ts-ignore
        addResidentData?.startTime
      ).format("HH:mm:ss")}`
    );

    // @ts-ignore
    let endedAt = addResidentData?.endDate
      ? new Date(
          // @ts-ignore
          `${moment(addResidentData?.endDate).format("YYYY-MM-DD")}T${moment(
            // @ts-ignore
            addResidentData?.endTime
          ).format("HH:mm:ss")}`
        )
      : undefined;

    const result = await Api.post("/api/v1/building-management/users/invites", {
      //@ts-ignore
      doorList: addResidentData.doors.map((door) => door.doorUuid),
      inviteEmail: addResidentData.email,
      name: addResidentData.name,
      phoneNumber: "+5531999998888",
      permissionLevel: "resident",
      notifyAllowedViaEmail: true,
      expiresInHours: 48,
      startedAt: startedAt,
      endedAt: endedAt,
    });
    if (result != null) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  const renderSubComponent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <FillUserInfo
            data-testid="fill-resident-info"
            addResidentData={addResidentData}
            setAddResidentData={setAddResidentData}
            onNext={() => {
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              onClose();
            }}
          />
        );
      // istanbul ignore next
      case 1:
        return (
          <GuestSelectDoorsFormComponent
            currentModule="residents"
            data-testid="select-doors-form"
            selectedDoorList={addResidentData.doors}
            // addResidentData={addResidentData}
            // setAddResidentData={setAddResidentData}
            onNext={(doorList) => {
              setAddResidentData({
                ...addResidentData,
                //@ts-ignore
                doors: doorList,
              });
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
          />
        );
      case 2:
        return (
          //@ts-ignore
          <DoorAccessWrapperComponent
            data-testid="door-access-wrapper"
            // addResidentData={addResidentData}
            // setAddResidentData={setAddResidentData}
            accessFormVariant="add_guest"
            title="Access"
            onSubmit={(doorAccessDate) => {
              setAddResidentData({
                ...addResidentData,
                ...doorAccessDate,
              });
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
          />
        );

      case 3:
        return (
          <AddGuestConfirmation
            data-testid="add-resident-confirmation"
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
            //@ts-ignore
            doorAccessInfo={addResidentData}
            guestInfo={{
              ...addResidentData,
              invite_email: addResidentData.email,
            }}
            doorList={addResidentData.doors!}
            onClose={onClose}
            onSubmit={() => {
              if (addResidentData.type !== "guest") {
                inviteResident().then(() => {
                  setActiveStep(activeStep + 1);
                });
              }
            }}
            onDelete={(doorAccess: any) => {
              const newDoorList = addResidentData.doors!.filter(
                // @ts-ignore
                (door) => door?.doorUuid !== doorAccess.doorUuid
              );
              setAddResidentData({ ...addResidentData, doors: newDoorList });
            }}
          />
        );
      case 4:
        onClose();
        return <></>;
      default:
        return <></>;
    }
  }, [activeStep, isLoading, addResidentData]);

  return (
    <div
      data-testid={"add-resident-container"}
      className="common-form-container"
    >
      <div className="common-form-wrapper">
        <div className="form-title-wrapper">
          <div className="form-title">
            <H4 color={theme.color.text.subdued}>
              Add{" "}
              {activeStep == 0
                ? "Person"
                : addResidentData.type.charAt(0).toUpperCase() +
                  addResidentData.type.slice(1)}
            </H4>
          </div>
          <div className="form-cancel">
            <Button
              variant="plain"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <StepIndicator
          style={{ marginTop: "14åpx", marginBottom: "49px" }}
          totalSteps={4}
          activeStep={activeStep}
        />
        {renderSubComponent()}
      </div>
    </div>
  );
}

export { AddNewResident };

import { createApi } from "@reduxjs/toolkit/query/react";

import { Constants } from "../../../assets/constants";

import axiosBaseQuery from "./base";
import {
  AddGuest,
  AddGuestDoor,
  EditGuestInfoRequest,
  Guest,
  GuestDetail,
  GuestRequestParams,
  UpdateGuestDoorAccess,
} from "./model/guest";
import { Paginated, PaginatedParams } from "./model";

export function getGuestsTransformer(response: any, args: any) {
  if (!response) {
    return {
      data: [],
      pagination: {
        total: 0,
        page: args.page,
        limit: args.pageSize,
      },
    };
  }
  return {
    data: response.rows,
    pagination: {
      total: response.pagination?.total ?? 0,
      page: response.pagination?.page ?? args.page,
      limit: response.pagination?.per_page ?? args.pageSize,
    },
  };
}

const guestApi = createApi({
  tagTypes: ["guest", "guests", "door-accesses", "door-access"],
  reducerPath: "guest",
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    guests: builder.query<
      Paginated<Guest[]>,
      GuestRequestParams & PaginatedParams
    >({
      query: (params) => ({
        method: "GET",
        url: Constants.apiEndpoint.getGuests,
        params: { ...params },
      }),
      transformResponse: (response: any, _, args) => {
        return getGuestsTransformer(response, args);
      },
    }),
    // @ts-ignore
    addGuest: builder.mutation<{}, AddGuest>({
      query: (body) => ({
        method: "POST",
        url: `${Constants.apiEndpoint.createUser}`,
        data: body,
      }),
    }),
    updateGuest: builder.mutation<
      unknown,
      {
        id?: string;
        actualNickname?: string;
        newNickname?: string;
        pinId?: string;
        newName?: string;
      }
    >({
      query: ({ id, pinId, ...data }) =>
        id
          ? {
              method: "PUT",
              url: `${Constants.apiEndpoint.users}/${id}/nickname`,
              data,
            }
          : {
              method: "PUT",
              url: `${Constants.apiEndpoint.doorPins}/${pinId}/name`,
              data,
            },
    }),
    deleteGuest: builder.mutation<
      {},
      { id: string; name: string; isPinOnly: string }
    >({
      query: (args) =>
        args.isPinOnly !== "true"
          ? {
              method: "POST",
              url: `${Constants.apiEndpoint.users}/${args.id}/revoke-access?nickname=${args.name}`,
            }
          : {
              method: "POST",
              url: `${Constants.apiEndpoint.doorPins}/${args.id}/revoke-door-access`,
            },
    }),
    deleteGuestDoorAccess: builder.mutation<{}, { id: string; userId: string }>(
      {
        query: (args) => ({
          method: "DELETE",
          url: `${Constants.apiEndpoint.doorAccess}/${args.id}`,
        }),
      }
    ),
  }),
});

export const {
  useGuestsQuery,
  useUpdateGuestMutation,
  useDeleteGuestMutation,
  useDeleteGuestDoorAccessMutation,
  useAddGuestMutation,
  reducer: guestReducer,
  middleware: guestMiddleware,
} = guestApi;

export default guestApi;

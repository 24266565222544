import styled from "styled-components";

export const InviteContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  flex-direction: row;
  padding: 0px 24px;
  display: flex;
`;

export const IconContainer = styled.div`
  background-color: #f1f2f3;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const InfoContainer = styled.div`
  padding: 0px 16px;
  flex-direction: column;
  flex: 1;
  display: flex;
`;

export const InfoText = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #6d7175;
`;

export const Time = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #6d7175;
`;

export const GroupDate = styled.h3`
  font-size: 18px;
  font-weight: 400;
  color: black;
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

import type { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

import { TokenUtils } from "../../../../utils";
import { LocalStorage, Session } from "../../../../service";
import { Constants } from "../../../../../assets/constants";

import { ErrorResponse, formatErrorAndThrow, StatusCode } from "./error-mapper";

async function responseInterceptor(
  error: AxiosError<ErrorResponse>,
  httpClient: AxiosInstance
) {
  const originalRequest = error.request;
  if (
    error.response?.status === StatusCode.FORBIDDEN &&
    !originalRequest.retry
  ) {
    originalRequest.retry = true;
    const session = await LocalStorage.getItem<Session>(
      Constants.storageKey.userSession
    );
    if (!TokenUtils.isTokenExpired(session?.accessToken)) {
      // Re-request if token is not expired
      originalRequest.headers = {
        Authorization: `Bearer ${session?.accessToken}`,
      };
      return httpClient(originalRequest);
    }
    // App token has expired so, logout the app
    // istanbul ignore next
    await LocalStorage.setItem(Constants.storageKey.userSession, undefined);
  }
  return formatErrorAndThrow(error);
}

async function requestInterceptor<I>(config: AxiosRequestConfig<I>) {
  const session = await LocalStorage.getItem<Session>(
    Constants.storageKey.userSession
  );

  // istanbul ignore next
  if (session && !TokenUtils.isTokenExpired(session?.accessToken)) {
    config.headers = {
      userId: session?.userId,
      Authorization: `Bearer ${session?.accessToken}`,
    };
  }
  return config;
}

export { requestInterceptor, responseInterceptor };

import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Button, H4 } from "../../../../common/components/design-system";
import { DoorInfo } from "./door-info";
import { AddLock } from "./add-lock";
import { DoorEdit } from "../door-edit/door-edit";
import { Api } from "common/redux/services/base/api";
interface DoorDetailsProps {
  door: any;
  buildingData: any[];
  permissions: any;
  onClose: () => void;
}
function DoorDetails({
  door,
  buildingData,
  permissions,
  onClose,
}: DoorDetailsProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [doorData, setDoorData] = useState(door);
  const [doorName, setDoorName] = useState("");

  async function getDoorData() {
    setIsLoading(true);
    const result = await Api.get(
      // @ts-ignore
      "/api/v1/building-management/doors/" + door.uuid
    );
    if (result != null) {
      setIsLoading(false);
      setDoorData(result.data);

      return Promise.resolve();
    } else {
      setIsLoading(false);
      return Promise.reject();
    }
  }
  useEffect(() => {
    getDoorData();
    setDoorName(doorData.name);
  }, [activeStep]);

  // istanbul ignore next
  const renderSubComponent = useCallback(() => {
    if (isLoading) return <></>;
    switch (activeStep) {
      case 0:
        return (
          <DoorInfo
            door={door}
            permissions={permissions}
            buildingData={door}
            addNewDoorData={doorData}
            setAddNewDoorData={setDoorData}
            onNext={() => {
              setActiveStep(activeStep + 1);
            }}
            goBack={() => {
              setActiveStep(activeStep - 1);
            }}
            onButtonClick={() => {
              setActiveStep(1);
            }}
          />
        );
      case 1:
        return (
          <AddLock
            door={door}
            buildingData={buildingData}
            addNewDoorData={doorData}
            setAddNewDoorData={setDoorData}
            onNext={() => {
              setActiveStep(4);
            }}
            goBack={() => {
              setActiveStep(0);
              setIsLoading(true);
            }}
            onButtonClick={() => {
              setActiveStep(1);
            }}
          />
        );
      case 3:
        return (
          <DoorEdit
            door={door}
            buildingData={buildingData}
            addNewDoorData={doorData}
            setAddNewDoorData={setDoorData}
            onNext={() => {
              setActiveStep(4);
            }}
            goBack={() => {
              setActiveStep(0);
              setIsLoading(true);
            }}
            onButtonClick={() => {
              setActiveStep(1);
            }}
          />
        );
      case 4:
        onClose();
        return <></>;
    }
  }, [activeStep, isLoading, doorData]);

  return (
    <div
      data-testid={"add-door-container"}
      style={{ display: "flex", flex: 1, flexDirection: "column" }}
      className="common-form-container"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 24px",
          paddingBottom: 24,
        }}
      >
        <div
          className="form-title"
          style={{ paddingLeft: activeStep != 0 ? 0 : 24 }}
        >
          {activeStep != 0 ? (
            <H4 color={theme.color.text.subdued}>
              {activeStep == 3 ? "Edit Door" : "Add Lock"}
            </H4>
          ) : (
            door.buildingName
          )}
        </div>
        {(permissions?.includes("building-manager") ||
          permissions?.includes("organization-manager")) && (
          <div className="form-cancel">
            <Button
              data-testid={"edit-button"}
              variant="plain"
              onClick={() => {
                setActiveStep(3);
              }}
            >
              Edit
            </Button>
          </div>
        )}
      </div>
      {renderSubComponent()}
    </div>
  );
}
export { DoorDetails };

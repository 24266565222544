import moment from "moment";
import { Api } from "./base/api";
import { useNavigate } from "react-router-dom";
import i18n from 'i18n-js';
interface ResidentDoorAccessData {
  startDate: string;
  startTime: string;
  endDate?: string;
  endTime?: string;
  doors: any[];
  email: string;
  name: string;
}

export async function addResidentDoorAccess(data: ResidentDoorAccessData) {
  const formatDateTime = (date: string, time: string) =>
    moment(
      `${moment(date).format("YYYY-MM-DD")} ${moment(time).format(
        "HH:mm:ss [GMT]ZZ (zz)"
      )}`
    ).format();

  try {
    const startedAt = formatDateTime(data.startDate, data.startTime);
    const endedAt =
      data?.endDate && data?.endTime
        ? formatDateTime(data.endDate, data.endTime)
        : undefined;

    const result = await Api.post("/api/v1/building-management/users/invites", {
      doorList: data.doors.map((door) => door.doorUuid),
      inviteEmail: data.email,
      name: data.name,
      phoneNumber: "+5531999998888",
      permissionLevel: "resident",
      notifyAllowedViaEmail: true,
      expiresInHours: 48,
      startedAt,
      endedAt,
    });

    if (!result) {
      throw new Error(i18n.t("message.no_result_from_api"));
    }
    return Promise.resolve(result);
  } catch (error) {
    console.error("Error in addResidentDoorAccess:", error);
    throw error;
  }
}

export async function removeResident(userId: string): Promise<boolean> {
  try {
    const result = await Api.post(
      `/api/v1/building-management/users/${userId}/revoke-door-access`
    );

    if (!result) {
      throw new Error(i18n.t("message.no_result_from_api"));
    }
    return Promise.resolve(true);
  } catch (error) {
    console.error("Error in removeResident:", error);
    throw new Error("Failed to remove resident");
  }
}

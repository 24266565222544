import { useEffect, useMemo, useState } from "react";

import {
  Button,
  ButtonText,
  Checkbox,
} from "../../../../common/components/design-system";
import { InputFieldIcon } from "common/components/design-system/text-field/input-field-icon/input-field-icon";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";

import * as S from "./styles";
import { Radio } from "@mui/material";
import { Api } from "common/redux/services/base/api";

function SelectBuilding({
  addNewStaffData,
  setAddNewStaffData,
  onNext,
  goBack,
  organizations = [],
  hasOrganizationPermissions = false,
  isFirstStep = false,
}: any) {
  const [searchText, setSearchText] = useState("");
  const [allowedToNext, setAllowedToNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [buildings, setBuildings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredBuildings, setFilteredBuildings] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);

  useEffect(() => {
    if (searchText === "") {
      setFilteredBuildings(buildings);
    } else {
      const filtered = buildings.filter((building: any) => {
        return building.buildingName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilteredBuildings(filtered);
    }
  }, [searchText, buildings]);

  async function getBuildings(): Promise<any> {
    setLoading(true);
    const result = await Api.post(
      "/api/v1/building-management/search?page=1&pageSize=50&orderBy=buildingName&orderDir=ASC",
      {
        nameOrAddress: "*",
        organizationId:
          organizations.length > 1 ? organizations[1].organizationUuid : null,
      }
    );
    if (result != null) {
      selectUserBuildings(result.data.rows);
      setBuildings(result.data.rows);
      setLoading(false);
      return Promise.resolve(result.data.rows);
    } else {
      setLoading(false);
      return Promise.reject();
    }
  }

  const [previousSelectedBuildings, setPreviousSelectedBuildings] = useState(
    []
  );

  function selectUserBuildings(newBuildings: any) {
    if (addNewStaffData?.buildingAccess?.length > 0) {
      const selectedBuildings = newBuildings.filter((building: any) => {
        return addNewStaffData?.buildingAccess?.some(
          (buildingAccess: any) =>
            buildingAccess.buildingUuid === building?.buildingUuid
        );
      });
      setSelectedBuildings(selectedBuildings);
      setPreviousSelectedBuildings(selectedBuildings);
    }
  }

  useEffect(() => {
    getBuildings();
  }, []);

  const groupedBuildings = useMemo(() => {
    const groupedData = filteredBuildings?.reduce((acc, curr) => {
      const { administrativeArea } = curr;
      if (!acc[administrativeArea]) {
        // @ts-ignore
        acc[administrativeArea] = [];
      }
      // @ts-ignore
      acc[administrativeArea].push(curr);
      return acc;
    }, {});

    return groupedData;
  }, [filteredBuildings]);

  const nextButton = () => {
    if (selectedBuildings.length === 0) {
      setErrorMessage("*Please select a building");
      return;
    }

    setErrorMessage("");

    if (selectedBuildings.length > 0) {
      onNext();
    }
  };

  useEffect(() => {
    setAddNewStaffData({
      ...addNewStaffData,
      buildings: selectedBuildings,
    });
  }, [selectedBuildings]);

  useEffect(() => {
    if (hasOrganizationPermissions) {
      setSelectedBuildings(buildings);
    }
  }, [hasOrganizationPermissions, buildings]);

  function renderBuildings() {
    return (
      <>
        {groupedBuildings && Object.keys(groupedBuildings).length == 0 && (
          <span>None</span>
        )}
        {Object.keys(groupedBuildings).map((administrativeArea) => {
          let administrativeAreaName;
          if (administrativeArea == null || administrativeArea == "null") {
            administrativeAreaName = "Other";
          } else {
            administrativeAreaName = administrativeArea;
          }
          return (
            <S.BuildingsGroupContainer key={administrativeArea}>
              <S.GroupName>{administrativeAreaName}</S.GroupName>
              {/*  @ts-ignore */}
              {groupedBuildings[administrativeArea]?.map(
                // @ts-ignore
                (building: any, index) => {
                  return (
                    <S.BuildingContainer key={building.buildingUuid}>
                      <S.IconContainer>
                        <BuildingIcon />
                      </S.IconContainer>
                      <S.BuildingName>{building.buildingName}</S.BuildingName>
                      <Checkbox
                        title=""
                        style={{
                          cursor: "pointer",
                        }}
                        disabled={hasOrganizationPermissions}
                        checked={selectedBuildings.some((item: any) => {
                          return item.buildingUuid === building.buildingUuid;
                        })}
                        onChange={() => {
                          if (
                            selectedBuildings.some((item: any) => {
                              return (
                                item.buildingUuid === building.buildingUuid
                              );
                            })
                          ) {
                            setSelectedBuildings(
                              selectedBuildings.filter((item: any) => {
                                return (
                                  item.buildingUuid !== building.buildingUuid
                                );
                              })
                            );
                          } else {
                            setSelectedBuildings([
                              // @ts-ignore
                              ...selectedBuildings,
                              // @ts-ignore
                              building,
                            ]);
                          }
                        }}
                      />
                    </S.BuildingContainer>
                  );
                }
              )}
            </S.BuildingsGroupContainer>
          );
        })}
      </>
    );
  }

  function isEnabledToProceed() {
    if (
      !hasOrganizationPermissions &&
      selectedBuildings.length === previousSelectedBuildings.length &&
      selectedBuildings.every((item, index) => {
        return previousSelectedBuildings.some((prevItem) => {
          return (
            // @ts-ignore
            item.buildingUuid === prevItem.buildingUuid
          );
        });
      })
    ) {
      return false;
    }
    return true;
  }

  return (
    <div data-testid="select-building-container">
      <S.PageTitle>
        {hasOrganizationPermissions ? "Buildings" : "Assign Buildings"}
      </S.PageTitle>
      <S.DescriptionText>
        {hasOrganizationPermissions
          ? "By selecting the Organization Role, the user will be assigned every building"
          : "Access to entry and common doors will automatically be assigned for the buildings selected below."}
      </S.DescriptionText>

      <InputFieldIcon
        data-testid="search-input"
        style={{
          width: "100%",
          marginBottom: 48,
        }}
        prefixIcon={() => <SearchIcon />}
        placeholder="Search"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <S.ContentContainer style={{ flexDirection: "column" }}>
        {!loading && groupedBuildings && <>{renderBuildings()}</>}
      </S.ContentContainer>
      <div>
        <h2 style={{ fontSize: 12, color: "red" }}>{errorMessage}</h2>
      </div>
      <div className="bottom-buttons">
        <Button
          size="lg"
          variant="basic"
          onClick={goBack}
        >
          <ButtonText>{isFirstStep ? "Cancel" : "Previous"}</ButtonText>
        </Button>
        <div style={{ width: 20 }} />
        <Button
          data-testid="next-button"
          size="lg"
          variant="primary"
          onClick={nextButton}
          disabled={!isEnabledToProceed()}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      </div>
    </div>
  );
}

export { SelectBuilding };
